/* eslint-disable no-nested-ternary */
import {
	BackButton, CustomStepper, DefaultButton,
} from 'common/presentation/components';
import {
	Box, InputBaseComponentProps, Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dimens from 'assets/dimens';
import { ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ContactSection from './ContactSection';
import ClientDetails from './ClientDetails';
import AddressDetails from './AddressDetails';
import { InputFieldProps } from '@/common/domain/entities/input-field-props';
import { makePurchaseValidation } from './builder/purchase-factory';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CheckItem from './components/CheckItem';
import { PurchaseSummary } from './PurchaseSummary';
import useAuth from 'features/authentication/presentation/provider/auth.provider';

enum ViewSelector {
	CLIENT_DETAILS = 0,
	CONTACTS = 1,
	ADRESS_DETAILS = 2,
	PURCHASE_SUMMARY = 3
}

const PurchasePage = () => {
	const [activeStep, setActiveStep] = useState(0);
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const validation = makePurchaseValidation();
	const { userAuth } = useAuth();
	const [state, setState] = useState({
		buyerFullName: '',
		buyerTaxId: '',
		buyerCompanyName: '',
		comment: '',
		commentCheck: false,

		buyerEmail: '',
		buyerPhoneNumber: '',
		buyerSecondaryPhoneNumber: '',
	});

	const [shippingAddress, setShippingAddress] = useState({
		cityName: '',
		streetAddress: '',
		zipCode: '',
		districtId: 11,
		districtName: 'Leiria',
		countryId: 1,
		countryName: 'Portugal',
	});

	const [useAsBillingAddress, setUseAsBillingAddress] = useState<boolean>(true);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [pickupAtCounter, setPickupAtCounter] = useState<boolean>(false);

	const [billingAddress, setBillingAddress] = useState({
		cityName: '',
		streetAddress: '',
		zipCode: '',
		districtId: 11,
		districtName: 'Leiria',
		countryId: 1,
		countryName: 'Portugal',
	});

	const [erros, setErros] = useState({
		buyerFullName: '',
		buyerTaxId: '',
		buyerCompanyName: '',
		comment: '',
		commentCheck: false,

		buyerEmail: '',
		buyerPhoneNumber: '',
		buyerSecondaryPhoneNumber: '',
		shippingAddress: {
			cityName: '',
			streetAddress: '',
			zipCode: '',
			districtId: '',
		},
		billingAddress: {
			cityName: '',
			streetAddress: '',
			zipCode: '',
			districtId: '',
		},
	});

	useEffect(() => {
		if (userAuth) {
			setState((prev) => ({
				...prev,
				buyerFullName: userAuth.fullName || '',
				buyerTaxId: userAuth.taxId || '',
				buyerCompanyName: userAuth.companyName || '',
				buyerEmail: userAuth.email || '',
				buyerPhoneNumber: userAuth.phoneNumber || '',
				buyerSecondaryPhoneNumber: userAuth.secondaryPhoneNumber || '',
			}));

			setShippingAddress((prev) => ({
				...prev,
				countryId: userAuth.country?.id || 1,
				countryName: userAuth.country?.name || '',
				districtId: userAuth.district?.id || 1,
				districtName: userAuth.district?.name || '',
				cityName: userAuth.cityName || '',
				streetAddress: userAuth.streetAddress || '',
				zipCode: userAuth.zipCode || '',
			}));
		}
	}, [userAuth]);

	const handleGoBack = () => {
		if (activeStep === ViewSelector.CLIENT_DETAILS) {
			navigate(-1);
		} else {
			setActiveStep(activeStep - 1);
		}
	};

	const steps = [1, 2, 3, 4];

	const handleNextClick = () => {
		let isValid = true;
		const formData = { ...state };
		if (activeStep === 0) {
			const nameError = validation.validate('buyerFullName', formData);
			const taxIdError = validation.validate('buyerTaxId', formData);
			setErros((prev) => ({
				...prev,
				buyerFullName: nameError,
				buyerTaxId: taxIdError,
			}));
			if (nameError || taxIdError) {
				isValid = false;
			}
		}

		if (activeStep === 1) {
			const emailError = validation.validate('buyerEmail', formData);
			const phoneError = validation.validate('buyerPhoneNumber', formData);
			setErros((prev) => ({
				...prev,
				buyerEmail: emailError,
				buyerPhoneNumber: phoneError,
			}));
			if (emailError || phoneError) {
				isValid = false;
			}
		}

		if (activeStep === 2) {
			const shippingFormData = { ...shippingAddress };
			const cityNameError = validation.validate('cityName', shippingFormData);
			const streetAddressError = validation.validate('streetAddress', shippingFormData);
			const zipCodeError = validation.validate('zipCode', shippingFormData);

			let billingAddressErrors = { ...erros.billingAddress };
			if (!useAsBillingAddress) {
				const billingFormData = { ...billingAddress };
				const cityNameBError = validation.validate('cityName', billingFormData);
				const streetAddressBError = validation.validate('streetAddress', billingFormData);
				const zipCodeBError = validation.validate('zipCode', billingFormData);
				if (cityNameBError || streetAddressBError || zipCodeBError) {
					isValid = false;
				}

				billingAddressErrors = {
					...billingAddressErrors,
					cityName: cityNameBError,
					streetAddress: streetAddressBError,
					zipCode: zipCodeBError,
				};
			}
			setErros((prev) => ({
				...prev,
				shippingAddress: {
					...prev.shippingAddress,
					cityName: cityNameError,
					streetAddress: streetAddressError,
					zipCode: zipCodeError,
				},
				billingAddress: billingAddressErrors,
			}));
			if (cityNameError || streetAddressError || zipCodeError) {
				isValid = false;
			}
		}

		if (isValid) {
			if (activeStep !== 3) {
				setActiveStep(activeStep + 1);
			}
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const handleShippingAddress = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setShippingAddress({ ...shippingAddress, [event.target.name]: event.target.value });
	};

	const handleBillingAddress = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setBillingAddress({ ...billingAddress, [event.target.name]: event.target.value });
	};

	return (
		<Box sx={{
			pl: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },
			pr: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },
			pb: Dimens.SECTION_PADDING_BOTTOM,
			mt: {
				xs: 4,
			},
		}}
		>
			<BackButton onClick={handleGoBack} />
			<Stack alignItems="center">
				<Box sx={{ width: '80%', mt: 8 }}>
					<CustomStepper steps={steps} activeStep={activeStep} />
				</Box>
			</Stack>
			{activeStep === ViewSelector.CLIENT_DETAILS && (
				<ClientDetails
					nameField={{
						name: 'buyerFullName',
						value: state.buyerFullName,
						error: !!erros.buyerFullName,
						helperText: erros.buyerFullName,
						onChange: handleChange,
					} as InputFieldProps<HTMLTextAreaElement>}
					taxIdField={{
						name: 'buyerTaxId',
						value: state.buyerTaxId,
						error: !!erros.buyerTaxId,
						helperText: erros.buyerTaxId,
						inputProps: { type: 'number' } as InputBaseComponentProps,
						onChange: handleChange,
					} as InputFieldProps<HTMLTextAreaElement>}
					companyField={{
						name: 'buyerCompanyName',
						value: state.buyerCompanyName,
						onChange: handleChange,
					} as InputFieldProps<HTMLTextAreaElement>}
					commentField={{
						name: 'comment',
						value: state.comment,
						onChange: handleChange,
					} as InputFieldProps<HTMLTextAreaElement>}
					commentCheck={state.commentCheck}
					onCommentCheckChange={(check) => setState((prev) => ({ ...prev, commentCheck: check }))}
				/>
			)}
			{activeStep === ViewSelector.CONTACTS && (
				<ContactSection
					emailField={{
						name: 'buyerEmail',
						value: state.buyerEmail,
						error: !!erros.buyerEmail,
						helperText: erros.buyerEmail,
						onChange: handleChange,
					} as InputFieldProps<HTMLTextAreaElement>}
					cellField={{
						name: 'buyerPhoneNumber',
						value: state.buyerPhoneNumber,
						onChange: handleChange,
						error: !!erros.buyerPhoneNumber,
						inputProps: { type: 'number' } as InputBaseComponentProps,
						helperText: erros.buyerPhoneNumber,
					} as InputFieldProps<HTMLTextAreaElement>}
					phoneField={{
						name: 'buyerSecondaryPhoneNumber',
						value: state.buyerSecondaryPhoneNumber,
						inputProps: { type: 'number' } as InputBaseComponentProps,
						onChange: handleChange,
					} as InputFieldProps<HTMLTextAreaElement>}
				/>
			)}
			{activeStep === ViewSelector.ADRESS_DETAILS && (
				<Box>
					{/* <CheckItem
						label={t('counter_delivery')}
						positiveButtonText={t('yes')}
						negativeButtonText={t('no')}
						positiveAction={() => setPickupAtCounter(true)}
						negativeAction={() => setPickupAtCounter(false)}
						positiveChecked={!!pickupAtCounter}
						negativeChecked={!pickupAtCounter}
					/> */}
					<AddressDetails
						title={t('shipping_address')}
						countryId={shippingAddress.countryId}
						onCountryChange={(value) => {
							setShippingAddress((prev) => ({
								...prev,
								countryId: value.id,
								countryName: value.name,
							}));
						}}
						districtId={shippingAddress.districtId}
						onDistrictChange={(value) => {
							setShippingAddress((prev) => ({
								...prev,
								districtId: value.id,
								districtName: value.name,
							}));
						}}
						addressField={{
							name: 'streetAddress',
							value: shippingAddress.streetAddress,
							error: !!erros.shippingAddress.streetAddress,
							helperText: erros.shippingAddress.streetAddress,
							onChange: handleShippingAddress,
						} as InputFieldProps<HTMLTextAreaElement>}
						postalCodeField={{
							name: 'zipCode',
							value: shippingAddress.zipCode,
							error: !!erros.shippingAddress.zipCode,
							helperText: erros.shippingAddress.zipCode,
							onChange: handleShippingAddress,
						} as InputFieldProps<HTMLTextAreaElement>}
						locationField={{
							name: 'cityName',
							value: shippingAddress.cityName,
							error: !!erros.shippingAddress.cityName,
							helperText: erros.shippingAddress.cityName,
							onChange: handleShippingAddress,
						} as InputFieldProps<HTMLTextAreaElement>}
						showCheck
						useAsBillingAddressCheck={useAsBillingAddress}
						onUseAsBillingAddressCheckChange={(value) => setUseAsBillingAddress(value)}

					/>
					{!useAsBillingAddress && (
						<AddressDetails
							title={t('billing_address')}
							countryId={billingAddress.countryId}
							onCountryChange={(value) => {
								setBillingAddress((prev) => ({
									...prev,
									countryId: value.id,
									countryName: value.name,
								}));
							}}
							districtId={billingAddress.districtId}
							onDistrictChange={(value) => {
								setBillingAddress((prev) => ({
									...prev,
									districtId: value.id,
									districtName: value.name,
								}));
							}}
							addressField={{
								name: 'streetAddress',
								value: billingAddress.streetAddress,
								error: !!erros.billingAddress.streetAddress,
								helperText: erros.billingAddress.streetAddress,
								onChange: handleBillingAddress,
							} as InputFieldProps<HTMLTextAreaElement>}
							postalCodeField={{
								name: 'zipCode',
								value: billingAddress.zipCode,
								error: !!erros.billingAddress.zipCode,
								helperText: erros.billingAddress.zipCode,
								onChange: handleBillingAddress,
							} as InputFieldProps<HTMLTextAreaElement>}
							locationField={{
								name: 'cityName',
								value: billingAddress.cityName,
								error: !!erros.billingAddress.cityName,
								helperText: erros.billingAddress.cityName,
								onChange: handleBillingAddress,
							} as InputFieldProps<HTMLTextAreaElement>}
							useAsBillingAddressCheck={useAsBillingAddress}
							onUseAsBillingAddressCheckChange={(value) => setUseAsBillingAddress(value)}

						/>
					)}
				</Box>
			)}

			{activeStep === ViewSelector.PURCHASE_SUMMARY && (
				<PurchaseSummary
					clientDetail={{
						buyerFullName: state.buyerFullName,
						buyerCompanyName: state.buyerCompanyName,
						buyerTaxId: state.buyerTaxId,
						comment: state.comment,
						commentCheck: state.commentCheck,
					}}
					contact={{
						buyerEmail: state.buyerEmail,
						buyerPhoneNumber: state.buyerPhoneNumber,
						buyerSecondaryPhoneNumber:
							state.buyerSecondaryPhoneNumber.trim() ? state.buyerSecondaryPhoneNumber : undefined,
					}}
					useShippingAsBillingAddress={useAsBillingAddress}
					shippingAddress={{
						...shippingAddress,
						zipCode: shippingAddress.zipCode.replace(/[^0-9]/g, ''),
					}}
					billingAddress={{
						...billingAddress,
						zipCode: billingAddress.zipCode.replace(/[^0-9]/g, ''),
					}}
				/>
			)}

			{activeStep !== 3 && (
				<Stack alignItems="center" sx={{ mt: 10 }}>
					<DefaultButton onClick={handleNextClick} endIcon={<ArrowForward />}>{t('next')}</DefaultButton>
				</Stack>
			)}
		</Box>
	);
};

export default PurchasePage;
