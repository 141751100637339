import { OrderBackofficeSimpleModel, OrderReadBackoffice, OrderStatus } from '../../data/dto/order-backoffice-model';
import React, {
	createContext, useContext, useMemo, useState,
} from 'react';
import { OrderApiImpl } from '../../data/remote/order-api-impl';
import { ApiResponse } from 'common/domain/entities/api-response';
import { FilterParams } from '../../data/remote/order-api';

interface FetchState {
	loading: boolean;
	error: string;
}

type TContextProps = {
	orders: OrderBackofficeSimpleModel[]
	setOrders: React.Dispatch<React.SetStateAction<OrderBackofficeSimpleModel[]>>;
	loadingOrders: boolean;
	getOrders: (filter: FilterParams) => Promise<{
		success: boolean;
		message: string;
		total: number;
	}>
	orderStatus: OrderStatus[],
	getOrderStatus: () => Promise<{
		success: boolean;
		message: string;
	}>
	updatingStatus: boolean;
	updateOrderStatus: (orderId: number, statusId: number) => Promise<{
		success: boolean;
		message: string;
	}>
	order: OrderReadBackoffice | null;
	orderByIdFetchState: FetchState;
	getOrderById: (id: number) => Promise<boolean>
	getPurchaseProofFile: (orderId: number) => Promise<{
		success: boolean;
		message: string;
		file?: File;
	}>
	proofLoading: boolean
}

const OrderBackofficeContext = createContext({} as TContextProps);

export const OrderBackofficeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [orders, setOrders] = useState<OrderBackofficeSimpleModel[]>([]);
	const [orderStatus, setOrderStatus] = useState<OrderStatus[]>([]);
	const [loadingOrders, setLoadingOrders] = useState(false);
	const [updatingStatus, setUpdatingStatus] = useState(false);

	const [orderByIdFetchState, setOrderByIdFetchState] = useState<FetchState>({ error: '', loading: false });
	const [order, setOrder] = useState<OrderReadBackoffice | null>(null);
	const [proofLoading, setProofLoading] = useState(false);
	const orderApi = new OrderApiImpl();

	const getOrders = async (filter: FilterParams) => {
		setLoadingOrders(true);
		return orderApi.getOrders(filter).then((res) => {
			setOrders(res.data);
			return ({ success: true, message: '', total: res.total });
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			return { success: false, message: responseError.message, total: 0 };
		}).finally(() => setLoadingOrders(false));
	};

	const getOrderStatus = async () => orderApi.getOrderStatus().then((res) => {
		setOrderStatus(res.data);
		return ({ success: true, message: '' });
	}).catch((err) => {
		const responseError = err as ApiResponse<void>;
		return { success: false, message: responseError.message };
	});

	const getOrderById = async (id: number) => {
		setOrderByIdFetchState({ ...orderByIdFetchState, loading: true });
		return orderApi.getOrderById(id).then((res) => {
			setOrder(res.data);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setOrderByIdFetchState({ ...orderByIdFetchState, error: responseError.message });
			return false;
		}).finally(() => {
			setOrderByIdFetchState({ ...orderByIdFetchState, loading: false });
		});
	};

	const updateOrderStatus = async (orderId: number, statusId: number) => {
		setUpdatingStatus(true);
		return orderApi.updateOrderStatus(orderId, statusId).then(() => ({ success: true, message: '' })).catch((err) => {
			const responseError = err as ApiResponse<void>;
			return { success: false, message: responseError.message };
		}).finally(() => setUpdatingStatus(false));
	};

	const getPurchaseProofFile = (orderId: number) => {
		setProofLoading(true);
		return orderApi.getPurchaseProof(orderId).then((e) => ({
			success: true,
			message: 'successs',
			file: e.data,
		})).catch((err) => {
			const responseError = err as ApiResponse<void>;
			return { success: false, message: responseError.message };
		}).finally(() => {
			setProofLoading(false);
		});
	};

	const value = useMemo(() => ({
		orders,
		setOrders,
		getOrders,
		loadingOrders,
		orderStatus,
		getOrderStatus,
		updateOrderStatus,
		updatingStatus,
		orderByIdFetchState,
		order,
		getOrderById,
		proofLoading,
		getPurchaseProofFile,
	}), [
		orders, loadingOrders, orderStatus, updatingStatus, orderByIdFetchState, order, proofLoading,
	]);

	return (
		<OrderBackofficeContext.Provider value={value}>
			{children}
		</OrderBackofficeContext.Provider>
	);
};

export default function useOrdersBackoffice() {
	return useContext(OrderBackofficeContext);
}
