import {
	Box,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import useSettingsBackoffice from '../../provider/settings-backoffice.provider';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { TextInput } from '../../components';
import { ShipmentBackoffice } from 'features/backoffice/data/dto/shipment-backoffice-model';
import { Toast } from 'primereact/toast';
import { zilaSlab } from 'assets/theme/theme';

const PortsDefinition = () => {
	const theme = useTheme();
	const {
		getShipments,
		shipments,
		shipmentLoading,
		districts,
		updateShipment,
		updateShipmentState,
		setShipments,
	} = useSettingsBackoffice();
	const { t } = useTranslation('translations');
	const toast = useRef<Toast>(null);
	const [lazyState, setlazyState] = useState({
		first: 0,
		rows: 30,
		page: 1,
		sortField: null,
		sortOrder: null,
		filters: {},
	});
	const [totalOfShipments, setTotalOfShipments] = useState<number>(0);

	useEffect(() => {
		getShipments({
			page: lazyState.page,
			size: lazyState.rows,
		}).then((res) => {
			setTotalOfShipments(res.total);
		});
	}, []);

	const onPage = (event: any) => {
		setlazyState(event);
	};

	const priceBodyTemplate = (price: number) => new Intl.NumberFormat('pt-EU', {
		style: 'currency',
		currency: 'EUR',
	}).format(price);

	const districtEditor = (options: any) => {
		const mDistricts = [{ id: null, name: t('none') }, ...districts];
		return (
			<Dropdown
				value={options?.value}
				options={mDistricts}
				onChange={(e) => options.editorCallback(e.value.id)}
				placeholder="Select a State"
				optionLabel="name"
			/>
		);
	};

	const inputNumberEditor = (options: any) => (
		<TextInput
			value={options?.value}
			type="number"
			onChange={(e) => options.editorCallback(e.target.value)}
		/>
	);

	const inputTextEditor = (options: any) => (
		<TextInput
			value={options?.value}
			type="text"
			onChange={(e) => options.editorCallback(e.target.value)}
		/>
	);

	const onRowEditComplete = (e: any) => {
		const list = [...shipments];
		const { newData, index } = e;
		const old = list[index];
		const prod = newData as ShipmentBackoffice;
		list[index] = prod;
		setShipments(list);

		updateShipment(newData).then(() => {
			toast.current?.show({
				severity: 'success',
				summary: 'Sucesso',
				detail: 'Item atualizado com sucesso',
			});
		})
			.catch(() => {
				list[index] = old;
				setShipments(list);
				toast.current?.show({
					severity: 'error',
					summary: 'Falha',
					detail: 'Uma ou mais informação não foi possível ser salva.',
				});
			});
	};

	return (
		<Box sx={{ bgcolor: theme.palette.background.default }}>
			<Toast ref={toast} />
			<Stack direction="row" sx={{ p: 2 }}>
				<Typography fontSize={22} fontFamily={zilaSlab} fontWeight={400}>
					{t('shipping_settings')}
				</Typography>
			</Stack>
	  		<Box sx={{ overflow: 'auto' }}>
				<Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
					<DataTable
						value={shipments}
						lazy
						paginator
						first={lazyState.first}
						onPage={onPage}
						rows={lazyState.rows}
						onRowEditComplete={onRowEditComplete}
						editMode="row"
						totalRecords={totalOfShipments}
						loading={shipmentLoading || updateShipmentState.loading}
						dataKey="id"
						tableStyle={{ minWidth: '50rem' }}
					>
						<Column field="id" bodyStyle={{ fontSize: 14 }} header="ID" />
						<Column
							field="name"
							bodyStyle={{ fontSize: 14 }}
							header={t('name')}
							editor={(options) => inputTextEditor(options)}
						/>
						<Column
							field="price"
							bodyStyle={{ fontSize: 14 }}
							header={t('price')}
							body={(options) => priceBodyTemplate(options.price)}
							editor={(options) => inputNumberEditor(options)}
						/>
						<Column
							field="minWeight"
							bodyStyle={{ fontSize: 14 }}
							header={t('min_weight')}
							editor={(options) => inputNumberEditor(options)}
						/>
						<Column
							field="maxWeight"
							bodyStyle={{ fontSize: 14 }}
							header={t('max_weight')}
							editor={(options) => inputNumberEditor(options)}
						/>
						<Column
							field="minDaysEstimate"
							bodyStyle={{ fontSize: 14 }}
							header={t('min_days_estimate')}
							editor={(options) => inputNumberEditor(options)}
						/>
						<Column
							field="maxDaysEstimate"
							bodyStyle={{ fontSize: 14 }}
							header={t('max_days_estimate')}
							editor={(options) => inputNumberEditor(options)}
						/>
						<Column
							field="districtName"
							bodyStyle={{ fontSize: 14 }}
							header={t('district')}
							editor={(options) => districtEditor(options)}
						/>
						<Column
							rowEditor
							headerStyle={{ width: '10%', minWidth: '8rem' }}
							bodyStyle={{ textAlign: 'center' }}
						/>
					</DataTable>
				</Box>
    	</Box>
		</Box>
	);
};

export default PortsDefinition;
