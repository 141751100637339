import React, { useEffect, useState } from 'react';
import NotFound from 'features/not-found/presentation';
import useAuth from 'features/authentication/presentation/provider/auth.provider';
import { useNavigate, useParams } from 'react-router-dom';
import OrderItem from './components/OrderItem';
import { ICartProduct } from '@/common/domain/entities/cart-product';
import {
	Box, CircularProgress, Grid,
	Stack,
	Typography, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { AppEnv } from 'common/constants/app-env';
import { Stripe, StripeElements, loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { statusTag } from 'features/authentication/presentation/customer-form/component/OrderRow';
import { Elements } from '@stripe/react-stripe-js';
import useCart from 'common/presentation/providers/cart.provider';
import { CheckoutForm } from 'common/presentation/components/checkout-form/checkout-form';
import { BodyText, DefaultButton, DefaultDivider } from 'common/presentation/components';
import { zilaSlab } from 'assets/theme/theme';
import TitleSmall from 'common/presentation/components/title-small/TitleSmall';
import { MultibancoIcon } from 'assets/images';
import { CarbonShoppingCart } from 'assets/icons';
import AppLinks from 'assets/applinks.routes';
import useNotifications from 'common/presentation/providers/notifications.provider';
import { ModalBuySuccess } from 'features/products/presentation/modals/ModalBuySuccess';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const stripePromise = loadStripe(AppEnv.STRIPE_KEY);

export const OrderStoreDetail: React.FC = () => {
	const { loading, getOrder, order } = useAuth();
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const [found, setFound] = useState(false);
	const { toast } = useNotifications();
	const navigate = useNavigate();
	const { userAuth } = useAuth();
	const [openSuccess, setOpenSuccess] = useState(false);

	const [stripe, setStripe] = useState<Stripe>();
	const [elements, setElements] = useState<StripeElements>();
	const { id } = useParams();

	const {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		createOrUpdatePayment, payment, loadingPayment,
	} = useCart();

	useEffect(() => {
		if (!id) {
			setFound(false);
			return;
		}
		getOrder(parseInt(id, 10)).then((e) => {
			setFound(e);
		});
	}, []);

	// useEffect(() => {
	//	if (order) {
	//		if()
	//	}
	// }, [order]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const onCreateOrder = async () => {
		if (!stripe || !elements) return;

		const res = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${AppEnv.HOST}${AppLinks.STORE_ORDER}/${order?.id}`,
			},
			redirect: 'if_required',
		});

		if (res.error) {
			toast.current?.show({
				severity: 'error',
				summary: 'Falha',
				detail: 'Falha ao confirmar o pagamento',
			});
		} else {
			setOpenSuccess(true);
		}
	};
	return (
		<Box
			sx={{
				pl: 8,
				pr: 8,
				pb: Dimens.SECTION_PADDING_BOTTOM,
				mt: {
					xs: 4,
				},
			}}
		>
			{loading && (
				<div>loading</div>
			)}
			{!found && !loading && (
				<NotFound />
			)}
			<Grid container spacing={2}>
				<Grid item sm={12} md={8}>
					<Stack direction="row" alignContent="center" alignSelf="center" justifyItems="center" justifyContent="space-between">
						<Typography
							sx={{
								color: theme.palette.primary.contrastText,
								fontWeight: Dimens.FONT_BOLDER,
								fontSize: '24px',
								alignSelf: 'start',
								mb: 4,
							}}
						>
							{t('order').toUpperCase()}
							{' '}
							#
							{order?.id}
						</Typography>
						<Box height={20}>
							{order && statusTag(order)}
						</Box>
					</Stack>

					{order?.items?.map((e) => (
						<OrderItem
							product={e.product as ICartProduct}
							quantity={e.quantity}
							price={e.priceWithTaxes}
						/>
					))}
				</Grid>

				<Grid item sm={12} md={4}>
					{!!order && order.paymentDetail != null && order.paymentDetail.status === 'requires_payment_method' && (
						<Elements
							stripe={stripePromise}
							options={{
								clientSecret: order?.paymentDetail.secret,
							}}
						>
							<Stack
								sx={{
									border: '1px solid #CCCCCC',
									p: 4,
								}}
							>
								<CheckoutForm
									didStripeLoad={(st) => setStripe(st)}
									didElementLoad={(el) => setElements(el)}
								/>

								<Stack alignItems="center" sx={{ mt: 3, mb: 1 }}>
									<DefaultButton
										disabled={loading}
										onClick={onCreateOrder}
										endIcon={<CarbonShoppingCart />}
									>
										{loading && <CircularProgress color="secondary" size={18} sx={{ mr: 1 }} />}
										{t('confirm')}
									</DefaultButton>
								</Stack>
							</Stack>
						</Elements>
					)}

					{!!order && order.paymentDetail != null && !!order.paymentDetail.entity && (
						<Stack
							sx={{
								border: '1px solid #CCCCCC',
								p: 4,
							}}
						>
							<Stack direction="row" justifyContent="space-between">
								<BodyText sx={{ fontSize: Dimens.FONT_SIZE_SMALL, fontFamily: zilaSlab }}>
									{t('cart_subtotal')}
								</BodyText>
								<BodyText sx={{
									fontSize: Dimens.FONT_SIZE_SMALL_TITLE, fontWeight: Dimens.FONT_BOLD,
								}}
								>
									{`${Number.parseFloat(`${order.amount}`).toFixed(2) || 0} €`}
								</BodyText>
							</Stack>
							<DefaultDivider sx={{ mt: 2, mb: 2 }} width="100%" />
							<Stack
								direction="row"
								justifyContent="space-between"
								sx={{
									mt: 1,
								}}
							>
								<Stack direction="column">

									<img width={150} src={MultibancoIcon} alt="Multibanco icon" />

									<BodyText sx={{
										color: theme.palette.secondary.main,
										fontWeight: Dimens.FONT_NORMAL,
										display: 'flex',
										fontSize: 14,
										gap: 1,
										mt: 2,
									}}
									>
										<BodyText component="span" sx={{ fontSize: 14 }}>{t('entity').toUpperCase()}</BodyText>
										{order.paymentDetail.entity}
									</BodyText>

									<BodyText sx={{
										color: theme.palette.secondary.main,
										fontWeight: Dimens.FONT_NORMAL,
										display: 'flex',
										fontSize: 14,
										gap: 1,
									}}
									>
										<BodyText component="span" sx={{ fontSize: 14 }}>{t('reference').toUpperCase()}</BodyText>
										{order.paymentDetail.reference}
									</BodyText>

								</Stack>
								<TitleSmall sx={{
									fontSize: Dimens.FONT_SIZE_LARGE_SMALL,
									fontWeight: Dimens.FONT_BOLD,
								}}
								>
									{`${Number.parseFloat(`${order.amount}`).toFixed(2) || 0} €`}
								</TitleSmall>
							</Stack>
						</Stack>
					)}
				</Grid>
			</Grid>
			<ModalBuySuccess
				open={openSuccess}
				authenticated={!!userAuth}
				onOpenChange={(value) => setOpenSuccess(value)}
				onSeeOrders={() => navigate(AppLinks.CLIENT_AREA)}
				onClose={() => {
					navigate(userAuth ? AppLinks.CLIENT_AREA : AppLinks.PRODUCTS);
				}}
			/>
		</Box>
	);
};
