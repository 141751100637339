import React from 'react';
import {
	Box, Stack, Grid, useMediaQuery, useTheme, CircularProgress,
} from '@mui/material';
import { heroLanding } from 'assets/images';
import Dimens from 'assets/dimens';
import { BodyText, DefaultButton, TitleMedium } from 'common/presentation/components';
import TextField from 'common/presentation/components/text-field/TextField';
import { AtField, PersonField, Key } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { Validation } from 'common/domain/entities/validation';
import useAuth from '../provider/auth.provider';
import AppLinks from 'assets/applinks.routes';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useNotificationsContext from 'common/presentation/providers/notifications.provider';

type Props = {
	validation: Validation
}

const RegisterPage: React.FC<Props> = ({ validation }) => {
	const { t } = useTranslation('translations');
	const { toast } = useNotificationsContext();
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const { loading, error, createUser } = useAuth();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [state, setState] = React.useState({
		isFormValid: true,
		email: '',
		emailError: '',
		name: '',
		nameError: '',
		password: '',
		passwordError: '',
		repeatPassword: '',
		repeatPasswordError: '',
	});

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const onBlurValidation = (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
	) => {
		const formData = {
			...state,
		};
		const fieldError = validation.validate(event.target.name, formData);
		setState({
			...state,
			[`${event.target.name}Error`]: fieldError,
		});
	};

	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const {
			name, email, password, repeatPassword,
		} = state;
		const formData = {
			name, email, password, repeatPassword,
		};

		const emailError = validation.validate('email', formData);
		const nameError = validation.validate('name', formData);
		const passwordError = validation.validate('password', formData);
		const repeatPasswordError = validation.validate('repeatPassword', formData);

		const isFormValid = !(!!emailError
			|| !!nameError || !!passwordError || !!repeatPasswordError);

		setState({
			...state,
			emailError,
			nameError,
			passwordError,
			repeatPasswordError,
			isFormValid,
		});

		if (isFormValid) {
			createUser(state.name, state.email, state.password).then((didSuccess) => {
				if (didSuccess) {
					toast.current?.show({
						severity: 'success',
						summary: 'Sucesso',
						detail: 'Conta criada com sucesso',
					});
					if (searchParams.get('purchase')) {
						navigate(`${AppLinks.LOGIN}?purchase=true`);
						return;
					}
					navigate(AppLinks.LOGIN);
				} else {
					toast.current?.show({
						severity: 'error',
						summary: 'Falha',
						detail: error,
					});
				}
			});
		}

		return false;
	};

	return (
		<Box sx={{
			backgroundImage: isMd ? `url(${heroLanding})` : 'none',
			backgroundPosition: 'left top',
			backgroundRepeat: 'no-repeat',
			backgroundSize: '50% 100%',
			mt: {
				md: Dimens.INTRO_HERO_IMAGE_TOP,
				xs: -10,
			},
			mb: 8,
		}}
		>
			<Stack
				alignItems="flex-end"
				sx={{
					pl: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },
					pt: { md: Dimens.INTRO_HERO_IMAGE_TOP_POSITIVE, xs: Dimens.SECTION_SPACING },
				}}
			>
				<Stack justifyContent="center" sx={{ width: { md: '50%', xs: '100%' }, height: '85vh' }}>
					<form onSubmit={onSubmit}>
						<Stack justifyContent="center" sx={{ height: '100%' }}>
							<TitleMedium>
								{t('welcome_to_volt')}
							</TitleMedium>
							<BodyText sx={{ mt: 2 }}>
								{t('create_account_info')}
							</BodyText>
							<Grid container sx={{ mt: 5, width: '100%' }} spacing={2}>
								<Grid item md={12} xs={12}>
									<TextField
										name="name"
										value={state.name}
										placeholder={t('name_and_surname')}
										error={Boolean(state.nameError)}
										helperText={state.nameError}
										onChange={handleChange}
										onBlur={onBlurValidation}
										startIcon={<PersonField />}
									/>
								</Grid>
								<Grid item md={12} xs={12}>
									<TextField
										name="email"
										value={state.email}
										placeholder="Email"
										error={Boolean(state.emailError)}
										helperText={state.emailError}
										onChange={handleChange}
										onBlur={onBlurValidation}
										startIcon={<AtField />}
									/>
								</Grid>
								<Grid item md={12} xs={12}>
									<TextField
										name="password"
										value={state.password}
										type="password"
										onChange={handleChange}
										error={Boolean(state.passwordError)}
										helperText={state.passwordError}
										onBlur={onBlurValidation}
										placeholder={t('set_your_password')}
										startIcon={<Key />}
									/>
								</Grid>
								<Grid item md={12} xs={12}>
									<TextField
										name="repeatPassword"
										value={state.repeatPassword}
										type="password"
										onChange={handleChange}
										error={Boolean(state.repeatPasswordError)}
										onBlur={onBlurValidation}
										placeholder={t('repeat_the_password')}
										startIcon={<Key />}
									/>
								</Grid>
								<Grid item md={12} xs={12} mt={3}>
									<Stack>
										<DefaultButton disabled={loading} type="submit" sx={{ alignSelf: 'center' }}>
											{loading && <CircularProgress size={30} />}
											{t('create_account')}
										</DefaultButton>
									</Stack>
								</Grid>

								<Grid item md={12}>
									<BodyText sx={{ mt: 2 }}>
										{t('already_have_volt_account')}
										{' '}
										<Link style={{ textDecoration: 'none', color: '#B9C92B' }} to={AppLinks.LOGIN}>{t('enter')}</Link>
									</BodyText>
								</Grid>
							</Grid>
						</Stack>
					</form>
				</Stack>
			</Stack>
		</Box>
	);
};

export default RegisterPage;
