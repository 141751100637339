import {
	InputAdornment,
	OutlinedInput as MuiTextField,
	OutlinedInputProps,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import SizedBox from '../sized-box/SizedBox';

interface Props extends OutlinedInputProps {
	startIcon?: React.ReactNode
	endIcon?: React.ReactNode
	background?: string
	helperText?: string
	optional?: boolean
}

const TextField: React.FC<Props> = (props) => {
	const theme = useTheme();
	const {
		startIcon, endIcon, background, label, helperText, error, optional, ...remainingProps
	} = props;
	return (
		<>
			<Stack direction="row" justifyContent="space-between">

				<Typography sx={{
					color: theme.palette.textMain.main,
					fontSize: Dimens.FONT_SIZE_SMALL,
					fontWeight: Dimens.FONT_NORMAL,
				}}
				>
					{label}
				</Typography>
				<Typography sx={{
					color: theme.palette.secondary.main,
					fontSize: Dimens.FONT_SIZE_SMALL,
					fontWeight: Dimens.FONT_NORMAL,
				}}
				>
					{optional && 'Optional'}
				</Typography>
			</Stack>
			<SizedBox height={0.7} />
			<MuiTextField
				autoComplete="off"
				error={error}
				startAdornment={startIcon && (
					<InputAdornment position="start">
						{startIcon}
					</InputAdornment>
				)}
				endAdornment={(
					endIcon && (
						<InputAdornment position="end">
							{endIcon}
						</InputAdornment>
					)
				)}
				sx={{
					p: '4px',
					background: background || theme.palette.background.paper,
					'& fieldset': {
						borderRadius: '0px',
					},
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderColor: theme.palette.fieldOutline.main,
							transition: 'border-color 0.3s',
							textAlign: 'right',
						},
					},
					'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.palette.fieldOutline.main,
					},
				}}
				size="small"
				{...remainingProps}
				fullWidth
			/>
			{error && (
				<Typography sx={{
					color: theme.palette.error.main,
					fontSize: Dimens.FONT_SIZE_SMALL,
					fontWeight: Dimens.FONT_NORMAL,
				}}
				>
					{helperText}
				</Typography>
			)}
		</>
	);
};

export default TextField;
