import { ApiResponse } from '@/common/domain/entities/api-response';
import { OrderBackofficeSimpleModel, OrderReadBackoffice, OrderStatus } from '../dto/order-backoffice-model';
import { FilterParams, OrderApi } from './order-api';
import http from './http';
import { httpGet, httpPatch } from 'common/utils/http.utils';
import { serializeFormQuery } from 'common/utils/general.utils';
import axios, { AxiosError } from 'axios';

export class OrderApiImpl implements OrderApi {
	apiService = http;

	getOrders(filter: FilterParams): Promise<ApiResponse<OrderBackofficeSimpleModel[]>> {
		const query = serializeFormQuery(filter);
		return httpGet(`/orders${query}`, this.apiService);
	}

	getOrderById(id: number): Promise<ApiResponse<OrderReadBackoffice>> {
		return httpGet(`/orders/${id}`, this.apiService);
	}

	getOrderStatus(): Promise<ApiResponse<OrderStatus[]>> {
		return httpGet('/orders-statuses', this.apiService);
	}

	updateOrderStatus(orderId: number, statusId: number): Promise<ApiResponse<string>> {
		return httpPatch(`/orders/status/${orderId}?newStatus=${statusId}`, {}, this.apiService);
	}

	async getPurchaseProof(orderId: number): Promise<ApiResponse<File>> {
		try {
			// change this address
			const resp = await this.apiService.get(`/orders/${orderId}/proof`, {
				responseType: 'blob',
			});

			const blob = resp.data as Blob;

			return {
				message: 'success',
				data: new File([blob], `volt_${orderId}_purchase_proof.pdf`),
			} as ApiResponse<File>;
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = JSON.parse(await err.response?.data.text()) as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}
}
