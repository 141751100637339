import {
	Box, Divider, Link, Stack, SvgIcon, Typography, styled, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { MultibancoIconToDark, logoBig, PayPalIcon } from 'assets/images';
import React from 'react';
import {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	Call, Facebook, Farward, Instagram, LinkedIn, Mail, LinkMethod,
} from 'assets/icons';
import { ArrowRightAltOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { inter } from 'assets/theme/theme';
import SizedBox from '../sized-box/SizedBox';
import DefaultButton from '../default-button/DefaultButton';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import BodyText from '../body-text/BodyText';
import PaymentIcon from '@mui/icons-material/Payment';

type TextProps = {
	color?: string;
	bold?: boolean;
	showDivider?: boolean
}

const DefaultText = styled(Typography, {
	shouldForwardProp: (props) => props !== 'showDivider' && props !== 'bold' && props !== 'color',
})<TextProps>(({
	theme, color, bold = false, showDivider = false,
}) => ({
	fontSize: Dimens.FONT_SIZE_SMALL,
	color: color || theme.palette.footerbg.contrastText,
	fontWeight: bold ? Dimens.FONT_BOLDER : Dimens.FONT_THIN,
	fontFamily: inter,
	[theme.breakpoints.down('md')]: {
		borderLeft: Dimens.BORDER_WIDTH_DIVIDER,
		borderLeftStyle: showDivider ? 'solid' : 'none',
		paddingLeft: showDivider ? Dimens.TEXT_PADDING : '0px',
		borderLeftColor: theme.palette.secondary.main,
	},
	'&:hover': {
		cursor: 'pointer',
	},
}));
const SmallText = styled(Typography)(({ theme }) => ({
	fontSize: Dimens.FONT_SIZE_EXTRA_SMALL,
	color: theme.palette.footerbg.contrastText,
	fontFamily: inter,
}));

interface ListItemProps {
	icon: React.ReactNode,
	title: string,
	subtitle: string,
	infoText?: string
	onClick?: () => void
}

const FooterListItem: React.FC<ListItemProps> = ({
	icon, title, subtitle, infoText, onClick,
}) => {
	const { palette } = useTheme();
	return (
		<>
			<SizedBox height={1.5} />
			<Stack onClick={onClick} direction="row" sx={{ width: Dimens.FOOTER_LIST_ITEM_WIDTH }} justifyContent="space-between" alignItems="center">
				<Box>
					<DefaultText color={palette.secondary.main}>
						{title}
					</DefaultText>
					<SizedBox height={0.7} />
					<DefaultText>
						{subtitle}
					</DefaultText>
					{infoText && (
						<SmallText>
							{infoText}
						</SmallText>
					)}
				</Box>
				<SvgIcon color="primary">
					{icon}
				</SvgIcon>
			</Stack>
			<SizedBox height={1.5} />
			<Divider variant="fullWidth" sx={{ color: 'white' }} />
		</>
	);
};

const AppFooter = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();

	const renderButton = () => (
		<Box sx={{
			display: { md: 'flex', xs: 'block' }, flexDirection: 'column', justifyContent: 'flex-end',
		}}
		>
			<DefaultButton
				endIcon={<ArrowRightAltOutlined />}
				onClick={() => navigate(AppLinks.CONTACT_US)}
			>
				{t('contact')}
			</DefaultButton>
			<SizedBox height={2.5} />
			<Stack direction="row" spacing={4}>
				<a href="https://www.facebook.com/VoltVirgilioOliveira" target="_blank" rel="noreferrer">
					<SvgIcon>
						<Facebook />
					</SvgIcon>
				</a>
			</Stack>

			<BodyText>
				{t('payment_methods')}
				:
			</BodyText>
			<Stack direction="row" gap={1} alignItems="baseline">
				<PaymentIcon sx={{ color: '#FFFFFF' }} />
				<LinkMethod width={25} />
				<img width={80} style={{ objectFit: 'contain' }} src={MultibancoIconToDark} alt="Multibanco icon" />
				<img width={80} style={{ objectFit: 'contain' }} src={PayPalIcon} alt="Paypal icon" />
			</Stack>
		</Box>
	);

	return (
		<footer>
			<Box sx={{
				pt: { md: Dimens.FOOTER_VERTICAL_PADDING, xs: Dimens.FOOTER_VERTICAL_PADDING_MOBILE },
				pb: { md: Dimens.FOOTER_VERTICAL_PADDING, xs: Dimens.FOOTER_VERTICAL_PADDING_MOBILE },
				pl: { md: Dimens.FOOTER_HORIZANTAL_PADDING, xs: Dimens.FOOTER_HORIZANTAL_PADDING_MOBILE },
				pr: { md: Dimens.FOOTER_HORIZANTAL_PADDING, xs: Dimens.FOOTER_HORIZANTAL_PADDING_MOBILE },
				bgcolor: theme.palette.footerbg.main,
			}}
			>
				<Stack direction="column">
					<Box sx={{
						[theme.breakpoints.down('md')]: {
							borderBottom: Dimens.BORDER_WIDTH_DIVIDER_SMALL,
							borderBottomColor: theme.palette.secondary.main,
							borderBottomStyle: 'solid',
							pb: Dimens.FOOTER_VERTICAL_PADDING_MOBILE,
						},
					}}
					>
						<img src={logoBig} alt="logo" />
					</Box>
					<Stack direction={{ md: 'row', xs: 'column-reverse' }} justifyContent={{ md: 'space-between', xs: 'flex-start' }} sx={{ mt: 3 }}>
						<Box sx={{ display: { md: 'none', xs: 'block' }, mt: Dimens.FOOTER_SECTION_PADDING_MOBILE }}>
							{renderButton()}
						</Box>
						<Box>
							<Stack direction={{ md: 'column', xs: 'row' }} sx={{ mt: { md: 0, xs: Dimens.FOOTER_SECTION_PADDING_MOBILE } }}>
								<DefaultText
									onClick={() => navigate(AppLinks.TERMS_AND_CONDITIONS_OF_USE)}
								>
									{t('terms_and_conditions')}
								</DefaultText>
								<SizedBox />

								<DefaultText
									sx={{ ml: { xs: 1, md: 0 } }}
									showDivider
									onClick={() => navigate(AppLinks.DELIVERY_TERMS)}
								>
									{t('delivery')}
								</DefaultText>

							</Stack>
							<SizedBox />
							<Stack direction={{ md: 'column', xs: 'row' }}>

								<DefaultText
									onClick={() => navigate(AppLinks.GUARANTEES_AND_RETURNS)}
								>
									{t('warrenties_and_return')}
								</DefaultText>

								<SizedBox />
								<a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank" rel="noreferrer">
									<DefaultText
										sx={{ ml: { xs: 1, md: 0 } }}
										showDivider
										onClick={() => navigate(AppLinks.PRODUCT_CATELOG)}
									>
										{t('complaint_book')}
									</DefaultText>
								</a>
							</Stack>
						</Box>
						<Box sx={{ display: { md: 'block', xs: 'none' } }}>
							<DefaultText color={theme.palette.secondary.main} bold>
								{'Volt.pt'.toUpperCase()}
							</DefaultText>
							<SizedBox height={1.5} />
							<DefaultText
								onClick={() => navigate(AppLinks.LANDING)}
							>
								{t('about_us')}
							</DefaultText>
							<SizedBox height={1.5} />
							<DefaultText
								color={theme.palette.secondary.main}
								bold
								onClick={() => navigate(AppLinks.PRODUCTS)}
							>
								{t('products').toUpperCase()}
							</DefaultText>
							<SizedBox height={0.5} />
							{/*
							<DefaultText>
								{t('new_products')}
							</DefaultText>
							<SizedBox height={1.5} />
							<DefaultText>
								{t('top_selling_products')}
							</DefaultText>
							*/}
							<SizedBox height={1.5} />
							<DefaultText
								color={theme.palette.secondary.main}
								bold
								onClick={() => navigate(AppLinks.PRODUCT_CATELOG)}
							>
								{t('product_catalogs').toUpperCase()}
							</DefaultText>
						</Box>
						<Box sx={{
							mt: {
								md: Dimens.FOOTER_NEGATIVE_PADDING,
								xs: Dimens.BUTTON_PADDING_TOP_BOTTOM,
							},
						}}
						>
							<DefaultText color={theme.palette.secondary.main} bold>
								{'ARMAZÉM LEIRIA'.toUpperCase()}
							</DefaultText>
							<a
								href="https://maps.app.goo.gl/ruJqCjh4BR6PRKTN6"
								target="_blank"
								rel="noreferrer"
								style={{ textDecoration: 'none' }}
							>
								<FooterListItem
									title={t('address')}
									subtitle="Travessa do Pisão - Edifísio DC 1 Parceiros, 2400-473 Leiria"
									icon={<Farward />}
								/>
							</a>
							<FooterListItem
								title={t('phone')}
								subtitle="+351 244 850 160"
								infoText={t('fixed_mobile_network_call')}
								icon={<Call />}
							/>
							<FooterListItem
								title="Email"
								subtitle="info@volt.pt"
								icon={<Mail />}
							/>
						</Box>
						<Box sx={{ display: { md: 'flex', xs: 'none' } }}>
							{renderButton()}
						</Box>
					</Stack>
					<SizedBox height={5} />
					<SmallText>
						© 2024
						{' '}
						<Link sx={{ color: theme.palette.primary.main, textDecoration: 'none' }} href="/#" target="_blank">VOLT - Virgílio & Oliveira</Link>
						{' '}
						{t('all_rights_reserved')}
					</SmallText>
				</Stack>
			</Box>
		</footer>
	);
};

export default AppFooter;
