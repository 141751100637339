import React, { useEffect, useRef, useState } from 'react';
import {
	Box, Stack, useTheme, Grid, Chip, IconButton, Skeleton,
	Select, MenuItem, FormControl, Typography,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { BodyText } from 'common/presentation/components';
import ProductCard from 'common/presentation/components/product-card/ProductCard';
import {
	ChevronLeft, ChevronRight, Close, Search,
} from '@mui/icons-material';
import { zilaSlab } from 'assets/theme/theme';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import { useTranslation } from 'react-i18next';
import FilterSideMenu from '../components/FilterSideMenu';
import useProduct from '../provider/product.provider';
import { FilterParams } from '../../data/remote/product-api';
import { serializeFormQuery } from 'common/utils/general.utils';
import { Brand } from '../../domain/entities/Brand';
import useSearch from '../provider/search.provider';
import { CategoryMenu, createBreadCrumb } from '../components/CategoryMenu';
import { ProductCategoryModel } from '../../data/dto/product-category-model';
import { ProductModel } from '../../data/dto/product-model';
import { ModalProductBuy } from '../modals/ModalProductBuy';
import useCart from 'common/presentation/providers/cart.provider';

const ProductList = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const {
		products, getProductsByFilter, brands, productLoading, totalProducts,
	} = useProduct();
	const { addToCart } = useCart();
	const [filter, setFilter] = useState<FilterParams>({} as FilterParams);
	const [filterApplied, setFilterApplied] = useState<FilterParams>({} as FilterParams);
	const [searchParams, setSearchParams] = useSearchParams();
	const [breadcrumb, setBreadcrumb] = useState([] as ProductCategoryModel[]);

	// handle model to add to add product to bag
	const [selectedProduct, setSelectedProduct] = useState<ProductModel>();
	const [openBuyModal, setOpenBuyModel] = useState<boolean>(false);

	const navigate = useNavigate();
	const { search: searchQuery, setSearch, categories } = useSearch();
	const pageNumber = useRef(1);
	const RESULT_SIZE = 24;
	/**
	 * Component Init,  get parametters from url and update filterApplied
	 */
	useEffect(() => {
		const brandsIds = searchParams.getAll('brandsIds').map((it) => parseInt(it, 10));
		const searchString = searchParams.get('search') || undefined;
		const sortBy = searchParams.get('sortBy') || 'asc_price';
		const categoryId = searchParams.get('categoryId') ? parseInt(searchParams.get('categoryId') || '', 10) : undefined;

		const page = parseInt(searchParams.get('page') || '1', 10);
		pageNumber.current = page;

		const mFilter = {
			brandsIds,
			search: searchString,
			page,
			sortBy,
			categoryId,
		} as unknown as FilterParams;
		setFilterApplied(mFilter);
	}, []);

	/**
	 * When filter Applied change a new request is made to the server
	 *
	 */
	useEffect(() => {
		if (Object.keys(filterApplied).length > 0) {
			setFilter({ ...filterApplied, brandsIds: [...(filterApplied.brandsIds || [])] });
			getProductsByFilter({ ...filterApplied, size: RESULT_SIZE });
			setSearchParams(serializeFormQuery(filterApplied));
		}
		document.querySelector('#products-scroll')?.scrollTo(0, 0);
		window.scrollTo(0, 0);
	}, [filterApplied]);

	/**
	 * searchQuery comes from useSearch and it value is changed by AppBarSearch using context API
	 */
	useEffect(() => {
		if (Object.keys(filterApplied).length > 0) {
			pageNumber.current = 1;
			setFilterApplied({ search: searchQuery, page: 1 });
		}
		console.log(searchQuery);
	}, [searchQuery]);

	/**
	 * if filterApplied was already initialized and the categoryID in the url
	 * changed then update filter
	 */
	useEffect(() => {
		const categoryId = searchParams.get('categoryId') ? parseInt(searchParams.get('categoryId') ?? '', 10) : undefined;
		const newSearch = searchParams.get('search') ? searchParams.get('search') ?? undefined : undefined;
		if (Object.keys(filterApplied).length > 0 && categoryId !== filterApplied.categoryId) {
			pageNumber.current = 1;
			setFilterApplied({
				...filterApplied, categoryId, page: 1, search: newSearch,
			});
		}
	}, [searchParams]);

	useEffect(() => {
		const result = createBreadCrumb(categories, filterApplied.categoryId);
		setBreadcrumb(result);
	}, [categories, filterApplied]);

	const handleBrandDelete = (item: Brand) => {
		const brandsIds = filterApplied.brandsIds ?? [];
		const index = brandsIds.indexOf(item.id);
		if (index !== -1) {
			brandsIds.splice(index, 1);
		}
		pageNumber.current = 1;
		setFilterApplied({ ...filterApplied, brandsIds, page: 1 });
	};

	const handleRemoveSearch = () => {
		if (searchQuery) {
			setSearch(undefined); // will clean App Bar search field
		} else {
			pageNumber.current = 1;
			setFilterApplied({ ...filterApplied, search: undefined, page: 1 });
		}
	};

	const handleDetailClick = (id: number) => { navigate(`${AppLinks.PRODUCT_DETAIL}/${id}`); };
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const handleCartClick = (item: ProductModel) => {
		setSelectedProduct(item);
		setOpenBuyModel(true);
	};

	const onApplyFilter = () => {
		pageNumber.current = 1;
		setFilterApplied({ ...filter, page: 1 });
	};

	const onFilterChange = (params: FilterParams) => {
		pageNumber.current = 1;
		setFilter({ ...params, page: 1 });
	};

	const onClearFilter = () => {
		pageNumber.current = 1;
		setFilterApplied({ page: 1, sortBy: filterApplied.sortBy });
	};

	const goToPage = (page: number) => {
		pageNumber.current = page;
		setFilterApplied({ ...filterApplied, page });
		document.querySelector('#filter-scroll')?.scrollTo(0, 0);
		window.scrollTo(0, 0);
	};

	const onBreadClick = (categoryId: number | undefined) => {
		setFilterApplied({ ...filterApplied, categoryId, page: 1 });
	};

	const totalPages = Math.ceil(totalProducts / RESULT_SIZE);
	return (
		<Box sx={{ display: 'flex', justifyContent: 'center' }}>
			<Box sx={{
				...Dimens.PAGE_HORIZONTAL_PADDING,
				mb: Dimens.SECTION_PADDING_BOTTOM,
				maxWidth: Dimens.PAGE_MAX_WIDTH,
			}}
			>
				<Stack
					direction="row"
					sx={{
						display: {
							xs: 'none',
							sm: 'flex',
						},
					}}
				>

					<BodyText
						sx={{
							fontSize: Dimens.FONT_SIZE_SMALL,
							color: theme.palette.secondary.main,
							fontWeight: Dimens.FONT_BOLDER,
							cursor: 'pointer',
							'&:hover': {
								textDecoration: 'underline',
							},
							pr: 2,
							pt: 2,
							pb: 2,
						}}
						onClick={() => onBreadClick(undefined)}
					>
						{t('products').toUpperCase()}
					</BodyText>

					{breadcrumb.length > 0 && (
						<BodyText
							sx={{
								fontSize: Dimens.FONT_SIZE_SMALL,
								color: theme.palette.secondary.main,
								fontWeight: Dimens.FONT_BOLDER,
								cursor: 'pointer',
								'&:hover': {
									textDecoration: 'underline',
								},
								pt: 2,
								pb: 2,
							}}
						>
							/
						</BodyText>
					)}

					{breadcrumb.map((item, index) => (
						<div key={`bread-${item.productCategoryId}`} style={{ display: 'flex', flexDirection: 'row' }}>
							{!!index && (
								<BodyText
									sx={{
										fontSize: Dimens.FONT_SIZE_SMALL,
										color: theme.palette.secondary.main,
										fontWeight: Dimens.FONT_BOLDER,
										cursor: 'pointer',
										'&:hover': {
											textDecoration: 'underline',
										},
										pt: 2,
										pb: 2,
									}}
								>
									/
								</BodyText>
							)}
							<BodyText
								onClick={() => onBreadClick(item.productCategoryId)}
								sx={{
									fontSize: Dimens.FONT_SIZE_SMALL,
									color: theme.palette.secondary.main,
									fontWeight: Dimens.FONT_BOLDER,
									cursor: 'pointer',
									'&:hover': {
										textDecoration: 'underline',
									},
									p: 2,
								}}
							>
								{item.name.toUpperCase()}
							</BodyText>
						</div>
					))}
				</Stack>
				<Grid container spacing={2}>
					<Grid
						id="filter-scroll"
						item
						md={3}
						sm={12}
						xs={12}
						sx={{
							overflowY: 'scroll',
							'&::-webkit-scrollbar': { display: 'none' },
							scrollbarWidth: 'none',
							msOverflowStyle: 'none',
							height: '85vh',
						}}
					>
						<Stack direction="column" sx={{ display: { md: 'flex', xs: 'flex' } }}>
							<CategoryMenu
								selected={filterApplied.categoryId}
								categories={categories}
								onClick={(value) => {
									pageNumber.current = 1;
									return setFilterApplied({
										categoryId: value, page: 1,
									});
								}}
								sx={{ mt: 5 }}
							/>
							<Box sx={{
								mt: 2, display: { md: 'block' },
							}}
							>
								<FilterSideMenu
									value={filter}
									onChange={onFilterChange}
									onApplyFilter={onApplyFilter}
									onClearFilter={onClearFilter}
								/>
							</Box>
						</Stack>
					</Grid>
					<Grid
						id="products-scroll"
						item
						md={9}
						sm={12}
						sx={{
							overflowY: 'scroll',
							'&::-webkit-scrollbar': { display: 'none' },
							scrollbarWidth: 'none',
							msOverflowStyle: 'none',
							height: '85vh',
						}}
					>
						<Stack direction="row" sx={{ mt: 3, gap: 1 }}>
							{filterApplied.search && <Chip label={filterApplied.search} icon={<Search />} onDelete={handleRemoveSearch} deleteIcon={<Close />} sx={{ borderRadius: '0px' }} />}
							{brands.filter((it) => (filterApplied.brandsIds || []).indexOf(it.id) !== -1)
								.map((brand, key) => <Chip key={`brand-${key.toString()}-${brand.id}`} label={brand.name} onDelete={() => handleBrandDelete(brand)} deleteIcon={<Close />} sx={{ borderRadius: '0px' }} />)}

						</Stack>
						<Grid container columns={2} gap={2} justifyContent="space-between" sx={{ mt: 3 }}>
							<BodyText sx={{
								fontSize: Dimens.FONT_SIZE_SMALL,
								fontFamily: zilaSlab,
								fontWeight: Dimens.FONT_BOLD,
								color: theme.palette.secondary.main,
							}}
							>
								{t('found_results', { total: totalProducts })}
							</BodyText>
							<Stack direction="row" gap={2} sx={{ display: { md: 'flex', xs: 'flex' } }}>
								<BodyText sx={{
									fontSize: Dimens.FONT_SIZE_BODY,
									fontFamily: zilaSlab,
									fontWeight: Dimens.FONT_BOLDER,
									color: theme.palette.textMain.main,
								}}
								>
									{t('order_by').toUpperCase()}
								</BodyText>

								<FormControl
									sx={{ minWidth: 150 }}
									size="small"
								>
									<Select
										labelId="demo-select-small-label"
										id="demo-select-small"
										label="Age"
										variant="standard"
										value={filterApplied.sortBy ? filterApplied.sortBy : 'asc_price'}
										disableUnderline
										onChange={(event) => {
											pageNumber.current = 1;
											setFilterApplied({ ...filterApplied, sortBy: event.target.value, page: 1 });
										}}
									>
										<MenuItem value="asc_price">Menor preço</MenuItem>
										<MenuItem value="desc_price">Maior preço</MenuItem>
									</Select>
								</FormControl>
							</Stack>
						</Grid>
						<Grid
							container
							spacing={2}
							columns={4}
							minWidth={{
								md: 960,
								sm: 600,
								xs: '100%',
							}}
							sx={{ mt: 3 }}
						>

							{productLoading && [...Array(RESULT_SIZE).keys()].map((item) => (
								<Grid item key={`skeleton-product-${item}`}>
									<Stack spacing={0.5}>
										<Skeleton variant="rectangular" width={230} height={210} />
										<Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
										<Skeleton variant="rounded" height={40} />
										<Stack direction="row" gap={1}>
											<Skeleton variant="rectangular" sx={{ flex: 1 }} height={60} />
											<Skeleton variant="rounded" width={85} height={60} />
										</Stack>
									</Stack>
								</Grid>
							))}

							{!productLoading && products && products.map((product, key) => (
								<Grid
									item
									key={`prodct-${product.productId}-${key.toString()}`}
								>
									<ProductCard
										image={product.image}
										price={`${product.priceWithTaxes}`}
										reference={product.reference || ''}
										info={product.name ?? ''}
										onDetailClick={() => handleDetailClick(product.productId)}
										onCartClick={() => handleCartClick(product as ProductModel)}
									/>
								</Grid>
							))}

							{totalProducts === 0 && (
								<Grid item md={12} sx={{ display: 'flex' }} justifyContent="center">
									<Typography sx={{ mt: 6 }}>
										{t('items_not_found')}
									</Typography>
								</Grid>
							)}

						</Grid>
						{totalProducts !== 0 && (
							<Stack direction="row" justifyContent="center" alignItems="center">
								<IconButton
									disabled={pageNumber.current <= 1}
									onClick={() => goToPage(pageNumber.current - 1)}
								>
									<ChevronLeft sx={{ height: '39px' }} />
								</IconButton>
								<BodyText sx={{ color: theme.palette.secondary.main }}>
									{t('page')}
									{' '}
									{pageNumber.current}
									{' '}
									{t('of')}
									{' '}
									{totalPages}
								</BodyText>
								<IconButton
									onClick={() => goToPage(pageNumber.current + 1)}
									disabled={pageNumber.current >= totalPages}
								>
									<ChevronRight sx={{ height: '39px' }} />
								</IconButton>
							</Stack>
						)}
					</Grid>
				</Grid>
			</Box>
			<ModalProductBuy
				product={selectedProduct as ProductModel}
				open={openBuyModal}
				onAddToCart={(item) => {
					setOpenBuyModel(false);
					return addToCart(item);
				}}
				onClose={() => setOpenBuyModel(false)}
			/>
		</Box>
	);
};

export default ProductList;
