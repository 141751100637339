import { ApiResponse } from 'common/domain/entities/api-response';
import { CategoriesApi } from './categories-api';
import http from './http';
import { CategoryModel, SubCategoryModel } from '../dto/category-backoffice-model';
import axios, { AxiosError } from 'axios';

export default class CategoriesApiImpl implements CategoriesApi {
	apiService = http;

	async getProductCategories(): Promise<ApiResponse<CategoryModel[]>> {
		try {
			const resp = await this.apiService.get('/product-categories');
			const data = resp.data as ApiResponse<CategoryModel[]>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async getProductSubCategories(
		parentCategoryId: number,
	): Promise<ApiResponse<SubCategoryModel[]>> {
		try {
			const resp = await this.apiService.get(`/product-categories/${parentCategoryId}/subCategories`);
			const data = resp.data as ApiResponse<SubCategoryModel[]>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async deleteProductCategory(id: number): Promise<ApiResponse<void>> {
		try {
			const resp = await this.apiService.delete(`/product-categories/${id}`);
			const data = resp.data as ApiResponse<void>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}
}
