/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import {
	Box, IconButton, Stack, useTheme,
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { imageNotLoaded } from 'assets/images';
import { processImg } from 'common/utils/general.utils';

interface Props {
	images: string[]
	videoUrl?: string
}

const ProductImageView: React.FC<Props> = (props) => {
	const { images, videoUrl } = props;
	const [carouselImages, setCarouselImages] = useState(images.filter((item) => !!item));
	const [selectedIndex, setSelectedIndex] = useState(0);
	const theme = useTheme();

	useEffect(() => {
		(async () => {
			const promises = images.map(async (image) => {
				try {
					return await processImg(image);
				} catch (error) {
					return '';
				}
			});
			Promise.all(promises).then((result) => {
				setCarouselImages(result.filter((item) => !!item));
			});
		})();
	}, [images]);

	const handleRightClick = () => {
		setSelectedIndex((prev) => {
			let mPrev = prev;
			if (prev < carouselImages.length - 1) {
				mPrev += 1;
				return mPrev;
			}
			return -1;
		});
	};

	const handleLeftClick = () => {
		setSelectedIndex((prev) => {
			let mPrev = prev;
			if (mPrev === -1) {
				return carouselImages.length - 1;
			}
			if (prev > 0) {
				mPrev -= 1;
				return mPrev;
			}
			if (videoUrl) {
				return -1;
			}
			return carouselImages.length - 1;
		});
	};

	const handleImageClick = (index: number) => {
		setSelectedIndex(index);
	};
	return (
		<Box sx={{
			minHeight: '300px',
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			justifyItems: 'center',
		}}
		>

			<Stack gap={2} mb={{ md: 2, sm: 8, xs: 2 }} direction="row" justifyItems="center" justifyContent="space-between" alignItems="center">
				<IconButton
					sx={{
						bgcolor: theme.palette.secondary.main,
						borderRadius: '0px',
						color: theme.palette.secondary.contrastText,
						zIndex: 99,
					}}
					onClick={handleLeftClick}
				>
					<ChevronLeft />
				</IconButton>

				<Box sx={{ height: { md: 400, xs: 400 }, widows: { md: 400, xs: 400 }, position: 'relative' }} display="flex" alignContent="center">
					{selectedIndex !== -1 && (
						<img
							width="100%"
							src={carouselImages[selectedIndex] || imageNotLoaded}
							style={{ position: 'relative', objectFit: 'contain' }}
							alt="Produto"
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = imageNotLoaded;
							}}
						/>
					)}

					{videoUrl && selectedIndex === -1 && (
						<video
							key="product-images-video"
							autoPlay
							controls
							width="100%"
							onClick={() => handleImageClick(-1)}
							style={{ objectFit: 'contain' }}
						>
							<source src={videoUrl} />
							<track kind="captions" />
						</video>
					)}
				</Box>

				<IconButton
					sx={{
						bgcolor: theme.palette.secondary.main,
						borderRadius: '0px',
						color: theme.palette.secondary.contrastText,
						zIndex: 99,
					}}
					onClick={handleRightClick}
				>
					<ChevronRight />
				</IconButton>
			</Stack>
			<Stack
				direction="row"
				justifyContent="center"
				gap={2}
				sx={{
					overflowX: 'scroll',
					'&::-webkit-scrollbar': { display: 'none' },
					scrollbarWidth: 'none',
					msOverflowStyle: 'none',
				}}
			>
				{carouselImages.map((el, index) => (
					<img
						key={`product-images-${index.toString()}`}
						src={el}
						alt="Product"
						width="64px"
						height="64px"
						onClick={() => handleImageClick(index)}
						style={{ objectFit: 'contain', border: selectedIndex === index ? `3px solid ${theme.palette.secondary.main}` : 'none', cursor: 'pointer' }}
					/>
				))}

				{videoUrl && (
					<div
						style={{ position: 'relative', cursor: 'pointer' }}
						onClick={() => handleImageClick(-1)}
						role="button"
						tabIndex={0}
					>
						<video
							key="product-images-video"
							muted
							src={videoUrl}
							width="64px"
							height="64px"
							style={{ objectFit: 'contain', background: '#CCCCCC7d', border: selectedIndex === -1 ? `3px solid ${theme.palette.secondary.main}` : 'none' }}
						/>
						<i
							className="pi pi-video"
							style={{
								position: 'absolute',
								top: 21,
								left: 21,
								background: 'gray',
								boxShadow: '-1px 1px 6px',
								padding: 6,
								fontSize: '10px',
								borderRadius: 8,
								color: '#ffffff',
							}}
						/>
					</div>
				)}
			</Stack>
		</Box>
	);
};

export default ProductImageView;
