import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

interface ImageProps {
  imageXs?: string;
  imageMd: string;
  alt: string;
  height?: string;
  width?: string;
}

const Image: React.FC<ImageProps> = (props) => {
	const {
		imageMd, imageXs, alt, height, width,
	} = props;
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const [image, setImage] = React.useState(isMd ? imageMd : imageXs);
	React.useEffect(() => {
		if (isMd) {
			setImage(imageMd);
		} else if (imageXs) {
			setImage(imageXs);
		}
	}, [isMd, setImage, imageMd, imageXs]);
	return (
		<img src={image} alt={alt} height={height} width={width} />
	);
};

export default Image;
