import { ApiResponse } from 'common/domain/entities/api-response';
import { CatelogBackofficeModel } from '../dto/catelog-backoffice-model';
import { CatelogApi, FilterParams } from './catelog-api';
import http from './http';
import { serializeFormQuery } from 'common/utils/general.utils';
import axios, { AxiosError } from 'axios';

export class CatelogApiImpl implements CatelogApi {
	apiService = http;

	async getCatelogs(params: FilterParams): Promise<ApiResponse<CatelogBackofficeModel[]>> {
		try {
			const query = serializeFormQuery(params);
			const resp = await this.apiService.get(`/catalogs${query}`);
			const data = resp.data as ApiResponse<CatelogBackofficeModel[]>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}
}
