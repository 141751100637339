/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	MenuItem, Select, SelectChangeEvent, Typography, useTheme,
} from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import { KeyboardArrowDown } from '@mui/icons-material';
import SizedBox from '../sized-box/SizedBox';

interface Props<T>{
	label: string
	value: any
	options: T[]
	error?: boolean,
	renderItem: (item: T) => {optionValue: any, optionLabel: string}
	onChange?: (event: SelectChangeEvent) => void
	onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void
	size?: 'small' | 'medium'
	borderRadius?: string
	fullWidth?: boolean
}

const DefaultDropdown = <T, >(props: Props<T>) => {
	const {
		label, value, options, error, renderItem, onChange, onBlur, size = 'small', borderRadius = '0px', fullWidth = true,
	} = props;
	const theme = useTheme();
	return (
		<>
			<Typography sx={{
				color: theme.palette.textMain.main,
				fontSize: Dimens.FONT_SIZE_SMALL,
			}}
			>
				{label}

			</Typography>
			<SizedBox height={0.7} />
			<Select
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				size={size}
				fullWidth={fullWidth}
				error={error}
				IconComponent={KeyboardArrowDown}
				sx={{
					color: theme.palette.textLight.main,
					borderRadius,
				}}
			>
				{options.map((el, index) => {
					const { optionValue, optionLabel } = renderItem(el);
					return <MenuItem key={`drop-item-${index.toString()}`} value={optionValue}>{optionLabel}</MenuItem>;
				})}
			</Select>
		</>
	);
};

export default DefaultDropdown;
