import { ValidationComposite } from 'common/presentation/validation/validators';
import { ValidationBuilder } from 'common/presentation/validation/validators/builder/validation-builder';

export const makePurchaseValidation = (): ValidationComposite => ValidationComposite.build([
	...ValidationBuilder.field('buyerFullName' as keyof object).required().build(),
	...ValidationBuilder.field('buyerTaxId' as keyof object).nif().build(),

	...ValidationBuilder.field('buyerEmail' as keyof object).required().email().build(),
	...ValidationBuilder.field('buyerPhoneNumber' as keyof object).required().build(),

	...ValidationBuilder.field('cityName' as keyof object).required().build(),
	...ValidationBuilder.field('streetAddress' as keyof object).required().build(),
	...ValidationBuilder.field('zipCode' as keyof object).required().build(),
	...ValidationBuilder.field('districtId' as keyof object).required().build(),
]);
