import React from 'react';
import ProductList from './ProductList';
import { ProductProvider } from '../provider/product.provider';

const Products = () => (
	<main>
		<ProductProvider>
			<ProductList />
		</ProductProvider>
	</main>
);

export default Products;
