/* eslint-disable class-methods-use-this */
import { Either, Left, Right } from '@ajtec/either-typescript';
import { ApiResponse } from '@/common/domain/entities/api-response';
import { ICatelogRepository } from '../../domain/repositories/catelog-repository';
import { CatelogApiImpl } from '../remote/catelog-api-impl';
import { Catelog } from '../../domain/entities/catelog';

export class CatelogRepositoryImpl implements ICatelogRepository {
	homeApi = new CatelogApiImpl();

	async getCatelogs(): Promise<Either<string, ApiResponse<Catelog[]>>> {
		try {
			const products = await this.homeApi.getCatelogs();
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
