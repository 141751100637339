import React, { useEffect, useState } from 'react';
import 'features/purchase/presentation/PurchaseSummary.css';
import {
	Box, Grid, useTheme,
} from '@mui/material';
import useCart from 'common/presentation/providers/cart.provider';
import { BodyText } from 'common/presentation/components';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import { Card } from 'primereact/card';
import { Timeline } from 'primereact/timeline';
import CartItem from 'features/cart/presentation/components/CartItem';
import CartSideItem, { PaymentMethod } from 'features/cart/presentation/components/CartSideItem';
import { ModalBuySuccess } from 'features/products/presentation/modals/ModalBuySuccess';
import useAuth from 'features/authentication/presentation/provider/auth.provider';
import AppLinks from 'assets/applinks.routes';
import { useNavigate } from 'react-router-dom';
import useNotifications from 'common/presentation/providers/notifications.provider';
import { Stripe, StripeElements, loadStripe } from '@stripe/stripe-js';
import { AppEnv } from 'common/constants/app-env';
import { Elements } from '@stripe/react-stripe-js';
import { ModalConfirmOrder } from '../modals/ModalConfirmOrder';
import { getPaymentMethodId } from 'common/utils/general.utils';

interface ItemInfoProps {
	title: string;
	info: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ItemInfo: React.FC<ItemInfoProps> = (props) => {
	const { title, info } = props;
	const theme = useTheme();

	return (
		<Box>
			<BodyText sx={{
				color: theme.palette.textMain.main,
				fontSize: Dimens.FONT_SIZE_SMALL,
				fontWeight: Dimens.FONT_BOLD,
			}}
			>
				{title}
			</BodyText>
			<BodyText sx={{
				fontWeight: Dimens.FONT_NORMAL,
			}}
			>
				{info}
			</BodyText>
		</Box>
	);
};

interface TimelineEvent {
	section?: 'client' | 'contact' | 'shipping address' | 'billing address' | 'items';
	name?: string;
	description: string;
	icon?: string;
	color?: string;
}

type TPurchaseSummaryProps = {
	useShippingAsBillingAddress: boolean,
	clientDetail: {
		buyerFullName: string,
		buyerTaxId: string,
		buyerCompanyName: string,
		comment: string,
		commentCheck: boolean,
	},
	contact: {
		buyerEmail: string,
		buyerPhoneNumber: string,
		buyerSecondaryPhoneNumber?: string,
	},
	shippingAddress: {
		cityName: string,
		streetAddress: string,
		zipCode: string,
		districtId: number,
		districtName: string,
		countryId: number,
		countryName: string,
	},
	billingAddress: {
		cityName: string,
		streetAddress: string,
		zipCode: string,
		districtId: number,
		districtName: string,
		countryId: number,
		countryName: string,
	},
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const stripePromise = loadStripe(AppEnv.STRIPE_KEY, { locale: 'pt' });

export const PurchaseSummary: React.FC<TPurchaseSummaryProps> = ({
	clientDetail,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	contact,
	useShippingAsBillingAddress,
	shippingAddress,
	billingAddress,
}) => {
	const { t } = useTranslation('translations');
	const { toast } = useNotifications();
	const { palette: { secondary } } = useTheme();
	const {
		cartProducts, removeFromCart,
		incrementProduct, decrementCart,
		createOrder, loading,
		createOrUpdatePayment, payment, loadingPayment,
		createMultibancoPayment,
	} = useCart();

	const navigate = useNavigate();

	const { userAuth } = useAuth();
	const [openSuccess, setOpenSuccess] = useState(false);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [stripe, setStripe] = useState<Stripe>();
	const [elements, setElements] = useState<StripeElements>();
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>('CARD');
	const [mbwayNumber, setMbwayNumber] = useState<string>('');

	useEffect(() => {
		createOrUpdatePayment({
			...clientDetail,
			...contact,
			shippingAddress,
			billingAddress: useShippingAsBillingAddress ? shippingAddress : billingAddress,
			paymentMethod: getPaymentMethodId('CARD'),
		});
		setMbwayNumber(contact.buyerPhoneNumber || '');
	}, []);

	useEffect(() => {
		if (payment?.id) {
			createOrUpdatePayment({
				...clientDetail,
				...contact,
				shippingAddress,
				billingAddress: useShippingAsBillingAddress ? shippingAddress : billingAddress,
				paymentSecret: payment?.id,
				paymentMethod: getPaymentMethodId('CARD'),
			});
		}
	}, [cartProducts]);

	const events: TimelineEvent[] = [
		{
			section: 'client', name: t('client_details'), description: 'alguma coisa', icon: 'pi pi-user', color: '#84a8c5',
		},
		{
			section: 'contact', name: t('contact'), description: 'alguma coisa', icon: 'pi pi-phone', color: '#aaad05',
		},
		{
			section: 'shipping address', name: t('shipping_address'), description: 'alguma coisa', icon: 'pi pi-map', color: '#dfa342',
		},
		{
			section: 'billing address', name: t('billing_address'), description: 'alguma coisa', icon: 'pi pi-credit-card', color: secondary.main,
		},
		{
			section: 'items', name: '', description: 'alguma coisa', icon: 'pi pi-shopping-cart', color: secondary.main,
		},
	] as TimelineEvent[];

	const openConfirmModel = (str?: Stripe, el?: StripeElements, method?: PaymentMethod) => {
		setStripe(str);
		setElements(el);
		setPaymentMethod(method || 'CARD');
		setOpenConfirm(true);
	};

	const onCreateOrder = async () => {
		setOpenConfirm(false);

		let multbancoSecret: string | undefined = '';
		if (paymentMethod === 'MULTIBANCO') {
			const pay = await createMultibancoPayment({
				...clientDetail,
				...contact,
				shippingAddress,
				billingAddress: useShippingAsBillingAddress ? shippingAddress : billingAddress,
				paymentMethod: getPaymentMethodId('MULTIBANCO'),
			});
			multbancoSecret = pay.paymentId;
		}

		createOrder({
			...clientDetail,
			...contact,
			shippingAddress,
			billingAddress: useShippingAsBillingAddress ? shippingAddress : billingAddress,
			comments: clientDetail.commentCheck ? clientDetail.comment : undefined,
			paymentSecret: paymentMethod === 'MULTIBANCO' ? multbancoSecret : payment?.id,
			paymentMethod: getPaymentMethodId(paymentMethod),
			paymentPhoneNumber: mbwayNumber,
		}).then(async (result) => {
			if (result.success) {
				if (stripe && elements) {
					if (paymentMethod === 'CARD') {
						const res = await stripe.confirmPayment({
							elements,
							confirmParams: {
								return_url: `${AppEnv.HOST}${AppLinks.STORE_ORDER}/${result.orderId}`,
								receipt_email: contact.buyerEmail,
								payment_method_data: {
									billing_details: {
										name: clientDetail.buyerFullName,
										email: contact.buyerEmail,
										phone: contact.buyerPhoneNumber,
										address: {
											city: billingAddress.cityName,
											postal_code: billingAddress.zipCode,
											state: billingAddress.districtName,
											line1: billingAddress.streetAddress,
										},
									},
								},
							},
							redirect: 'if_required',
						});
						if (res.error) {
							toast.current?.show({
								severity: 'error',
								summary: 'Falha',
								detail: 'Falha ao confirmar o pagamento',
							});
						} else {
							setOpenSuccess(true);
						}
					} else {
						window.location.href = `${AppEnv.HOST}${AppLinks.STORE_ORDER}/${result.orderId}`;
					}
				}
			} else {
				toast.current?.show({
					severity: 'error',
					summary: 'Falha',
					detail: result.message,
				});
			}
		});
	};

	const customizedMarker = (item: TimelineEvent) => (
		<span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style={{ backgroundColor: item.color }}>
			<i className={item.icon} />
		</span>
	);

	const clientDetailContent = () => (
		<Grid container spacing={4}>
			<Grid item>
				<ItemInfo
					title={t('name_and_surname')}
					info={clientDetail.buyerFullName}
				/>
			</Grid>
			<Grid item>
				<ItemInfo
					title={t('tax_id_label')}
					info={clientDetail.buyerTaxId}
				/>
			</Grid>
			<Grid item>
				<ItemInfo
					title={t('company')}
					info={clientDetail.buyerCompanyName}
				/>
			</Grid>
			<Grid item>
				<ItemInfo
					title={t('comment')}
					info={clientDetail.comment}
				/>
			</Grid>
		</Grid>
	);
	const contactContent = () => (
		<Grid container spacing={4}>
			<Grid item>
				<ItemInfo
					title={t('label_email')}
					info={contact.buyerEmail}
				/>
			</Grid>
			<Grid item>
				<ItemInfo
					title={t('cell_phone')}
					info={contact.buyerPhoneNumber}
				/>
			</Grid>
			<Grid item>
				<ItemInfo
					title={t('phone')}
					info={contact.buyerSecondaryPhoneNumber || ''}
				/>
			</Grid>
		</Grid>
	);
	const shippingContent = () => (
		<Box sx={{ whiteSpace: 'pre-line' }}>
			{shippingAddress.streetAddress}
			{', \n'}
			{shippingAddress.cityName}
			{' - '}
			{shippingAddress.zipCode}
			{', \n'}
			{shippingAddress.districtName}
			{', '}
			{shippingAddress.countryName}
		</Box>
	);
	const billingContent = () => (
		<Box sx={{ whiteSpace: 'pre-line' }}>
			{useShippingAsBillingAddress ? shippingAddress.streetAddress : billingAddress.streetAddress}
			{', \n'}
			{useShippingAsBillingAddress ? shippingAddress.cityName : billingAddress.cityName}
			{' - '}
			{useShippingAsBillingAddress ? shippingAddress.zipCode : billingAddress.zipCode}
			{', \n'}
			{useShippingAsBillingAddress ? shippingAddress.districtName : billingAddress.districtName}
			{', '}
			{useShippingAsBillingAddress ? shippingAddress.countryName : billingAddress.countryName}
		</Box>
	);
	const itemsContent = () => (
		<Grid container spacing={2}>
			<Grid item md={8} sm={12} xs={12}>
				{cartProducts.map((el) => (
					<CartItem
						product={el}
						key={el.productId}
						onRemove={removeFromCart}
						onDecrement={() => decrementCart(el)}
						onIncrement={() => incrementProduct(el)}
					/>
				))}
			</Grid>
			<Grid item md={4} sm={12} xs={12}>
				{!loadingPayment && payment?.secret && (
					<Elements
						stripe={stripePromise}
						options={{
							clientSecret: payment?.secret,
						}}
					>
						<CartSideItem
							buttonLabel="confirm"
							loading={loading}
							price={payment?.price?.itemsTotalAmount ?? 0}
							shippingPrice={payment?.deliveryEstimateDays?.price}
							discount={payment?.price?.discount}
							paymentForm
							maxDeliveryDays={payment?.deliveryEstimateDays?.maxDays}
							minDeliveryDays={payment?.deliveryEstimateDays?.minDays}
							handleFinalizeClick={openConfirmModel}
							mbwayPhone={mbwayNumber}
							onMBWayPhoneChange={(mphone) => setMbwayNumber(mphone)}
						/>
					</Elements>
				)}

			</Grid>
		</Grid>
	);

	const customizedContent = (item: TimelineEvent) => (
		<Card title={item.name}>
			{item.section === 'client' && clientDetailContent()}
			{item.section === 'contact' && contactContent()}
			{item.section === 'shipping address' && shippingContent()}
			{item.section === 'billing address' && billingContent()}
			{item.section === 'items' && itemsContent()}
		</Card>
	);

	return (
		<Box mt={8}>
			<div className="card flex flex-wrap gap-6">
				<Timeline value={events} align="left" className="w-full gap-4" marker={customizedMarker} content={customizedContent} />
			</div>
			<ModalBuySuccess
				open={openSuccess}
				authenticated={!!userAuth}
				onOpenChange={(value) => setOpenSuccess(value)}
				onSeeOrders={() => navigate(AppLinks.CLIENT_AREA)}
				onClose={() => {
					navigate(userAuth ? AppLinks.CLIENT_AREA : AppLinks.PRODUCTS);
				}}
			/>
			<ModalConfirmOrder
				open={openConfirm}
				onOpenChange={(value) => setOpenConfirm(value)}
				onConfirmClick={onCreateOrder}
			/>
		</Box>
	);
};
