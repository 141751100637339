/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProductCategoryModel } from 'features/products/data/dto/product-category-model';
import { zilaSlab } from 'assets/theme/theme';
import { searchParentTree } from 'features/products/presentation/components/CategoryMenu';
import { PaymentMethod } from 'features/cart/presentation/components/CartSideItem';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Tiff = require('tiff.js/tiff.min.js');

export const serializeFormQuery = (params: any): string => {
	type ObjectKey = keyof typeof params;
	const paramsString: string[] = [];
	Object.keys(params).forEach((item) => {
		const myKey = item as ObjectKey;
		if (params[myKey] !== undefined) {
			if (Array.isArray(params[myKey])) {
				const arr = params[myKey] as unknown[];
				arr?.forEach((it) => {
					paramsString.push(`${item}=${it}`);
				});
			} else {
				paramsString.push(`${item}=${params[myKey]}`);
			}
		}
	});

	let query = '';
	if (paramsString.length > 0) {
		query = `?${paramsString.join('&')}`;
	}

	return query;
};

export function stringToColor(string: string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

export function stringAvatar(name: string, size: number) {
	const nameArr = name.split(' ');
	let first = '';
	let second = '';
	if (nameArr.length > 1) {
		// eslint-disable-next-line prefer-destructuring
		first = name.split(' ')[0][0];
		// eslint-disable-next-line prefer-destructuring
		second = name.split(' ')[1][0];
	} else if (nameArr.length === 1) {
		// eslint-disable-next-line prefer-destructuring
		first = name.split(' ')[0][0];
	}

	return {
		sx: {
			bgcolor: stringToColor(name),
			width: size,
			height: size,
			fontFamily: zilaSlab,
		},
		children: `${first}${second}`,
	};
}

export const getStockColors = (value: number) => {
	switch (value) {
		case 4:
			return {
				color: '#00A82D',
				bgColor: '#F2FBE4',
			};

		case 3:
			return {
				color: '#F79E1B',
				bgColor: '#FFF1DC',
			};

		case 2:
			return {
				color: '#CC5F5F',
				bgColor: '#FEECEB',
			};

		case 1:
			return {
				color: '#ffffff',
				bgColor: 'red',
			};

		default:
			return {
				color: 'grey',
				bgColor: 'transparent',
			};
	}
};

export const getCustomerTypeColors = (hasAccount: boolean) => {
	switch (hasAccount) {
		case true:
			return {
				bgcolor: '#FFF1DC',
				color: '#F79E1B',
			};
		case false:
			return {
				bgcolor: '#EEF1FF',
				color: '#0D31F0',
			};
		default:
			return {
				bgcolor: '#F1F1F1',
				color: '#374B54',
			};
	}
};

export const getOrderStatusColors = (value: number) => {
	switch (value) {
		case 4: case 5: case 6:
			return {
				color: '#00A82D',
				bgColor: '#F2FBE4',
			};

		case 3: case 7:
			return {
				color: '#CC5F5F',
				bgColor: '#FEECEB',
			};

		case 1: case 2:
			return {
				color: '#F79E1B',
				bgColor: '#FFF1DC',
			};

		default:
			return {
				color: 'grey',
				bgColor: 'transparent',
			};
	}
};

export const getProductStatusColors = (value: boolean) => {
	if (value) {
		return {
			color: '#00A82D',
			bgColor: '#F2FBE4',
		};
	}
	return {
		color: '#CC5F5F',
		bgColor: '#FEECEB',
	};
};

export const getClientTypeColors = (value: number) => {
	switch (value) {
		case 1:
			return {
				color: '#374B54',
				bgColor: '#F1F1F1',
			};
			break;
		case 2:
			return {
				color: '#0D31F0',
				bgColor: '#EEF1FF',
			};
			break;
		default:
			return {
				color: '#00A82D',
				bgColor: '#F2FBE4',
			};
			break;
	}
};

export const createBreadCrumb = (
	tree: ProductCategoryModel[],
	nodeId?: number | undefined,
): ProductCategoryModel[] => {
	let bread: ProductCategoryModel[] = [];
	const result = searchParentTree(tree, nodeId);
	if (result) {
		bread.push(result);
		if (result !== undefined && result.productCategoryId !== nodeId) {
			const selectedChild = createBreadCrumb(result.childrenCategories, nodeId);
			bread = [...bread, ...selectedChild];
		}
	}
	return bread;
};

export const getThumbnailFrom = ({
	videoUrl, onComplete, width = 360, height = 240,
}: {
	videoUrl: string,
	width?: number,
	height?: number,
	onComplete: (dataURI?: string) => void
}) => {
	const src = videoUrl; /// video url not youtube or vimeo,just video on server
	const video = document.createElement('video');

	video.src = src;

	video.width = width;
	video.height = height;

	const canvas = document.createElement('canvas');
	canvas.width = width;
	canvas.height = height;
	const context = canvas.getContext('2d');
	if (context == null) {
		onComplete();
		return;
	}

	video.addEventListener('loadeddata', () => {
		context.drawImage(video, 0, 0, canvas.width, canvas.height);
		const dataURI = canvas.toDataURL('image/jpeg');
		onComplete(dataURI);
	});
};

export const formatCurrency = (value: number | undefined | null) => {
	let val = value?.toFixed(2);
	val = `${val || 0}`;
	if (val.includes('.')) {
		val = val.replace('.', ',');
		val = `${val} €`;
	} else {
		val = val.concat(',00 €');
	}
	val = val.replace(/(?=(\d{3})+(\D))\B/g, '.');
	return val;
};
export const processImg = async (img: string) => new Promise<string>((resolve, reject) => {
	if (img.indexOf('.tif') !== -1) {
		const xhr = new XMLHttpRequest();
		xhr.responseType = 'arraybuffer';
		xhr.open('GET', img, true);
		xhr.onload = function () {
			try {
				Tiff.initialize({
					TOTAL_MEMORY: 100000000,
				});
				const tiff = new Tiff({ buffer: xhr.response });
				resolve(tiff.toDataURL());
			} catch (error) {
				reject(error);
			}
		};

		xhr.onerror = (e) => {
			reject(e);
		};
		xhr.send();
	} else {
		resolve(img);
	}
});

export const getPaymentMethodId = (method: PaymentMethod) => {
	switch (method) {
		case 'CARD':
			return 1;
		case 'MBWAY':
			return 2;
		case 'MULTIBANCO':
			return 3;
		default:
			return -1;
	}
};
