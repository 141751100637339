import React from 'react';
import {
	useTheme, Stack, Box, Link,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { Link as RouterLink } from 'react-router-dom';
import Space from '../space/Space';
import BodyText from '../body-text/BodyText';
import DefaultDivider from '../default-divider/DefaultDivider';

export type MenuItem = {
	title: string,
	url: string,
	submenu?: {title: string; url: string; submenu?: {title: string; url: string;}[]}[]
}

interface ListItemProps {
  item: MenuItem;
  onClick?: (item: MenuItem) => void;
}

const SidebarListItem = (props: ListItemProps) => {
	const { item, onClick } = props;
	const theme = useTheme();
	return (
		<Link to={item.url} component={RouterLink} color="inherit" underline="none">
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				onClick={() => onClick?.call(0, item)}
				sx={{
					height: Dimens.APP_BAR_HEIGHT,
					'&:hover': {
						color: theme.palette.secondary.main,
						cursor: 'pointer',
					},
					'&:hover :nth-child(1)': {
						display: 'block',
					},
				}}
			>
				<Box sx={{
					display: 'none',
					width: Dimens.SELECTED_BAR_WIDTH,
					height: '100%',
					bgcolor: theme.palette.secondary.main,
				}}
				/>
				<Space width="60px" />
				<BodyText
					sx={{ fontWeight: Dimens.FONT_BOLD, color: theme.palette.textMain.main }}
				>
					{item.title.toLocaleUpperCase()}
				</BodyText>
				<Box />
			</Stack>
			<DefaultDivider width="100%" />
		</Link>
	);
};

export default SidebarListItem;
