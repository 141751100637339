/* eslint-disable no-useless-constructor */
import { InvalidNifError } from '../../errors/invalid-nif-error';
import { FieldValidation } from '../../protocols/field-validation';

export class NIFValidation implements FieldValidation {
	constructor(readonly field: keyof object) { }

	validate(input: object): Error | null {
		const value: string = input[this.field];

		if (!value) return null; // avoid validate empty field case the filed is not required

		if (!value || typeof value !== 'string' || value.length !== 9) return new InvalidNifError();

		let sumAux = 0;
		for (let i = 9; i >= 2; i -= 1) {
			sumAux += i * (parseInt(value[value.length - i], 10) || 0);
		}

		const module = sumAux % 11;

		// Get the eight first numbers
		const NIFwithoutLastDigit = value.slice(0, value.length - 1);

		if (module === 0 || module === 1) {
			return `${NIFwithoutLastDigit}0` === value ? null : new InvalidNifError();
		}
		return `${NIFwithoutLastDigit}${11 - module}` === value ? null : new InvalidNifError();
	}
}
