import { inter } from 'assets/theme/theme';
import { Typography, TypographyProps, useTheme } from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';

interface Props extends TypographyProps {
	maxLines?: number
}

const BodyText: React.FC<Props> = (props) => {
	const {
		children, sx, maxLines = 100, ...remainingProps
	} = props;
	const theme = useTheme();
	return (
		<Typography
			{...remainingProps}
			sx={{
				fontSize: { md: Dimens.FONT_SIZE_BODY, xs: Dimens.FONT_SIZE_BODY_MOBILE },
				fontWeight: Dimens.FONT_THIN,
				color: theme.palette.textLight.main,
				overflow: 'hidden',
				display: '-webkit-box',
				fontFamily: inter,
				WebkitBoxOrient: 'vertical',
				WebkitLineClamp: maxLines,
				whiteSpace: 'pre-wrap',
				...sx,
			}}
		>
			{children}
		</Typography>
	);
};

export default BodyText;
