import React, {
	createContext, useContext, useMemo, useState,
} from 'react';
import { ProductCategoryModel } from '../../data/dto/product-category-model';
import { GetCategories } from '../../domain/usecases/get-categories';
import { ProductRepositoryImpl } from '../../data/repository/product-repository-impl';

type ContextProps = {
    search: string | undefined;
    setSearch: (query: string | undefined) => void;

	categoriesLoading: boolean;
	categories: ProductCategoryModel[];
	categoriesError:string;
	getCategories: () => Promise<void>;
}

const SearchContext = createContext({} as ContextProps);

export const SearchProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [search, setSearch] = useState<string | undefined>();

	const [categoriesLoading, setCategoriesLoading] = useState(false);
	const [categories, setCategories] = useState([] as ProductCategoryModel[]);
	const [categoriesError, setCategoriesError] = useState('');

	const productRepository = new ProductRepositoryImpl();

	const getCategories = async () => {
		setCategoriesLoading(true);
		const getCategoriesUseCase = new GetCategories(productRepository);
		const categoriesResult = await getCategoriesUseCase.call();
		if (categoriesResult.isRight()) {
			setCategoriesError('');
			setCategories(categoriesResult.value);
		} else {
			setCategoriesError(categoriesResult.error);
		}
		setCategoriesLoading(false);
	};

	const value = useMemo(() => ({
		search,
		setSearch,

		categoriesLoading,
		categoriesError,
		categories,
		getCategories,
	}), [
		search,

		categoriesLoading,
		categoriesError,
		categories,
	]);

	return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export default function useSearch() {
	return useContext(SearchContext);
}
