import { ApiResponse } from '@/common/domain/entities/api-response';
import { OrderReadModel } from '../dto/order-read-model';
import { OrderApi } from './order-api';
import storeHttp from 'common/data/remote/store-http';
import { OrderCreateModel, OrderCreateNoAuthModel } from '../dto/order-create-model';
import { httpPost, httpGet } from 'common/utils/http.utils';
import axios, { AxiosError } from 'axios';
import { PaymentModel } from '../dto/payment-model';

export class OrderApiImpl implements OrderApi {
	apiService = storeHttp;

	async getOrders(): Promise<ApiResponse<OrderReadModel[]>> {
		return httpGet('/orders', this.apiService);
	}

	async getOrder(orderId: number): Promise<ApiResponse<OrderReadModel>> {
		return httpGet(`/orders/${orderId}`, this.apiService);
	}

	async createOrderNoAuth(order: OrderCreateNoAuthModel): Promise<ApiResponse<PaymentModel>> {
		return httpPost('/orders/noauth', order, this.apiService);
	}

	async createOrder(order: OrderCreateModel): Promise<ApiResponse<PaymentModel>> {
		return httpPost('/orders', order, this.apiService);
	}

	async getOrderProof(orderId: number): Promise<ApiResponse<File>> {
		try {
			const resp = await this.apiService.get(`/orders/${orderId}/proof`, {
				responseType: 'blob',
			});

			const blob = resp.data as Blob;

			return {
				message: 'success',
				data: new File([blob], `volt_${orderId}-proof-of-purchase.pdf`),
			} as ApiResponse<File>;
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = JSON.parse(await err.response?.data.text()) as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async createOrUpdatePayment(
		order: OrderCreateNoAuthModel,
	): Promise<ApiResponse<PaymentModel>> {
		return httpPost('/payment', order, this.apiService);
	}

	async createMultibancoPayment(
		order: OrderCreateNoAuthModel,
	): Promise<ApiResponse<PaymentModel>> {
		return httpPost('/payment/multibanco', order, this.apiService);
	}
}
