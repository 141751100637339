import Dimens from 'assets/dimens';
import { Box } from '@mui/material';
import React from 'react';

export const DeliveryTerms = () => (
	<Box justifyContent="center" display="flex" sx={{ padding: '16px', mb: 16 }}>
		<Box maxWidth={Dimens.PAGE_MAX_WIDTH} flex={1}>
			<h2>
				Entrega
			</h2>

			<section id="content" className="page-content page-cms page-cms-1 col-xs-12 col-sm-12">
				<h3><span style={{ color: '#b9c92b' }}>EXPEDIÇÃO DE ENCOMENDAS E PORTES</span></h3>
				<p style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}>

					As encomendas só são
					processadas em dias úteis até às 16 horas. Após a confirmação do pagamento,
					as encomendas são enviadas para a morada indicada pelo cliente. As
					pré-encomendas, ao contrário das encomendas normais e atrás mencionadas, só
					serão processadas quando o produto estiver disponível por parte da marca,
					para distribuição oficial e para cliente final.&nbsp;Os custos de envio das
					encomendas ficam a cargo do cliente e acrescem ao total do valor dos
					produtos selecionados. O cliente será informado destes encargos antes de
					confirmar o seu pedido e concluir o processo de compra, assim como da
					diferença de valor dependendo do meio de expedição escolhido.
				</p>
				<table width="60%">
					<tbody>
						<tr>
							<td
								width="25%"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
								<strong>Destino</strong>

							</td>
							<td
								width="25%"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
								<strong>Prazo de Entrega</strong>

							</td>
							<td
								width="10%"
								align="center"
								valign="middle"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
								<strong>Peso (Kg)</strong>

							</td>
							<td
								width="10%"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
								<strong>Valor</strong>

							</td>
						</tr>
						<tr>
							<td
								width="25%"
								rowSpan={4}
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
Portugal
								Continental*

							</td>
							<td
								width="25%"
								rowSpan={4}
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
Até 2 dias
								úteis

							</td>
							<td
								width="10%"
								align="center"
								valign="middle"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
5

							</td>
							<td>6.93 €</td>
						</tr>
						<tr>
							<td
								width="10%"
								align="center"
								valign="middle"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
10

							</td>
							<td
								width="10%"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
7.56 €
							</td>
						</tr>
						<tr>
							<td
								width="10%"
								align="center"
								valign="middle"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
15

							</td>
							<td
								width="10%"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
9.35 €
							</td>
						</tr>
						<tr>
							<td
								width="10%"
								align="center"
								valign="middle"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
20

							</td>
							<td
								width="10%"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
10.71 €
							</td>
						</tr>
						<tr>
							<td
								width="25%"
								rowSpan={3}
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
Portugal -
								Ilhas

							</td>
							<td
								width="25%"
								rowSpan={3}
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
Até 5 dias
								úties

							</td>
							<td
								width="10%"
								align="center"
								valign="middle"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
2

							</td>
							<td
								width="10%"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
10.96 €
							</td>
						</tr>
						<tr>
							<td
								width="10%"
								align="center"
								valign="middle"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
5

							</td>
							<td
								width="10%"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
15.37 €
							</td>
						</tr>
						<tr>
							<td
								width="10%"
								align="center"
								valign="middle"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
10

							</td>
							<td
								width="10%"
								style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}
							>
27.09 €
							</td>
						</tr>
					</tbody>
				</table>
				<p style={{ fontSize: '14px', fontWeight: '400', color: '#585858' }}>
Os prazos de
					entrega poderão sofrer alterações devido a ruturas de stock, épocas festivas
					ou outras condicionantes externas à VOLT, tais como atrasos por parte dos
					serviços de entregas, na medida em que são uma empresa externa à
					VOLT.&nbsp;&nbsp;* Para todas as compras, expedidas para Portugal
					Continental, acima de 300€ os custos de envio são gratuitos.
				</p>

			</section>
		</Box>
	</Box>
);
