import { Box, useTheme } from '@mui/material';
import React from 'react';

interface Props {
	height?: number;
}

const SizedBox: React.FC<Props> = (props) => {
	const theme = useTheme();
	const { height = 1 } = props;
	return (
		<Box sx={{
			pt: theme.spacing(height / 2),
			pb: theme.spacing(height / 2),
			display: 'flex',
			flexDirection: 'column',
		}}
		/>
	);
};

export default SizedBox;
