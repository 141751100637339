import { heroLanding } from 'assets/images';
import {
	Box,
	Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dimens from 'assets/dimens';
import { BodyText, DefaultButton, TitleMedium } from 'common/presentation/components';
import TextField from 'common/presentation/components/text-field/TextField';
import { AtField, Key } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import useAuth from '../provider/auth.provider';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import { makeLoginValidation } from '../builder/authentication-factory';
import LocalStorageWrapper, { LocalStorageKeys } from 'common/utils/storage-utils';

const LoginPage = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const navigate = useNavigate();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const { login, loading, error } = useAuth();
	const [{ email, password }, setCredentials] = useState({ email: '', password: '' });
	const [{ emailError, passwordError }, setErrors] = useState({ emailError: '', passwordError: '' });
	const [searchParams] = useSearchParams();

	const validation = makeLoginValidation();

	useEffect(() => {
		const key = LocalStorageWrapper.get<string>(LocalStorageKeys.REFRESH_STORE_TOKEN);
		const user = LocalStorageWrapper.get<string>(LocalStorageKeys.USER_STORE);
		if (key && user) {
			navigate(AppLinks.CLIENT_AREA);
		}
	}, []);

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setCredentials((prev) => ({ ...prev, [event.target.name]: event.target.value }));
	};

	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const formData = { email, password };

		const emailErrorV = validation.validate('email', formData);
		const passwordErrorV = validation.validate('password', formData);

		const isFormValid = !(!!emailErrorV || !!passwordErrorV);

		setErrors({ emailError: emailErrorV, passwordError: passwordErrorV });

		if (isFormValid) {
			login(email, password).then((result) => {
				if (result) {
					if (searchParams.get('purchase')) {
						navigate(AppLinks.PURCHASE);
						return;
					}
					navigate(AppLinks.CLIENT_AREA);
				}
			});
		}

		return false;
	};

	return (
		<Box sx={{
			backgroundImage: isMd ? `url(${heroLanding})` : 'none',
			backgroundPosition: 'left top',
			backgroundRepeat: 'no-repeat',
			backgroundSize: '50% 100%',
			mt: Dimens.INTRO_HERO_IMAGE_TOP,
		}}
		>
			<Stack
				alignItems="flex-end"
				sx={{
					width: { md: '100%', xs: '100%' },
					pl: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },
					pr: Dimens.PAGE_PADDING_SMALL_MOBILE,
					pt: { md: Dimens.INTRO_HERO_IMAGE_TOP_POSITIVE, xs: Dimens.SECTION_SPACING },
				}}
			>
				<Box sx={{ width: { md: '50%', xs: '100%' }, height: '85vh' }}>
					<Stack justifyContent="center" sx={{ height: '100%' }}>
						<TitleMedium>
							{t('welcome_to_volt')}
						</TitleMedium>
						<BodyText sx={{ mt: 2 }}>
							{t('welcome_info')}
						</BodyText>
						<Stack mt={5}>
							<Typography color="red">
								{error}
							</Typography>
						</Stack>
						<form onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item md={12} xs={12}>
									<TextField
										name="email"
										placeholder="Email"
										error={!!emailError}
										helperText={emailError}
										onChange={handleChange}
										fullWidth
										startIcon={<AtField />}
									/>
								</Grid>
								<Grid item md={12} xs={12}>
									<TextField
										name="password"
										type="password"
										error={!!passwordError}
										helperText={passwordError}
										placeholder={t('senha')}
										fullWidth
										onChange={handleChange}
										startIcon={<Key />}
									/>
									<Stack justifyContent="space-between" alignItems="center" direction="row">
										<FormControlLabel
											control={<Checkbox defaultChecked size="small" />}
											label={(
												<BodyText sx={{}}>
													{t('keep_session')}
												</BodyText>
											)}
										/>
										{/* <BodyText sx={{}}>
											{t('forgot_password')}
										</BodyText> */}
									</Stack>
								</Grid>
								<Grid item md={7} xs={12} mt={2}>
									<Stack>
										<DefaultButton type="submit" disabled={loading} sx={{ alignSelf: 'center' }}>
											{loading && <CircularProgress size={30} />}
											{!loading && t('enter')}
										</DefaultButton>
									</Stack>
								</Grid>
								<Grid item md={12}>
									<BodyText sx={{ mt: 2 }}>
										{t('dont_have_an_account')}
										{' '}
										<Link style={{ textDecoration: 'none', color: '#B9C92B' }} to={AppLinks.REGISTER}>{t('create_account')}</Link>
									</BodyText>
								</Grid>
							</Grid>
						</form>
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

export default LoginPage;
