import { Either } from '@ajtec/either-typescript';
import { IProductRepository } from '../repository/product-repository';
import { ProductCategoryModel } from '../../data/dto/product-category-model';

export class GetCategories {
	repository: IProductRepository;

	constructor(productRepository: IProductRepository) {
		this.repository = productRepository;
	}

	call(): Promise<Either<string, ProductCategoryModel[]>> {
		return this.repository.getCategories();
	}
}
