import { Either } from '@ajtec/either-typescript';
import { IProductRepository } from '../repository/product-repository';
import { Brand } from '../entities/Brand';

export class GetBrands {
	repository: IProductRepository;

	constructor(productRepository: IProductRepository) {
		this.repository = productRepository;
	}

	call(): Promise<Either<string, Brand[]>> {
		return this.repository.getBrands();
	}
}
