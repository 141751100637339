import React from 'react';
import {
	Stack, Button, useTheme, Typography,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import Dimens from 'assets/dimens';

interface Props {
	count?: number
	onIncrement?: (prevCount: number) => void
	onDecrement?: (newCount: number) => void
}

const CounterButton: React.FC<Props> = (props) => {
	const { count = 0, onIncrement, onDecrement } = props;
	const theme = useTheme();

	const handleIncrement = () => {
		onIncrement?.call(0, count);
	};
	const handleDecrement = () => {
		onDecrement?.call(0, count);
	};

	return (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{
				border: `1px solid ${theme.palette.divider}`,
				p: '3px',
			}}
			width={Dimens.COUNTER_BUTTON_WIDTH}
		>
			<Button
				color="secondary"
				size="small"
				variant="contained"
				disableElevation
				onClick={handleDecrement}
				sx={{
					pt: Dimens.BUTTON_PADDING_TOP_BOTTOM,
					pb: Dimens.BUTTON_PADDING_TOP_BOTTOM,
					m: 0,
					borderRadius: Dimens.DEFAULT_RADIUS,
					minWidth: '40px',
				}}
			>
				<Remove sx={{ fontSize: Dimens.FONT_SIZE_SMALL }} />
			</Button>
			<Typography fontSize={Dimens.FONT_SIZE_MEDIUM}>{count}</Typography>
			<Button
				color="secondary"
				variant="contained"
				disableElevation
				onClick={handleIncrement}
				sx={{
					pt: Dimens.BUTTON_PADDING_TOP_BOTTOM,
					pb: Dimens.BUTTON_PADDING_TOP_BOTTOM,
					m: 0,
					borderRadius: Dimens.DEFAULT_RADIUS,
					minWidth: '40px',
				}}
			>
				<Add sx={{ fontSize: Dimens.FONT_SIZE_SMALL }} />
			</Button>
		</Stack>
	);
};

export default CounterButton;
