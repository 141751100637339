import { inter } from 'assets/theme/theme';
import { InputLabel, TextField, TextFieldProps } from '@mui/material';
import React, { FC } from 'react';
import Dimens from 'assets/dimens';

const TextInput: FC<TextFieldProps> = (props) => {
	const { label, sx, ...restProps } = props;
	return (
		<div>
			<InputLabel sx={{ fontFamily: inter, fontSize: { md: Dimens.FONT_SIZE_SMALL_TITLE, xs: Dimens.FONT_SIZE_SMALL_TITLE_MOBILE } }} shrink htmlFor="bootstrap-input">
				{label}
			</InputLabel>
			<TextField
				fullWidth
				size="medium"
				sx={{
					fontFamily: inter,
					...sx,
				}}
				{...restProps}
			/>
		</div>
	);
};

export default TextInput;
