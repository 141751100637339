enum AppLinks {
	LANDING = '/',
	DELIVERY_TERMS = '/delivery-term',
	GUARANTEES_AND_RETURNS = '/guarantees-and-returns',
	TERMS_AND_CONDITIONS_OF_USE = '/terms-and-conditions-of-use',
	CONTACT_US = '/contact',
	PRODUCT_CATELOG = '/product-catelog',
	LOGIN = '/login',
	REGISTER = '/register',
	CLIENT_AREA = '/client-area',
	PRODUCT_DETAIL = '/products/detail',
	PRODUCTS = '/products',
	CART = '/cart',
	PURCHASE = '/purchase',
	STORE_ORDER = '/store/order',
	LOGIN_BACKOFFICE = '/backoffice/login',
	DASHBOARD = '/backoffice',
	ORDERS = '/backoffice/orders',
	ORDER_DETAIL = '/backoffice/order-detail/:id',
	CUSTOMERS = '/backoffice/customers',
	INVENTORY = '/backoffice/inventory',
	PRODUCT_FORM = '/backoffice/inventory/form',
	CATALOG = '/backoffice/catalog',
	SETTIGNS = '/backoffice/settings'
}

export default AppLinks;
