/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

import { ICartProduct } from 'common/domain/entities/cart-product';
import { CartActionKind, ICartAction } from '../../../domain/entities/cart-action';
import { ICartState } from '../../../domain/entities/cart-state';
import LocalStorageWrapper, { LocalStorageKeys } from 'common/utils/storage-utils';

const updateLocalItems = (items: ICartProduct[]) => {
	LocalStorageWrapper.set(LocalStorageKeys.CART, items);
};

export const cartReducer = (state: ICartState, action: ICartAction) => {
	const { type, payload } = action;
	const productIndex = state.cartProducts.findIndex(
		(product) => product.productId === payload.productId,
	);
	switch (type) {
	case CartActionKind.ADD_TO_CART: {
		const items = [...state.cartProducts, { ...payload }];
		updateLocalItems(items);
		return { ...state, cartProducts: items };
	}
	case CartActionKind.REMOVE_FROM_CART:
		if (productIndex !== -1) {
			state.cartProducts.splice(productIndex, 1);
		}
		updateLocalItems(state.cartProducts);
		return {
			...state,
			cartProducts: [...state.cartProducts],
		};

	case CartActionKind.INCREMENT:
		if (productIndex !== -1) {
			state.cartProducts[productIndex].quantity += 1;
		}
		updateLocalItems(state.cartProducts);
		return {
			...state,
			cartProducts: [...state.cartProducts],
		};

	case CartActionKind.DECREMENT:
		if (productIndex !== -1 && state.cartProducts[productIndex].quantity > 1) {
			state.cartProducts[productIndex].quantity -= 1;
		}
		updateLocalItems(state.cartProducts);
		return {
			...state,
			cartProducts: [...state.cartProducts],
		};
	case CartActionKind.CLEAR:
		updateLocalItems([]);
		return {
			...state,
			cartProducts: [],
		};

	default:
		return state;
	}
};
