/* eslint-disable no-nested-ternary */
import {
	Box,
	StepIconProps,
	useTheme, styled, Stepper, StepConnector, Step, StepLabel,
} from '@mui/material';
import React from 'react';

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
	({ theme, ownerState }) => ({
		color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
		display: 'flex',
		height: 22,
		alignItems: 'center',
		...(ownerState.active && {
		}),
		'& .QontoStepIcon-completedIcon': {
			zIndex: 1,
			fontSize: 18,
		},
		'& .QontoStepIcon-circle': {
			width: 8,
			height: 8,
			borderRadius: '50%',
			backgroundColor: 'currentColor',
		},
	}),
);

const CustomStepIcon = (props: StepIconProps) => {
	const { active, completed, className } = props;
	const theme = useTheme();
	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{active ? (
				<Box sx={{ p: 1, border: `1px solid ${theme.palette.secondary.main}` }}>
					<Box sx={{
						width: '30px',
						height: '30px',
						bgcolor: theme.palette.secondary.main,
					}}
					/>
				</Box>
			) : completed ? (
				<Box sx={{ p: 1, border: `1px solid ${theme.palette.textMain.main}` }}>
					<Box sx={{
						width: '30px',
						height: '30px',
						bgcolor: theme.palette.textMain.main,
					}}
					/>
				</Box>
			) : (
				<Box sx={{ p: 1, border: `1px solid ${theme.palette.primary.main}` }}>
					<Box sx={{
						width: '30px',
						height: '30px',
						bgcolor: theme.palette.primary.main,
					}}
					/>
				</Box>
			)}
		</QontoStepIconRoot>
	);
};

interface StepperProps {
	steps: number[];
	activeStep: number
}

const CustomStepper: React.FC<StepperProps> = (props) => {
	const { steps, activeStep } = props;
	return (
		<Stepper activeStep={activeStep} alternativeLabel connector={<StepConnector />}>
			{steps.map((el) => (
				<Step>
					<StepLabel StepIconComponent={CustomStepIcon}>{el}</StepLabel>
				</Step>
			))}
		</Stepper>
	);
};

export default CustomStepper;
