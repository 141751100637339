import React from 'react';
import NotFoundSection from './NotFoundSection';

const NotFound = () => (
	<main>
		<NotFoundSection />
	</main>
);

export default NotFound;
