import React, { useEffect } from 'react';
import {
	Box, useTheme, FormGroup,
	Skeleton,
} from '@mui/material';
import { BodyText, DefaultButton, DefaultDivider } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import { zilaSlab } from 'assets/theme/theme';
import useProduct from '../provider/product.provider';
import { FilterParams } from '../../data/remote/product-api';
import { BrandComponent } from './BrandComponent';

interface Props {
	value: FilterParams,
	onApplyFilter: () => void;
	onClearFilter: () => void;
	onChange: (params: FilterParams) => void;
}

const FilterSideMenu: React.FC<Props> = ({
	onApplyFilter,
	onClearFilter,
	value,
	onChange,
}: Props) => {
	const theme = useTheme();
	const { brands, getBrands, brandsLoading } = useProduct();

	useEffect(() => {
		getBrands();
	}, []);

	// const valueFormat = (amount: number) => `${amount} €`;

	const brandsIds = value.brandsIds || [];

	return (
		<Box sx={{
			border: '1px solid #E9E9E9', pt: '28px', pl: '18px', pr: '18px', pb: '40px',
		}}
		>
			<BodyText sx={{
				fontSize: Dimens.FONT_SIZE_SMALL_TITLE,
				fontWeight: Dimens.FONT_BOLDER,
				color: theme.palette.primary.contrastText,
				fontFamily: zilaSlab,
			}}
			>
				Filtros
			</BodyText>
			<DefaultDivider sx={{ mt: 2, mb: 2 }} width="100%" />
			<BodyText sx={{
				fontSize: Dimens.FONT_SIZE_SMALL,
				fontWeight: Dimens.FONT_BOLD,
				color: theme.palette.primary.contrastText,
			}}
			>
				Marcas
			</BodyText>
			<FormGroup>
				{brandsLoading && [...Array(10).keys()].map((item) => (
					<Skeleton key={`skeletons-brands-${item}`} variant="text" sx={{ fontSize: '2rem' }} />
				))}

				{!brandsLoading && (
					<BrandComponent
						brands={brands}
						brandSelected={brandsIds}
						materialSelected={[]}
						colorSelected={value.colorsIds || []}
						onBrandSelected={(ids) => onChange({ ...value, brandsIds: ids })}
						onColorSelected={(ids) => onChange({ ...value, colorsIds: ids })}
					/>
				)}
			</FormGroup>
			{/* <BodyText sx={{
				fontSize: Dimens.FONT_SIZE_SMALL,
				fontWeight: Dimens.FONT_BOLD,
				color: theme.palette.primary.contrastText,
				mt: 4,
			}}
			>
				ITENS MÍNIMOS DE ENCOMENDA
			</BodyText>
			<Slider
				sx={{ mt: 5 }}
				aria-label="Custom marks"
				defaultValue={20}
				step={10}
				color="secondary"
				valueLabelDisplay="auto"
				marks={[
					{
						value: 0,
						label: '10',
					},
					{
						value: 100,
						label: '200',
					},
				]}
			/>
			<BodyText sx={{
				fontSize: Dimens.FONT_SIZE_SMALL,
				fontWeight: Dimens.FONT_BOLD,
				color: theme.palette.primary.contrastText,
			}}
			>
				INTERVALO DE PRECOS
			</BodyText>
			<Slider
				sx={{ mt: 5 }}
				aria-label="Custom marks"
				defaultValue={500}
				step={1}
				color="secondary"
				valueLabelDisplay="auto"
				max={3000}
				getAriaValueText={valueFormat}
				valueLabelFormat={valueFormat}
				marks={[
					{
						value: 0,
						label: '0 € ',
					},
					{
						value: 3000,
						label: '3000 €',
					},
				]}
			/> */}
			<DefaultButton fullWidth sx={{ mt: 3 }} onClick={() => onApplyFilter()}>
				APLICAR FILTROS
			</DefaultButton>
			<DefaultButton variant="outlined" fullWidth sx={{ mt: 3 }} onClick={() => onClearFilter()}>
				ELIMINAR TODOS OS FILTROS
			</DefaultButton>
		</Box>
	);
};

export default FilterSideMenu;
