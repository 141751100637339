const Dimens = {
	DEFAULT_PADDING: '10px',
	DEFAULT_RADIUS: '0px',
	BUTTON_PADDING_TOP_BOTTOM: '10px',
	BUTTON_PADDING_RIGHT_LEFT: '8px',
	FOOTER_NEGATIVE_PADDING: '-25px',
	FOOTER_SECTION_PADDING_MOBILE: '50px',
	SECTION_PADDING_BOTTOM: '100px',
	PAGE_PADDING_SMALL: '170px',
	PAGE_PADDING_SMALL_MOBILE: '20px',
	AVATAR_BORDER_RADIUS: '10px',

	PAGE_MAX_WIDTH: '1400px',
	PAGE_HORIZONTAL_PADDING: {
		pl: '24px',
		pr: '24px',
	},
	PAGE_HORIZONTAL_PADDING_SIZE: '24px',

	PAGE_PADDING_MEDIUM: '275px',
	PAGE_PADDING_MEDIUM_MOBILE: '67px',
	SELECTED_BAR_WIDTH: '3px',

	SECTION_SPACING: '130px',
	SECTION_SPACING_MOBILE: '60px',

	// Appbar
	APPBAR_MARGIN_RIGHT: '200px',
	APP_PADDING_HORIZANTAL: '300px',
	APP_BAR_PADDING_HORIZANTAL_MOBILE: '30px',
	APP_BAR_HEIGHT: '95px',
	CARD_PADDING_HORIZANTAL: '20px',
	CARD_PADDING_VERTICAL: '15px',

	// Footer
	FOOTER_VERTICAL_PADDING: '64px',
	FOOTER_VERTICAL_PADDING_MOBILE: '27px',
	FOOTER_HORIZANTAL_PADDING: '170px',
	FOOTER_HORIZANTAL_PADDING_MOBILE: '20px',
	FOOTER_LIST_ITEM_WIDTH: '326px',
	// Widths
	COUNTER_BUTTON_WIDTH: '200px',
	BORDER_WIDTH_DIVIDER: '2px',
	BORDER_WIDTH_DIVIDER_SMALL: '1px',
	TEXT_PADDING: '4px',

	// FONT SIZES
	FONT_SIZE_EXTRA_LARGE: '47px',
	FONT_SIZE_EXTRA_LARGE_MOBILE: '24px',
	FONT_SIZE_LARGE: '40px',
	FONT_SIZE_LARGE_SMALL: '32px',
	FONT_SIZE_MEDIUM: '22px',
	FONT_SIZE_SMALL_TITLE: '18px',
	FONT_SIZE_SMALL_TITLE_MOBILE: '15px',
	FONT_SIZE_BODY: '16px',
	FONT_SIZE_BODY_MOBILE: '13px',
	FONT_SIZE_SMALL: '14px',
	FONT_SIZE_EXTRA_SMALL: '12px',

	// FONT WEIGHT
	FONT_THIN: '400',
	FONT_NORMAL: '500',
	FONT_BOLD: '600',
	FONT_BOLDER: '700',

	// Text Containers
	TEXT_BOX_WIDTH_SMALL: '556px',

	// SIZES
	NOT_FOUND_IMAGE_WIDTH: '750px',
	NOT_FOUND_IMAGE_WIDTH_MOBILE: '285px',
	DRAWER_WIDTH: '386px',

	// Company Intro Section
	INTRO_SECTION_FONT_PADDING_RIGHT: '160px',
	INTRO_HERO_IMAGE_TOP: '-180px',
	INTRO_HERO_IMAGE_TOP_POSITIVE: '180px',
	CARD_BORDER_RADIUS: '15.5px',

	ICON_BOX_DIEMENSIONS: '48px',

};

export default Dimens;
