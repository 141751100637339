import React from 'react';
import {
	Box,
	Stack,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import Dimens from 'assets/dimens';
import {
	BodyText, SizedBox, TitleLarge,
} from 'common/presentation/components';
import { useTranslation } from 'react-i18next';
import {
	heroLanding,
	heroLandingBgMobile,
} from 'assets/images';

const LandingHero = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const [bgImage, setBgImage] = React.useState(heroLanding);
	const isMd = useMediaQuery(theme.breakpoints.down('md'));

	React.useEffect(() => {
		if (isMd) {
			setBgImage(heroLandingBgMobile);
		} else {
			setBgImage(heroLanding);
		}
	}, [isMd]);
	return (
		<section>
			<Box sx={{
				backgroundImage: `url(${bgImage})`,
				backgroundPosition: 'right top',
				backgroundRepeat: 'no-repeat',
				backgroundSize: {
					md: '45% 100%',
					xs: '39% 100%',
				},
				mt: -5,
			}}
			>
				<Box sx={{ width: { md: '55%', xs: '75%' }, pl: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE }, pt: { md: Dimens.INTRO_HERO_IMAGE_TOP_POSITIVE, xs: Dimens.SECTION_SPACING } }}>
					<Stack justifyContent={{ md: 'center', xs: 'flex-start' }} sx={{ height: { md: '60vh', xs: '55vh' } }}>
						<BodyText
							sx={{
								fontSize: Dimens.FONT_SIZE_SMALL,
								color: theme.palette.secondary.main,
								fontWeight: Dimens.FONT_BOLDER,
								maxWidth: { xs: '75%', md: '100%' },
							}}
							whiteSpace="pre-line"
						>
							{t('complete_commercial_residential').toUpperCase()}
						</BodyText>
						<SizedBox height={1.5} />
						<TitleLarge sx={{ whiteSpace: 'pre-line' }}>
							{t('electrical_and_industrial')}
						</TitleLarge>
						<SizedBox height={5} />
						<BodyText sx={{ whiteSpace: 'pre-line' }}>
							{t('with_a_broad_portfolio')}
						</BodyText>
					</Stack>
				</Box>

			</Box>
		</section>
	);
};
export default LandingHero;
