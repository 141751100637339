import Dimens from 'assets/dimens';
import {
	Card,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import { PieChartOutline } from '@mui/icons-material';
import { inter } from 'assets/theme/theme';
import { SizedBox } from 'common/presentation/components';
import { Amount } from 'features/backoffice/data/dto/order-backoffice-model';
import { formatCurrency } from 'common/utils/general.utils';

interface Props {
	amounts?: Amount
}

const PriceCard: FC<Props> = (props) => {
	const { amounts } = props;
	const theme = useTheme();
	return (
		<Card
			elevation={0}
			sx={{
				bgcolor: theme.palette.background.default,
				pt: Dimens.CARD_PADDING_VERTICAL,
				pb: Dimens.CARD_PADDING_VERTICAL,
				pl: Dimens.CARD_PADDING_HORIZANTAL,
				pr: Dimens.CARD_PADDING_HORIZANTAL,
			}}
		>
			<Stack
				alignItems="center"
				justifyContent="center"
				component="span"
				sx={{
					height: Dimens.ICON_BOX_DIEMENSIONS,
					width: Dimens.ICON_BOX_DIEMENSIONS,
					borderRadius: Dimens.AVATAR_BORDER_RADIUS,
					bgcolor: theme.palette.secondary.main,
					color: theme.palette.secondary.contrastText,
				}}
			>
				<PieChartOutline />
			</Stack>
			<SizedBox height={3} />
			<Typography
				fontFamily={inter}
				fontSize={Dimens.FONT_SIZE_SMALL_TITLE}
				color={theme.palette.textLight.main}
			>
				Valor de encomenda
			</Typography>
			<Typography
				fontFamily={inter}
				fontWeight={Dimens.FONT_BOLD}
				fontSize={Dimens.FONT_SIZE_MEDIUM}
			>
				{formatCurrency(amounts?.itemsTotalAmount)}
			</Typography>
			<SizedBox height={3} />
			<Typography
				fontFamily={inter}
				fontSize={Dimens.FONT_SIZE_SMALL_TITLE}
				color={theme.palette.textLight.main}
			>
				Valor de encomenda com portes
			</Typography>
			<Typography
				fontFamily={inter}
				fontWeight={Dimens.FONT_BOLD}
				fontSize={Dimens.FONT_SIZE_MEDIUM}
			>
				{formatCurrency((
					(amounts?.itemsTotalAmount || 0) - (amounts?.discount || 0))
					+ (amounts?.shipmentFee ?? 0
					))}
			</Typography>
		</Card>
	);
};

export default PriceCard;
