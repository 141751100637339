import { ApiResponse } from 'common/domain/entities/api-response';
import React, {
	createContext, FC, useContext, useMemo, useState,
} from 'react';
import { CatelogBackofficeModel } from '../../data/dto/catelog-backoffice-model';
import { FilterParams } from '../../data/remote/catelog-api';
import { CatelogApiImpl } from '../../data/remote/catelog-api-impl';

interface FetchState {
	loading: boolean;
	error: string;
}

type TContextProps = {
	catelogs: CatelogBackofficeModel[]
	catelogFetchState: FetchState;
	getCatelogs: (params: FilterParams) => Promise<boolean>
}

const CatelogContext = createContext({} as TContextProps);

export const CatelogProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const [catelogs, setCatelogs] = useState([] as CatelogBackofficeModel[]);
	const [catelogFetchState, setCatelogFetchState] = useState<FetchState>({
		loading: false,
		error: '',
	});

	const catelogApi = new CatelogApiImpl();

	const getCatelogs = (params: FilterParams) => {
		setCatelogFetchState({
			...catelogFetchState,
			loading: true,
		});

		return catelogApi.getCatelogs(params).then((res) => {
			setCatelogs(res.data);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setCatelogFetchState((prev: FetchState) => ({ ...prev, error: responseError.message }));
			return false;
		}).finally(() => {
			setCatelogFetchState((prev: FetchState) => ({ ...prev, loading: false }));
		});
	};

	const value = useMemo(() => ({ getCatelogs, catelogs, catelogFetchState }), [catelogs,
		catelogFetchState]);
	return <CatelogContext.Provider value={value}>{children}</CatelogContext.Provider>;
};

export function useCatelog() {
	return useContext(CatelogContext);
}
