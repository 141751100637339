import React, { useEffect } from 'react';
import {
	Drawer, Stack, useTheme, Box, IconButton, SvgIcon,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { Products } from 'assets/icons';
import BodyText from '../body-text/BodyText';
import Space from '../space/Space';
import SidebarListItem, { MenuItem } from './SidebarListItem';
import useSearch from '../../../../features/products/presentation/provider/search.provider';
import AppLinks from '../../../../assets/applinks.routes';

interface Props {
	open: boolean
	onClick?: (item: MenuItem) => void;
	handleClose: () => void
}

const SideBar: React.FC<Props> = (props) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const { open, handleClose, onClick } = props;
	const { getCategories, categories } = useSearch();

	useEffect(() => {
		getCategories();
	}, []);

	const menuItems = categories.map((el) => ({ title: el.name, url: `${AppLinks.PRODUCTS}?categoryId=${el.productCategoryId}` }));
	return (
		<Drawer
			open={open}
			onClose={handleClose}
		>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{
					height: Dimens.APP_BAR_HEIGHT,
					bgcolor: theme.palette.primary.main,
					pr: Dimens.PAGE_PADDING_SMALL_MOBILE,
				}}
			>
				<Box />
				<Stack direction="row">
					<SvgIcon>
						<Products />
					</SvgIcon>
					<Space width={Dimens.BUTTON_PADDING_RIGHT_LEFT} />
					<BodyText sx={{ fontWeight: Dimens.FONT_BOLD, color: theme.palette.textMain.main }}>{t('products').toUpperCase()}</BodyText>
				</Stack>
				<IconButton onClick={handleClose}>
					<Close color="secondary" />
				</IconButton>
			</Stack>
			<Box sx={{ width: Dimens.DRAWER_WIDTH, pr: Dimens.PAGE_PADDING_SMALL_MOBILE, height: '90%' }}>
				{menuItems.map((el, index) => (
					<span key={`sidebar-${index + 1}`}>
						<SidebarListItem item={el} onClick={onClick} />
					</span>
				))}
			</Box>
		</Drawer>
	);
};

export default SideBar;
