import { Typography, TypographyProps, useTheme } from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';

const TitleSmall: React.FC<TypographyProps> = (props) => {
	const theme = useTheme();
	const {
		children, sx,
	} = props;
	return (
		<Typography
			{...props}
			sx={{
				fontWeight: Dimens.FONT_BOLDER,
				// lineHeight: {
				// 	lg: Dimens.TEXT_TITLE,
				// 	xl: Dimens.TEXT_TITLE,
				// 	md: Dimens.TEXT_TITLE_LARGE_MOBILE,
				// 	xs: Dimens.TEXT_TITLE_MOBILE,
				// },
				fontSize: {
					lg: Dimens.FONT_SIZE_SMALL_TITLE,
					xl: Dimens.FONT_SIZE_SMALL_TITLE,
					md: Dimens.FONT_SIZE_SMALL_TITLE,
					xs: Dimens.FONT_SIZE_SMALL_TITLE_MOBILE,
					sm: Dimens.FONT_SIZE_SMALL_TITLE_MOBILE,
				},
				color: theme.palette.textMain.main,
				...sx,
			}}
		>
			{children}

		</Typography>
	);
};

export default TitleSmall;
