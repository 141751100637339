import {
	Accordion, AccordionDetails, AccordionSummary, Stack, Typography,
} from '@mui/material';
import React from 'react';
import { ProductCategoryModel } from '../../data/dto/product-category-model';
import { BodyText } from 'common/presentation/components';
import { ExpandMore } from '@mui/icons-material';

interface Props {
	selected?: number;
	category: ProductCategoryModel,
	onClick?: (value: number | undefined) => void;
	subGroup?: boolean
}
export const CategoryMenuItem: React.FC<Props> = (props) => {
	const {
		selected,
		category,
		onClick,
		subGroup,
	} = props;

	const [expanded, setExpanded] = React.useState<string | false>(false);
	const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};
	return (
		<Accordion
			key={`cat-menu-${category.productCategoryId}`}
			expanded={expanded === `panel-${category.productCategoryId}`}
			elevation={0}
			onChange={handleChange(`panel-${category.productCategoryId}`)}
			disableGutters
		>
			<AccordionSummary
				expandIcon={category?.childrenCategories?.length > 0 && <ExpandMore />}
				aria-controls="panel1bh-content"
				id="panel1bh-header"
				sx={{ background: subGroup ? 'transparent' : '#F5F5F5' }}
			>
				<BodyText
					onClick={() => onClick?.call(0, category.productCategoryId)}
					sx={{
						flexShrink: 0,
						textTransform: 'uppercase',
						color: '#374B54',
						fontWeight: subGroup ? '600' : 'bold',
						fontSize: subGroup ? '12px' : '14px',
						cursor: 'pointer',
						'&:hover': {
							textDecoration: 'underline',
						},
					}}
				>
					{category.name}
				</BodyText>
			</AccordionSummary>
			<AccordionDetails sx={{ padding: 0, pl: 0.1 }}>

				<Stack>
					{category?.childrenCategories?.map((subCat) => {
						if ((subCat.childrenCategories?.length ?? 0) > 0) {
							return (
								<Stack sx={{ ml: 1 }} key={`cat-submenu-${subCat.productCategoryId}`}>
									<CategoryMenuItem
										category={subCat}
										selected={selected}
										onClick={onClick}
										subGroup
									/>
								</Stack>
							);
						}
						const isSelected = subCat.productCategoryId === selected;
						return (
							<Stack
								key={`cat-submenu-${subCat.productCategoryId}`}
								sx={{
									borderLeft: isSelected ? '#B9C92B solid 2px' : '', color: '#B9C92B', pl: 3, pr: 2, pt: 0.3, cursor: 'pointer',
								}}
								onClick={() => onClick?.call(0, subCat.productCategoryId)}
							>
								<Typography sx={{ borderBottom: '#E9E9E9 solid 1px', pb: 0.3, color: isSelected ? '#B9C92B' : '#666' }}>
									{subCat.name}
								</Typography>
							</Stack>
						);
					})}
				</Stack>

			</AccordionDetails>
		</Accordion>
	);
};
