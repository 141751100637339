import { TitleMedium } from 'common/presentation/components';
import {
	Box, Grid, Stack,
} from '@mui/material';
import React, { useState } from 'react';
import TextField from 'common/presentation/components/text-field/TextField';
import { CompanyField, NifField, PersonField } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import CheckItem from './components/CheckItem';
import { InputFieldProps } from 'common/domain/entities/input-field-props';

type TClientProps = {
	nameField: InputFieldProps<HTMLTextAreaElement>;
	taxIdField: InputFieldProps<HTMLTextAreaElement>;
	companyField: InputFieldProps<HTMLTextAreaElement>;
	commentField: InputFieldProps<HTMLTextAreaElement>;
	commentCheck: boolean;
	onCommentCheckChange: (value: boolean) => void
};

const ClientDetails: React.FC<TClientProps> = ({
	nameField,
	taxIdField,
	companyField,
	commentField,
	commentCheck,
	onCommentCheckChange,
}) => {
	const { t } = useTranslation('translations');
	const [comments, setComments] = useState(commentCheck);

	const handleCommentsChange = () => {
		onCommentCheckChange(!comments);
		setComments((prev) => !prev);
	};

	return (
		<Box>
			<TitleMedium sx={{ textAlign: 'center', mt: 4 }}>{t('client_details')}</TitleMedium>
			<Grid container spacing={2} sx={{ mt: 5 }}>
				<Grid item md={4} xs={12}>
					<TextField
						id="fullName"
						{...nameField}
						startIcon={<PersonField />}
						label={t('name_and_surname')}
					/>
				</Grid>
				<Grid item md={4} xs={12}>
					<TextField
						id="taxId"
						{...taxIdField}
						// error={Boolean(state.nameError)}
						// helperText={state.nameError}
						// onChange={handleChange}
						optional
						startIcon={<NifField />}
						label={t('tax_id_label')}
					/>
				</Grid>
				<Grid item md={4} xs={12}>
					<TextField
						id="companyName"
						{...companyField}
						optional
						// error={Boolean(state.nameError)}
						// helperText={state.nameError}
						// onChange={handleChange}
						startIcon={<CompanyField />}
						label={t('Empresa')}
					/>
				</Grid>
				<Grid item md={12}>
					<CheckItem
						label={t('would_add_comments')}
						positiveButtonText={t('yes')}
						negativeButtonText={t('no')}
						negativeChecked={!comments}
						positiveChecked={comments}
						negativeAction={handleCommentsChange}
						positiveAction={handleCommentsChange}
					/>
				</Grid>

				<Grid item md={12} xs={12} sx={{ display: comments ? 'flex' : 'none' }}>
					<Stack width="100%">
						<TextField
							label={t('comment')}
							{...commentField}
							placeholder={t('describe_your_question')}
							sx={{ width: '100%' }}
							multiline
							fullWidth
							minRows={4}
						/>
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ClientDetails;
