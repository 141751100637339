import { ICartProduct } from 'common/domain/entities/cart-product';
import {
	Stack, useTheme, Box, useMediaQuery, Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import TitleSmall from 'common/presentation/components/title-small/TitleSmall';
import { BodyText, DefaultButton } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import CounterButton from 'common/presentation/components/counter-button/CounterButton';
import { useTranslation } from 'react-i18next';
import { imageNotLoaded } from 'assets/images';
import { processImg } from 'common/utils/general.utils';

interface CartItemProps {
	product: ICartProduct;
	onIncrement?: (prevCount: number) => void
	onDecrement?: (newCount: number) => void
	onRemove: (product: ICartProduct) => void
}

const CartItem: React.FC<CartItemProps> = (props) => {
	const {
		product, onRemove, onIncrement, onDecrement,
	} = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const handleRemoveClick = () => {
		onRemove.call(0, product);
	};
	const images = [product.image, ...(product.images || [])].filter((item) => item !== undefined);
	const firstImage = images.length !== 0 ? images[0] : undefined;
	const [imageProcessed, setImageProcessed] = useState<string>();

	useEffect(() => {
		processImg(firstImage || '').then((processed) => {
			setImageProcessed(processed);
		}).catch(() => {
			setImageProcessed(undefined);
		});
	}, [firstImage]);
	return (
		<Grid
			container
			direction={{ md: 'row', xs: 'column' }}
			alignItems="center"
			justifyContent="center"
			gap={1}
			sx={{
				 border: `1px solid ${theme.palette.secondary.main}`, pt: 1, pb: 1, pl: 2.5, pr: 2.5, mb: 2,
			}}
		>
			<Grid item md={2} xs={12}>
				<div style={{
					display: 'flex',
					width: isMd ? 165 : 100,
					height: isMd ? 223 : 180,
					alignItems: 'center',
				}}
				>
					<img src={imageProcessed || imageNotLoaded} width="100%" height={150} style={{ objectFit: 'contain' }} alt="product background" />
				</div>
			</Grid>
			<Grid item md={6} xs={12}>
				<Box>

					<Grid container gap={1}>
						<BodyText sx={{
							color: theme.palette.secondary.main,
							fontWeight: Dimens.FONT_NORMAL,
							display: 'flex',
							fontSize: 12,
							gap: 1,
						}}
						>
							<BodyText component="span" sx={{ fontSize: 12 }}>{t('code').toUpperCase()}</BodyText>
							{product.reference}
						</BodyText>
						<Box sx={{ flexGrow: 1 }} />
						<BodyText sx={{
							color: theme.palette.secondary.main,
							fontWeight: Dimens.FONT_NORMAL,
							display: 'flex',
							fontSize: 12,
							gap: 1,
						}}
						>
							<BodyText component="span" sx={{ fontSize: 12 }}>{t('barcode').toUpperCase()}</BodyText>
							{product.barcode}
						</BodyText>

					</Grid>
					<TitleSmall sx={{ wordBreak: 'break-word' }}>{product.name}</TitleSmall>
					<BodyText sx={{ fontWeight: Dimens.FONT_BOLD, mt: 1 }}>
						{product.description}
					</BodyText>
				</Box>
			</Grid>
			<Grid item md={2} sm={12} xs={12} alignItems="center">
				<Stack alignItems="center">
					<DefaultButton
						onClick={handleRemoveClick}
						variant="text"
						sx={{
							color: theme.palette.error.main,
							textDecoration: 'underline',
							mb: 2,
						}}
					>
						{t('remove_article')}
					</DefaultButton>
					<CounterButton
						count={product.quantity}
						onIncrement={onIncrement}
						onDecrement={onDecrement}
					/>
					<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
						<BodyText sx={{ mt: 1, fontSize: Dimens.FONT_SIZE_SMALL }}>
							{t('price_with_tax')}
						</BodyText>
						<TitleSmall>
							{`${Number.parseFloat(`${product.priceWithTaxes || 0}`).toFixed(2) || 0} €`}
						</TitleSmall>
					</Stack>
				</Stack>
			</Grid>
		</Grid>
	);
};

export default CartItem;
