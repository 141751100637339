import { Box } from '@mui/material';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
	const { children, index, value } = props;
	return (
		<div>
			{value === index && (
				<Box sx={{ mt: 2 }}>
					{children}
				</Box>
			)}
		</div>
	);
};

export default TabPanel;
