import React, {
	createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { AuthApiImpl } from '../../data/remote/auth-api-impl';
import { ApiResponse } from 'common/domain/entities/api-response';
import LocalStorageWrapper, { LocalStorageKeys } from 'common/utils/storage-utils';
import { IdNameModel } from 'common/data/dto/id-name-model';
import { CommonApiImpl } from 'common/data/remote/common-api-impl';
import { UserReadModel, UserUpdateModel } from '../../data/dto/user-model';
import AppLinks from 'assets/applinks.routes';
import { useNavigate } from 'react-router-dom';
import { AxiosProgressEvent } from 'axios';
import { OrderReadModel } from 'features/purchase/data/dto/order-read-model';
import { OrderApiImpl } from 'features/purchase/data/remote/order-api-impl';
import { ICartProduct } from 'common/domain/entities/cart-product';

type TContextProps = {
	loading: boolean;
	uploadLoading: boolean;
	error: string;
	countries: IdNameModel[];
	districts: IdNameModel[];
	userAuth?: UserReadModel;
	orders: OrderReadModel[]
	order?: OrderReadModel
	login: (email: string, password: string) => Promise<boolean>;
	createUser: (fullName: string, email: string, password: string) => Promise<boolean>;
	getCountries: () => Promise<boolean>
	getDistricts: (countryId: number) => Promise<boolean>
	getUserInfo: () => Promise<boolean>
	updateUser: (user: UserUpdateModel) => Promise<{
		success: boolean;
		message: string;
	}>
	uploadFile: (
		file: File,
		onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined,
	) => Promise<{
		success: boolean;
		message: string;
		files?: {
			fileUrl: string;
		}[];
	}>
	getOrders: () => Promise<boolean>
	getOrder: (orderId: number) => Promise<boolean>
	getOrderProofFile: (orderId: number) => Promise<{
		success: boolean;
		message: string;
		file?: File;
	}>
}

const AuthContext = createContext({} as TContextProps);

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = useState(false);
	const [uploadLoading, setUploadLoading] = useState(false);
	const [error, setError] = useState('');
	const [countries, setCountries] = useState<IdNameModel[]>([]);
	const [districts, setDistricts] = useState<IdNameModel[]>([]);
	const [orders, setOrders] = useState<OrderReadModel[]>([]);
	const [order, setOrder] = useState<OrderReadModel>();

	const [userAuth, setUserAuth] = useState<UserReadModel>();
	const authApi = new AuthApiImpl();
	const commonApi = new CommonApiImpl();
	const ordersApi = new OrderApiImpl();
	const navigate = useNavigate();

	useEffect(() => {
		const key = LocalStorageWrapper.get<string>(LocalStorageKeys.REFRESH_STORE_TOKEN);
		if (!key && (window.location.href.indexOf(AppLinks.CLIENT_AREA) > -1)) {
			navigate(AppLinks.LOGIN);
		}
		const user = LocalStorageWrapper.get<UserReadModel>(LocalStorageKeys.USER_STORE);
		if (user) {
			setUserAuth(user);
		}
	}, []);

	const getUserInfo = async () => {
		setLoading(true);
		setError('');
		return authApi.getUserInfo().then((result) => {
			setUserAuth(result.data);
			LocalStorageWrapper.set(LocalStorageKeys.USER_STORE, result.data);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setError(responseError.message);
			return false;
		}).finally(() => {
			setLoading(false);
		});
	};

	const syncCart = async () => {
		commonApi.cartItems()
			.then(({ data: { items } }) => {
				if (items.length === 0) {
					const localItems = LocalStorageWrapper.get<ICartProduct[]>(LocalStorageKeys.CART);
					if (localItems && localItems.length !== 0) {
						localItems.forEach(async (item) => {
							try {
								await commonApi.cartAdd(item.productId, item.quantity);
							} catch (err) {
								// eslint-disable-next-line no-console
								console.log(err);
							}
						});
					}
				}
			}).catch(() => false);
	};

	const login = async (email: string, password: string) => {
		setLoading(true);
		setError('');
		return authApi.loginUser(email, password).then(async (result) => {
			const { authToken: newAuthToken } = result.data;
			LocalStorageWrapper.set(LocalStorageKeys.AUTH_STORE_TOKEN, newAuthToken);
			LocalStorageWrapper.set(LocalStorageKeys.REFRESH_STORE_TOKEN, result.data.refreshToken);
			await getUserInfo();
			syncCart();
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setError(responseError.message);
			return false;
		}).finally(() => {
			setLoading(false);
		});
	};

	const createUser = (fullName: string, email: string, password: string) => {
		setLoading(true);
		setError('');
		return authApi.createUser({ fullName, email, password }).then(() => true).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setError(responseError.message);
			return false;
		}).finally(() => {
			setLoading(false);
		});
	};

	const updateUser = (user: UserUpdateModel) => {
		setLoading(true);
		setError('');
		return authApi.updateUser(user).then(() => ({ success: true, message: '' })).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setError(responseError.message);
			return { success: false, message: responseError.message };
		}).finally(() => {
			setLoading(false);
		});
	};

	const getCountries = async () => {
		setLoading(true);
		setError('');
		return commonApi.getCountries().then((result) => {
			setCountries(result.data);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setError(responseError.message);
			return false;
		}).finally(() => {
			setLoading(false);
		});
	};

	const getDistricts = async (countryId: number) => {
		setLoading(true);
		setError('');
		return commonApi.getDistricts(countryId).then((result) => {
			setDistricts(result.data);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setError(responseError.message);
			return false;
		}).finally(() => {
			setLoading(false);
		});
	};

	const getOrders = async () => {
		setLoading(true);
		setError('');
		return ordersApi.getOrders().then((result) => {
			setOrders(result.data);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setError(responseError.message);
			return false;
		}).finally(() => {
			setLoading(false);
		});
	};

	const getOrder = async (orderId: number) => {
		setLoading(true);
		setError('');
		return ordersApi.getOrder(orderId).then((result) => {
			setOrder(result.data);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setError(responseError.message);
			return false;
		}).finally(() => {
			setLoading(false);
		});
	};

	const uploadFile = (
		file: File,
		onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined,
	) => {
		setUploadLoading(true);
		return authApi.uploadFile(file, onUploadProgress).then((e) => ({ success: true, message: '', files: e.data })).catch((err) => {
			const responseError = err as ApiResponse<void>;
			return { success: false, message: responseError.message };
		}).finally(() => {
			setUploadLoading(false);
		});
	};

	const getOrderProofFile = (orderId: number) => {
		setLoading(true);
		return ordersApi.getOrderProof(orderId).then((e) => ({
			success: true,
			message: 'successs',
			file: e.data,
		})).catch((err) => {
			const responseError = err as ApiResponse<void>;
			return { success: false, message: responseError.message };
		}).finally(() => {
			setLoading(false);
		});
	};

	const value = useMemo(() => ({
		loading,
		error,
		login,
		createUser,
		countries,
		getCountries,
		districts,
		getDistricts,
		getUserInfo,
		updateUser,
		userAuth,
		uploadFile,
		uploadLoading,
		orders,
		order,
		getOrders,
		getOrder,
		getOrderProofFile,
	}), [
		loading,
		error,
		countries,
		districts,
		userAuth,
		orders,
		order,
		uploadLoading,
	]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
	return useContext(AuthContext);
}
