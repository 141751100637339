import { AtField, CellField, PhoneField } from 'assets/icons';
import { TitleMedium } from 'common/presentation/components';
import TextField from 'common/presentation/components/text-field/TextField';
import {
	Box, Grid,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputFieldProps } from 'common/domain/entities/input-field-props';

type TContactSectionProps = {
	emailField: InputFieldProps<HTMLTextAreaElement>;
	cellField: InputFieldProps<HTMLTextAreaElement>;
	phoneField: InputFieldProps<HTMLTextAreaElement>;
};

const ContactSection: React.FC<TContactSectionProps> = ({
	emailField,
	cellField,
	phoneField,
}) => {
	const { t } = useTranslation('translations');
	return (
		<Box>
			<TitleMedium sx={{ textAlign: 'center', mt: 4 }}>{t('contacts')}</TitleMedium>
			<Grid container spacing={2} sx={{ mt: 5 }}>
				<Grid item md={4} xs={12}>
					<TextField
						{...emailField}
						startIcon={<AtField />}
						label={t('label_email')}
					/>
				</Grid>
				<Grid item md={4} xs={12}>
					<TextField
						name="cell"
						{...cellField}
						startIcon={<CellField />}
						label={t('cell_phone')}
					/>
				</Grid>
				<Grid item md={4} xs={12}>
					<TextField
						name="phone"
						{...phoneField}
						optional
						startIcon={<PhoneField />}
						label={t('phone')}
					/>
				</Grid>
			</Grid>

		</Box>
	);
};

export default ContactSection;
