import { ApiResponse } from 'common/domain/entities/api-response';
import { CustomersApi, FilterParams } from './customers-api';
import http from './http';
import { CustomerBackofficeModel, CustomerGroupModel, FullCustomerModel } from '../dto/customer-backoffice-model';
import { serializeFormQuery } from 'common/utils/general.utils';
import { httpGet, httpPatch } from 'common/utils/http.utils';
import { ClientOrderBackoffice } from '../dto/order-backoffice-model';

export class CustomersApiImpl implements CustomersApi {
	apiService = http;

	getCustomers(filter: FilterParams): Promise<ApiResponse<CustomerBackofficeModel[]>> {
		const query = serializeFormQuery(filter);
		return httpGet(`/customers${query}`, this.apiService);
	}

	getCustomerById(id: string): Promise<ApiResponse<FullCustomerModel>> {
		return httpGet(`/customers/${id}`, this.apiService);
	}

	getCustomerOrders(id: string): Promise<ApiResponse<ClientOrderBackoffice[]>> {
		return httpGet(`/customers/${id}/orders`, this.apiService);
	}

	getCustomerGroups(): Promise<ApiResponse<CustomerGroupModel[]>> {
		return httpGet('/customer-groups', this.apiService);
	}

	updateCustomerGroup(id: number, groupId: number): Promise<ApiResponse<void>> {
		return httpPatch(`/customers/${id}/CustomerGroup`, { groupId }, this.apiService);
	}

	updateCustomerStatus(id: number): Promise<ApiResponse<CustomerBackofficeModel>> {
		return httpPatch(`/customers/${id}/ChangeStatus`, { }, this.apiService);
	}
}
