import {
	Box, FormControl, IconButton, InputAdornment, OutlinedInput, Stack, Typography, useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useOrdersBackoffice from '../provider/order-backoffice.provider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OrderBackofficeSimpleModel } from '../../data/dto/order-backoffice-model';
import { Tag } from 'primereact/tag';
import { getOrderStatusColors } from 'common/utils/general.utils';
import { Dropdown } from 'primereact/dropdown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FilterParams } from '../../data/remote/order-api';
import SearchIcon from '@mui/icons-material/Search';
import { useDebounce } from 'primereact/hooks';
import { useTranslation } from 'react-i18next';
import useNotifications from 'common/presentation/providers/notifications.provider';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';

export const OrdersPage = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const theme = useTheme();
	const { t } = useTranslation();
	const { toast } = useNotifications();
	const navigate = useNavigate();
	const {
		orders, getOrders, setOrders,
		loadingOrders,
		getOrderStatus, orderStatus,
		updateOrderStatus, updatingStatus,
	} = useOrdersBackoffice();
	const [lazyState, setlazyState] = useState({
		first: 0,
		rows: 30,
		page: 1,
		sortField: null,
		sortOrder: null,
		filters: {},
	});
	const [inputValue, debouncedValue, setInputValue] = useDebounce('', 400);
	const [totalOfProducts, setTotalOfProducts] = useState<number>(0);
	const [filter, setFilter] = useState<FilterParams>({
		size: 20,
	} as FilterParams);

	useEffect(() => {
		setFilter(
			(prev) => ({
				...prev,
				size: lazyState.rows,
				page: lazyState.page,
				search: debouncedValue,
			}),
		);
	}, [lazyState, debouncedValue]);

	useEffect(() => {
		getOrderStatus();
	}, []);

	useEffect(() => {
		getOrders(filter).then((res) => {
			setTotalOfProducts(res.total);
		});
	}, [filter]);

	const onPage = (event: any) => {
		setlazyState(event);
	};

	const priceBodyTemplate = (rowData: OrderBackofficeSimpleModel) => new Intl.NumberFormat('pt-EU', {
		style: 'currency',
		currency: 'EUR',
	}).format(rowData.amount);

	const orderStatusTag = (options: any) => {
		const { status } = options;
		const { color, bgColor } = getOrderStatusColors(status.id);
		return (
			<Tag
				value={status.name}
				style={{ backgroundColor: bgColor, color }}
			/>
		);
	};

	const statusEditor = (options: any) => (
		<Dropdown
			value={options?.value}
			options={orderStatus}
			onChange={(e) => options.editorCallback(e.value)}
			placeholder="Select a Status"
			optionLabel="name"
			// eslint-disable-next-line react/no-unstable-nested-components
			itemTemplate={(ev) => {
				const { color, bgColor } = getOrderStatusColors(ev.id);
				return (
					<Stack>
						<Tag value={ev.name} style={{ backgroundColor: bgColor, color }} />
					</Stack>
				);
			}}
		/>
	);

	const onRowEditComplete = (e: any) => {
		const list = [...orders];
		const { newData, index } = e;
		const old = list[index];
		const order = newData as OrderBackofficeSimpleModel;
		list[index] = order;
		setOrders(list);

		Promise.all([
			updateOrderStatus(order.id, order.status.id),
		])
			.then(() => {
				toast.current?.show({
					severity: 'success',
					summary: 'Sucesso',
					detail: 'Item atualizado com sucesso',
				});
			})
			.catch(() => {
				list[index] = old;
				setOrders(list);
				toast.current?.show({
					severity: 'error',
					summary: 'Falha',
					detail: 'Uma ou mais informação não foi possível ser salva.',
				});
			});
	};

	const handleDetailClick = (options: any) => {
		navigate(AppLinks.ORDER_DETAIL.replace(':id', options.id));
	};

	return (
		<Box>
			<Stack direction="row">
				<Box sx={{ flexGrow: 1 }} />
				<FormControl
					variant="outlined"
					sx={{ width: '40ch', alignSelf: 'start', mr: 4 }}
				>
					<OutlinedInput
						size="small"
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						placeholder={t('Encomendas...')}
						startAdornment={(
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						)}
						inputProps={{
							'aria-label': 'search',
						}}
					/>
				</FormControl>
			</Stack>
			<Box sx={{ overflow: 'auto' }}>
				<Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
					<DataTable
						value={orders}
						lazy
						paginator
						first={lazyState.first}
						onPage={onPage}
						rows={lazyState.rows}
						totalRecords={totalOfProducts}
						editMode="row"
						onRowEditComplete={onRowEditComplete}
						loading={loadingOrders || updatingStatus}
						dataKey="id"
						tableStyle={{ minWidth: '50rem' }}
					>
						<Column
							field="productId"
							header="ID"
							align="center"
							// eslint-disable-next-line react/no-unstable-nested-components
							body={(rowData: OrderBackofficeSimpleModel) => (
								<Typography sx={{ color: '#B9C92B', fontWeight: 600 }}>
									{`#${rowData.id}`}
								</Typography>
							)}
						/>
						<Column field="customerName" bodyStyle={{ fontSize: 14 }} header="Nome" />

						<Column
							field="amount"
							align="center"
							header="Preço uni."
							style={{ fontWeight: 600 }}
							body={(options) => priceBodyTemplate(options)}
						/>

						<Column
							field="itemsQuantity"
							header="Quantidade"
							align="center"
							// eslint-disable-next-line react/no-unstable-nested-components
							body={(rowData: OrderBackofficeSimpleModel) => (
								<Typography sx={{ fontWeight: 600 }}>
									{`${rowData.itemsQuantity}`}
								</Typography>
							)}
						/>

						<Column
							header="Status"
							align="center"
							field="status"
							body={(options) => orderStatusTag(options)}
							editor={(options) => statusEditor(options)}
							headerStyle={{ width: '10%', minWidth: '8rem' }}
						/>
						<Column
							rowEditor
							headerStyle={{ width: '10%', minWidth: '8rem' }}
							bodyStyle={{ textAlign: 'center' }}
						/>
						<Column
							headerStyle={{ width: '10%', minWidth: '8rem' }}
							// eslint-disable-next-line react/no-unstable-nested-components
							body={(options) => (
								<IconButton onClick={() => handleDetailClick(options)}>
									<ArrowForwardIosIcon />
								</IconButton>
							)}
							bodyStyle={{ textAlign: 'center' }}
						/>
					</DataTable>
				</Box>
			</Box>
		</Box>
	);
};
