import { ApiResponse } from '@/common/domain/entities/api-response';
import { IdNameModel } from '../dto/id-name-model';
import { CommonApi } from './common-api';
import {
	httpDelete, httpGet, httpPatch, httpPost,
} from 'common/utils/http.utils';
import storeHttp from './store-http';
import { ShoppingCart, ShoppingCartItem } from 'common/domain/entities/cart-product';
import { DeliveryEstimate } from '../dto/delivery-estimate-model';
import { ProductOrderItem } from 'features/purchase/data/dto/order-create-model';

export class CommonApiImpl implements CommonApi {
	apiService = storeHttp;

	getCountries(): Promise<ApiResponse<IdNameModel[]>> {
		return httpGet('/countries', this.apiService);
	}

	getDistricts(countryId: number): Promise<ApiResponse<IdNameModel[]>> {
		return httpGet(`/countries/${countryId}/districts`, this.apiService);
	}

	cartItems(): Promise<ApiResponse<ShoppingCart>> {
		return httpGet('/shopping-cart-items', this.apiService);
	}

	cartAdd(productId: number, quantity: number): Promise<ApiResponse<ShoppingCartItem>> {
		return httpPost('/shopping-cart-items', {
			productId,
			quantity,
		}, this.apiService);
	}

	cartRemove(id: number): Promise<ApiResponse<void>> {
		return httpDelete(`/shopping-cart-items/${id}`, {}, this.apiService);
	}

	cartUpdateQuantity(id: number, quantity: number): Promise<ApiResponse<ShoppingCartItem>> {
		return httpPatch(`/shopping-cart-items/${id}`, {
			quantity,
		}, this.apiService);
	}

	getDeliveryEstimate(
		districtId: number,
		products: ProductOrderItem[],
	): Promise<ApiResponse<DeliveryEstimate>> {
		return httpPost('/shipment/estimate', {
			districtId,
			products,
		}, this.apiService);
	}
}
