import React, { useEffect, useState } from 'react';
import {
	Box, Stack, useTheme, Typography, Button, IconButton,
} from '@mui/material';
import { DefaultButton, SizedBox } from 'common/presentation/components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Add, FilterAlt } from '@mui/icons-material';
import { useCatelog } from '../provider/catelog.provider';
import { FilterParams } from '../../data/remote/catelog-api';
import moment from 'moment';
import { CatelogBackofficeModel } from '../../data/dto/catelog-backoffice-model';
import { getProductStatusColors } from 'common/utils/general.utils';
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StatusFilterModal from '../components/status-filter-modal/StatusFilterModal';

export const CatalogPage = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const { getCatelogs, catelogs, catelogFetchState } = useCatelog();
	const [filterParams, setFilterParams] = useState<FilterParams>({
		search: '',
		page: 1,
		size: 10,
		sortBy: 'id',
		active: true,
	});
	const [catelogStatuses] = useState<string[]>([t('active'), t('inactive')]);
	const [filterDialogOpen, setFilterDialogOpen] = useState(false);

	useEffect(() => {
		getCatelogs(filterParams);
	}, [filterParams]);

	const customerStatusTag = (options: any) => {
		const { active } = options;
		const { color, bgColor } = getProductStatusColors(active);
		return (
			<Tag
				value={active ? catelogStatuses[0] : catelogStatuses[1]}
				style={{
					backgroundColor: bgColor,
					color,
					fontSize: 14,
					paddingLeft: 8,
					paddingRight: 8,
				}}
			/>
		);
	};

	const handleFilterClick = () => {
		setFilterDialogOpen(true);
	};

	const handleFilterDialogClose = () => {
		setFilterDialogOpen(false);
	};

	return (
		<>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography variant="h4" sx={{ fontFamily: 'inter' }}>
          			{t('catelogues')}
				</Typography>
				<DefaultButton
					sx={{ borderRadius: 3 }}
					disabled
					startIcon={
						<Add />
					}
				>
            		{t('add_catelog')}
				</DefaultButton>
			</Stack>
			<SizedBox height={4} />
			<Box sx={{ backgroundColor: theme.palette.background.default }}>
				<Stack justifyContent="space-between" direction="row" alignItems="center" sx={{ p: 2 }}>
					<Typography>{t('catelog_list')}</Typography>
					<Button
						color="secondary"
						variant="outlined"
						onClick={handleFilterClick}
						startIcon={<FilterAlt />}
						sx={{
							borderRadius: '5px',
							fontSize: '16px',
						}}
					>
						{t('filters')}
					</Button>
				</Stack>
				<DataTable value={catelogs} loading={catelogFetchState.loading}>

					<Column
						field="id"
						header="ID"
						align="left"
						// eslint-disable-next-line react/no-unstable-nested-components
						body={(rowData: CatelogBackofficeModel) => (
							<Typography sx={{ color: '#B9C92B', fontWeight: 600 }}>
								{`#${rowData.id}`}
							</Typography>
						)}
					/>
					<Column
						field="name"
						bodyStyle={{ fontSize: 18 }}
						header={t('catelog_name')}
						// style={{ width: '25%' }}
					/>
					<Column
						field="createdAt"
						align="left"
						bodyStyle={{ fontSize: 18 }}
						header={t('added_on')}
						body={(rowData) => moment(rowData.createdAt).format('DD [de] MMMM YYYY')}
					/>
					<Column
						field="active"
						header="Status"
						align="left"
						body={(options) => customerStatusTag(options)}
					/>
					<Column
						// eslint-disable-next-line react/no-unstable-nested-components
						body={() => (
							<IconButton>
								<ArrowForwardIosIcon />
							</IconButton>
						)}
					/>
				</DataTable>
			</Box>
			<StatusFilterModal
				handleFilterClick={(value) => setFilterParams({ ...filterParams, active: value })}
				open={filterDialogOpen}
				handleClose={handleFilterDialogClose}
			/>
		</>
	);
};
