import React from 'react';

interface Props {
	width: string
}

const Space: React.FC<Props> = (props) => {
	const { width } = props;
	return (
		<span style={{ width }} />
	);
};

export default Space;
