import React, {
	createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import { Catelog } from '../../domain/entities/catelog';
import { CatelogRepositoryImpl } from '../../data/repositories/catelog-repository-impl';
import { GetCatelogs } from '../../domain/usecases/get-catelog';

type TContextProps = {
	loading: boolean;
	error: string;
	catelogs: Catelog[];
	getCatelogs: () => Promise<void>;
}

const CatelogContext = createContext({} as TContextProps);

export const CatelogProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [catelogs, setCatelog] = useState([] as Catelog[]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const catelogRepository = useMemo(() => new CatelogRepositoryImpl(), []);

	const getCatelogs = useCallback(async () => {
		setLoading(true);
		const catelogUseCase = new GetCatelogs(catelogRepository);
		const catelogResult = await catelogUseCase.call();
		if (catelogResult.isRight()) {
			setCatelog(catelogResult.value.data);
		} else {
			setError(catelogResult.error);
		}
		setLoading(false);
	}, [catelogRepository]);

	const value = useMemo(() => ({
		catelogs,
		loading,
		error,
		getCatelogs,
	}), [catelogs, loading, error, getCatelogs]);

	return <CatelogContext.Provider value={value}>{children}</CatelogContext.Provider>;
};

export default function useCatelogs() {
	return useContext(CatelogContext);
}
