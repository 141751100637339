import React from 'react';
import ContactUsSection from './ContactUsSection';
import { makeLoginValidation } from './builder/contact-factory';
import { ContactProvider } from './provider/contact.provider';

const ContactPage = () => (
	<main>
		<ContactProvider>
			<ContactUsSection validation={makeLoginValidation()} />
		</ContactProvider>
	</main>
);

export default ContactPage;
