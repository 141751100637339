import React from 'react';
import { CustomerForm } from './CustomerForm';

const ClientArea = () => (
	<section>
		<CustomerForm />
	</section>
);

export default ClientArea;
