/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import React, { createContext, useEffect, useState } from 'react';
import { ICartProduct, ShoppingCart } from '../../domain/entities/cart-product';
import { cartReducer } from './reducers/cart-state.reducer';
import { CartActionKind } from '../../domain/entities/cart-action';
import LocalStorageWrapper, { LocalStorageKeys } from 'common/utils/storage-utils';
import { OrderApiImpl } from 'features/purchase/data/remote/order-api-impl';
import { OrderCreateParamModel } from 'features/purchase/data/dto/order-create-model';
import { ApiResponse } from 'common/domain/entities/api-response';
import { CommonApiImpl } from 'common/data/remote/common-api-impl';
import useAuth from 'features/authentication/presentation/provider/auth.provider';
import { PaymentModel } from 'features/purchase/data/dto/payment-model';
import { DeliveryEstimate } from 'common/data/dto/delivery-estimate-model';

type ActionFunction = (item: ICartProduct) => void

type CartContextProps = {
	cartProducts: ICartProduct[]
	addToCart: ActionFunction
	removeFromCart: ActionFunction
	incrementProduct: ActionFunction
	decrementCart: ActionFunction
	getTotalPrice: () => number
	createOrder: (order: OrderCreateParamModel) => Promise<{
		success: boolean;
		message: string;
		orderId?: number;
	}>
	loading: boolean;
	createOrUpdatePayment: (order: OrderCreateParamModel) => Promise<{
		success: boolean;
		message: string;
	}>
	payment?: PaymentModel;
	loadingPayment: boolean;

	createMultibancoPayment: (order: OrderCreateParamModel) => Promise<{
		success: boolean;
		message: string;
		paymentId?: string
	}>
	paymentMultibanco?: PaymentModel;
	loadingPaymentMultibanco: boolean;

	deliveryEstimate?: DeliveryEstimate,
	getDeliveryEstimate: (districtId: number) => Promise<{
		success: boolean;
		message: string;
	}>
}

export const CartContext = createContext<CartContextProps>({} as CartContextProps);

export const CartProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = useState(false);
	const localItems = LocalStorageWrapper.get<ICartProduct[]>(LocalStorageKeys.CART);
	const { userAuth } = useAuth();
	const [shoppintCart, setShoppingCart] = useState<ShoppingCart>();

	const [payment, setPayment] = useState<PaymentModel>();
	const [loadingPayment, setLoadingPayment] = useState(true);

	const [paymentMultibanco, setPaymentMultibanco] = useState<PaymentModel>();
	const [loadingPaymentMultibanco, setLoadingPaymentMultibanco] = useState(true);

	const [deliveryEstimate, setDeliveryEstimate] = useState<DeliveryEstimate>();

	const orderApi = new OrderApiImpl();
	const commonApi = new CommonApiImpl();

	const syncServerCart = () => {
		commonApi.cartItems()
			.then((result) => setShoppingCart(result.data))
			// eslint-disable-next-line no-console
			.catch((ex) => console.error(ex));
	};

	useEffect(() => {
		if (userAuth) {
			syncServerCart();
		}
	}, [userAuth]);

	const [cartState, dispatch] = React.useReducer(
		cartReducer,
		{ cartProducts: localItems ?? [] as ICartProduct[] },
	);

	const addToCart = (product: ICartProduct) => {
		if (userAuth) {
			commonApi.cartAdd(product.productId, product.quantity)
				.then((result) => {
					dispatch({
						type: CartActionKind.ADD_TO_CART,
						payload: {
							...product, shoppingCartItemId: result.data.shoppingCartItemId,
						},
					});
				})
				.catch((err) => console.log(err));
		} else {
			dispatch({ type: CartActionKind.ADD_TO_CART, payload: product });
		}
	};

	const removeFromCart = (product: ICartProduct) => {
		if (userAuth) {
			commonApi.cartRemove(product.shoppingCartItemId!)
				.then(() => {
					dispatch({ type: CartActionKind.REMOVE_FROM_CART, payload: product });
				})
				.catch((err) => console.log(err));
		} else {
			dispatch({ type: CartActionKind.REMOVE_FROM_CART, payload: product });
		}
	};

	const incrementProduct = (product: ICartProduct) => {
		if (userAuth) {
			commonApi.cartUpdateQuantity(product.shoppingCartItemId!, product.quantity + 1)
				.then(() => {
					dispatch({ type: CartActionKind.INCREMENT, payload: product });
				})
				.catch((err) => console.log(err));
		} else {
			dispatch({ type: CartActionKind.INCREMENT, payload: product });
		}
	};

	const decrementCart = (product: ICartProduct) => {
		if (userAuth) {
			if (product.quantity === 1) {
				return;
			}
			commonApi.cartUpdateQuantity(product.shoppingCartItemId!, product.quantity - 1)
				.then(() => {
					dispatch({ type: CartActionKind.DECREMENT, payload: product });
				})
				.catch((err) => console.log(err));
		} else {
			dispatch({ type: CartActionKind.DECREMENT, payload: product });
		}
	};

	const clearCart = () => {
		dispatch({ type: CartActionKind.CLEAR, payload: {} as ICartProduct });
	};

	useEffect(() => {
		if (shoppintCart) {
			clearCart();
			shoppintCart.items.forEach((item) => {
				dispatch({
					type: CartActionKind.ADD_TO_CART,
					payload: {
						productId: item.productId,
						shoppingCartItemId: item.shoppingCartItemId,
						quantity: item.quantity,
						priceWithTaxes: item.productPriceWithTaxes,
						barcode: item.productBarcode,
						reference: item.productReference,
						name: item.productName,
						description: item.productDescription,
						image: item.productImageUrl,
						brandImage: item.brandImageUrl,
					} as ICartProduct,
				});
			});
		}
	}, [shoppintCart]);

	const getTotalPrice = React.useCallback(() => cartState.cartProducts.reduce((mTotal, product) => {
		let total = mTotal;
		total += product.priceWithTaxes * product.quantity;
		return total;
	}, 0 as number), [cartState.cartProducts, shoppintCart]);

	const createOrder = (order: OrderCreateParamModel) => {
		setLoading(true);
		if (userAuth) {
			return orderApi.createOrder({
				shoppingCartItemsIds: cartState.cartProducts.map((item) => item.shoppingCartItemId!),
				comments: order.comments,
				shippingAddress: order.shippingAddress,
				billingAddress: order.billingAddress,
				paymentSecret: order.paymentSecret,
				paymentMethod: order.paymentMethod,
				paymentPhoneNumber: order.paymentPhoneNumber,
			}).then((res) => ({ success: true, message: '', orderId: res.data.orderId })).catch((err) => {
				const responseError = err as ApiResponse<void>;
				return { success: false, message: responseError.message, orderId: undefined };
			}).finally(() => {
				setLoading(false);
			});
		}
		return orderApi.createOrderNoAuth({
			...order,
			products: cartState.cartProducts.map((item) => ({
				productId: item.productId,
				price: item.priceWithTaxes,
				quantity: item.quantity,
			})),
		}).then((res) => ({ success: true, message: '', orderId: res.data.orderId })).catch((err) => {
			const responseError = err as ApiResponse<void>;
			return { success: false, message: responseError.message, orderId: undefined };
		}).finally(() => {
			setLoading(false);
		});
	};

	const createOrUpdatePayment = (order: OrderCreateParamModel) => {
		setLoadingPayment(true);
		return orderApi.createOrUpdatePayment({
			...order,
			products: cartState.cartProducts.map((item) => ({
				productId: item.productId,
				price: item.priceWithTaxes,
				quantity: item.quantity,
			})),
		}).then((res) => {
			setPayment(res.data);
			return ({ success: true, message: '' });
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			return { success: false, message: responseError.message };
		}).finally(() => {
			setLoadingPayment(false);
		});
	};

	const createMultibancoPayment = (order: OrderCreateParamModel) => {
		setLoadingPaymentMultibanco(true);
		return orderApi.createMultibancoPayment({
			...order,
			products: cartState.cartProducts.map((item) => ({
				productId: item.productId,
				price: item.priceWithTaxes,
				quantity: item.quantity,
			})),
		}).then((res) => {
			setPaymentMultibanco(res.data);
			return ({ success: true, message: '', paymentId: res.data.id });
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			return { success: false, message: responseError.message, paymentId: undefined };
		}).finally(() => {
			setLoadingPaymentMultibanco(false);
		});
	};

	const getDeliveryEstimate = (districtId: number) => commonApi.getDeliveryEstimate(
		districtId,
		cartState.cartProducts.map((item) => ({
			productId: item.productId,
			price: item.priceWithTaxes,
			quantity: item.quantity,
		})),
	).then((res) => {
		setDeliveryEstimate(res.data);
		return ({ success: true, message: '' });
	}).catch((err) => {
		const responseError = err as ApiResponse<void>;
		return { success: false, message: responseError.message };
	});

	const value = React.useMemo(() => ({
		cartProducts: cartState.cartProducts,
		addToCart,
		removeFromCart,
		incrementProduct,
		decrementCart,
		getTotalPrice,
		createOrder,
		loading,
		createOrUpdatePayment,
		loadingPayment,
		payment,
		createMultibancoPayment,
		paymentMultibanco,
		loadingPaymentMultibanco,
		getDeliveryEstimate,
		deliveryEstimate,
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [cartState.cartProducts, getTotalPrice, loading, loadingPayment, payment, deliveryEstimate]);

	return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export default function useCart() {
	return React.useContext(CartContext);
}
