import { Box, useTheme, SxProps } from '@mui/material';
import React from 'react';

interface Props {
	color?: string;
	width?: string;
	height?: number;
	sx?: SxProps
}

const DefaultDivider: React.FC<Props> = (props) => {
	const theme = useTheme();
	const {
		color = theme.palette.primary.main, width = '280px', height = 1, sx,
	} = props;
	return (
		<Box sx={{
			height: `${height}px`, width, bgcolor: color, ...sx,
		}}
		/>
	);
};

export default DefaultDivider;
