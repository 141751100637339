/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import LocalStorageWrapper, { LocalStorageKeys } from 'common/utils/storage-utils';
import axios, {
	AxiosError, AxiosProgressEvent, AxiosResponse, InternalAxiosRequestConfig,
} from 'axios';
import { ApiResponse } from 'common/domain/entities/api-response';
import { UserModel } from 'features/authentication/data/dto/user-model';
import AppLinks from 'assets/applinks.routes';
import { AppEnv } from 'common/constants/app-env';

const http = axios.create({
	baseURL: `${AppEnv.BASE_URL}store`,
	headers: {
		'Content-type': 'application/json',
	},
});

const doRefreshToken = async (): Promise<string | null> => {
	const authToken = LocalStorageWrapper.get<string>(LocalStorageKeys.AUTH_STORE_TOKEN);
	const refreshToken = LocalStorageWrapper.get<string>(LocalStorageKeys.REFRESH_STORE_TOKEN);

	if (authToken && refreshToken) {
		try {
			const resp = await http.post('/users/refresh-token', {
				authToken,
				refreshToken,
			});
			const data = resp.data as ApiResponse<UserModel>;
			const { authToken: newAuthToken } = data.data;
			LocalStorageWrapper.set(LocalStorageKeys.AUTH_STORE_TOKEN, newAuthToken);
			LocalStorageWrapper.set(LocalStorageKeys.REFRESH_STORE_TOKEN, data.data.refreshToken);

			const user: any = data.data;
			delete user.authToken;
			delete user.refreshToken;
			LocalStorageWrapper.set(LocalStorageKeys.USER_STORE, user);
			return Promise.resolve(newAuthToken);
		} catch (error) {
			return Promise.resolve(null);
		}
	}
	return Promise.resolve(null);
};

const onRequest = async (
	config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
	const key = LocalStorageWrapper.get<string>(LocalStorageKeys.AUTH_STORE_TOKEN);
	const customConfig = config;
	if (key) {
		customConfig.headers.Authorization = `Bearer ${key}`;
	}

	return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
	const originalRequest = error.config;
	if (error.response?.status === 401 || error.response?.status === 403) {
		console.info('refreshing token');
		const result = await doRefreshToken();
		if (result && originalRequest) {
			originalRequest.headers.Authorization = `Bearer ${result}`;
			return http(originalRequest); // request again with the new token
		}

		console.info('refresh token fail');
		LocalStorageWrapper.remove(LocalStorageKeys.USER_STORE);
		LocalStorageWrapper.remove(LocalStorageKeys.AUTH_STORE_TOKEN);
		LocalStorageWrapper.remove(LocalStorageKeys.REFRESH_STORE_TOKEN);

		if (window.location.href.indexOf(AppLinks.CLIENT_AREA) > -1) {
			console.info('redirect to login...');
			window.location.reload();
		}
	}
	return Promise.reject(error);
};

http.interceptors.request.use(onRequest, onRequestError);
http.interceptors.response.use(onResponse, onResponseError);

export const uploadFile = async (
	file: File,
	endpoint: string,
	onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined,
) => {
	const formData = new FormData();
	formData.append('files', file);
	try {
		const resp = await http.post(endpoint, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			onUploadProgress,
		});
		const data = resp.data as ApiResponse<{fileUrl: string}[]>;
		return Promise.resolve(data);
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const errData = err as AxiosError;
			if (errData.response?.data) {
				const data = errData.response?.data as ApiResponse<void>;
				return Promise.reject(data);
			}
		}
		const error = { message: 'unexpected_error' } as ApiResponse<void>;
		return Promise.reject(error);
	}
};

export default http;
