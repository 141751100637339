/* eslint-disable max-len */
import { PaletteMode, ThemeOptions } from '@mui/material';

export const inter = "'Inter', sans-serif";
export const zilaSlab = 'ZillaSlab';

export default (mode: PaletteMode): ThemeOptions => ({
	// CHANGE YOUR APP THEME COLORS BELLOW
	palette: {
		mode,
		primary: {
			main: mode === 'light' ? '#E9E9E9' : '#E9E9E9',
			dark: '#1A4855',
			light: '#a6a6a6',
			contrastText: '#374B54',
		},
		secondary: {
			main: '#B9C92B',
			dark: '#87931f',
			light: '#FAFBD6',
			contrastText: '#fff',
		},
		error: {
			main: '#CC5F5F',
		},
		cardBg: {
			main: '#5C6464',
			light: '#5C6464',
			dark: '#5C6464',
			contrastText: '#fff',
		},
		success: {
			main: '#67E2C2',
			light: '#67E2C2',
			dark: '#67E2C2',
			contrastText: 'rgba(0, 0, 0, 0.7)',
		},
		background: {
			default: mode === 'light' ? '#ffffff' : '#121212',
			paper: mode === 'light' ? '#fff' : '#000000',
		},
		footerbg: {
			main: '#2E3D44',
			light: '#2E3D44',
			dark: '#2E3D44',
			contrastText: '#fff',
		},
		textMain: {
			main: mode === 'light' ? '#374B54' : '#fff',
		},
		textLight: {
			main: '#7B8A8A',
		},
		fieldOutline: {
			main: '#D3D3D3',
			light: '#D3D3D3',
			dark: '#D3D3D3',
			contrastText: '#fff',
		},
		divider: 'rgba(185, 201, 43, 0.2)',
	},
	components: {
		MuiRadio: {
			styleOverrides: {
				root: {
					'&.Mui-checked': {
						color: '#666262',
					},
				},
			},
		},
	},
	// CHANGE YOUR SHADOW COLORS BELLOW

	// shadows: [
	// 	'none',
	// 	'0px 2px 1px -1px rgba(15, 198, 154,0.2),0px 1px 1px 0px rgba(15, 198, 154,0.14),0px 1px 3px 0px rgba(15, 198, 154,0.12)',
	// 	'0px 3px 1px -2px rgba(15, 198, 154,0.2),0px 2px 2px 0px rgba(15, 198, 154,0.14),0px 1px 5px 0px rgba(15, 198, 154,0.12)',
	// 	'0px 3px 3px -2px rgba(15, 198, 154,0.2),0px 3px 4px 0px rgba(15, 198, 154,0.14),0px 1px 8px 0px rgba(15, 198, 154,0.12)',
	// 	'0px 2px 4px -1px rgba(15, 198, 154,0.2),0px 4px 5px 0px rgba(15, 198, 154,0.14),0px 1px 10px 0px rgba(15, 198, 154,0.12)',
	// 	'0px 3px 5px -1px rgba(15, 198, 154,0.2),0px 5px 8px 0px rgba(15, 198, 154,0.14),0px 1px 14px 0px rgba(15, 198, 154,0.12)',
	// 	'0px 3px 5px -1px rgba(15, 198, 154,0.2),0px 6px 10px 0px rgba(15, 198, 154,0.14),0px 1px 18px 0px rgba(15, 198, 154,0.12)',
	// 	'0px 4px 5px -2px rgba(15, 198, 154,0.2),0px 7px 10px 1px rgba(15, 198, 154,0.14),0px 2px 16px 1px rgba(15, 198, 154,0.12)',
	// 	'0px 5px 5px -3px rgba(15, 198, 154,0.2),0px 8px 10px 1px rgba(15, 198, 154,0.14),0px 3px 14px 2px rgba(15, 198, 154,0.12)',
	// 	'0px 5px 6px -3px rgba(15, 198, 154,0.2),0px 9px 12px 1px rgba(15, 198, 154,0.14),0px 3px 16px 2px rgba(15, 198, 154,0.12)',
	// 	'0px 6px 6px -3px rgba(15, 198, 154,0.2),0px 10px 14px 1px rgba(15, 198, 154,0.14),0px 4px 18px 3px rgba(15, 198, 154,0.12)',
	// 	'0px 6px 7px -4px rgba(15, 198, 154,0.2),0px 11px 15px 1px rgba(15, 198, 154,0.14),0px 4px 20px 3px rgba(15, 198, 154,0.12)',
	// 	'0px 7px 8px -4px rgba(15, 198, 154,0.2),0px 12px 17px 2px rgba(15, 198, 154,0.14),0px 5px 22px 4px rgba(15, 198, 154,0.12)',
	// 	'0px 7px 8px -4px rgba(15, 198, 154,0.2),0px 13px 19px 2px rgba(15, 198, 154,0.14),0px 5px 24px 4px rgba(15, 198, 154,0.12)',
	// 	'0px 7px 9px -4px rgba(15, 198, 154,0.2),0px 14px 21px 2px rgba(15, 198, 154,0.14),0px 5px 26px 4px rgba(15, 198, 154,0.12)',
	// 	'0px 8px 9px -5px rgba(15, 198, 154,0.2),0px 15px 22px 2px rgba(15, 198, 154,0.14),0px 6px 28px 5px rgba(15, 198, 154,0.12)',
	// 	'0px 8px 10px -5px rgba(15, 198, 154,0.2),0px 16px 24px 2px rgba(15, 198, 154,0.14),0px 6px 30px 5px rgba(15, 198, 154,0.12)',
	// 	'0px 8px 11px -5px rgba(15, 198, 154,0.2),0px 17px 26px 2px rgba(15, 198, 154,0.14),0px 6px 32px 5px rgba(15, 198, 154,0.12)',
	// 	'0px 9px 11px -5px rgba(15, 198, 154,0.2),0px 18px 28px 2px rgba(15, 198, 154,0.14),0px 7px 34px 6px rgba(15, 198, 154,0.12)',
	// 	'0px 9px 12px -6px rgba(15, 198, 154,0.2),0px 19px 29px 2px rgba(15, 198, 154,0.14),0px 7px 36px 6px rgba(15, 198, 154,0.12)',
	// 	'0px 10px 13px -6px rgba(15, 198, 154,0.2),0px 20px 31px 3px rgba(15, 198, 154,0.14),0px 8px 38px 7px rgba(15, 198, 154,0.12)',
	// 	'0px 10px 13px -6px rgba(15, 198, 154,0.2),0px 21px 33px 3px rgba(15, 198, 154,0.14),0px 8px 40px 7px rgba(15, 198, 154,0.12)',
	// 	'0px 10px 14px -6px rgba(15, 198, 154,0.2),0px 22px 35px 3px rgba(15, 198, 154,0.14),0px 8px 42px 7px rgba(15, 198, 154,0.12)',
	// 	'0px 11px 14px -7px rgba(15, 198, 154,0.2),0px 23px 36px 3px rgba(15, 198, 154,0.14),0px 9px 44px 8px rgba(15, 198, 154,0.12)',
	// 	'0px 11px 15px -7px rgba(15, 198, 154,0.2),0px 24px 38px 3px rgba(15, 198, 154,0.14),0px 9px 46px 8px rgba(15, 198, 154,0.12)',
	// ],

	// CHANGE YOUR APP FONT BELLOW

	typography: {
		fontFamily: zilaSlab,
	},
});
