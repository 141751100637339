import React from 'react';
import CartPage from './CartPage';

const Cart = () => (
	<main>
		<CartPage />
	</main>
);

export default Cart;
