/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { ApiResponse } from 'common/domain/entities/api-response';
import { ProductModel } from '../dto/product-model';
import { FilterParams, ProductApi } from './product-api';
import { BrandModel } from '../dto/brand-model';
import { serializeFormQuery } from 'common/utils/general.utils';
import { ProductCategoryModel } from '../dto/product-category-model';

export class ProductApiImpl implements ProductApi {
	async getProductById(id: number): Promise<ProductModel> {
		try {
			const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}store/products/${id}`);
			const data = resp.data as ApiResponse<ProductModel>;
			return data.data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	async getProductsByFilter(params: FilterParams): Promise<ApiResponse<ProductModel[]>> {
		try {
			const query = serializeFormQuery(params);
			const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}store/products${query}`);
			const { data } = resp;
			return data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	async getBrands(): Promise<BrandModel[]> {
		try {
			const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}store/brands`);
			const data = resp.data as ApiResponse<BrandModel[]>;
			console.log(data);
			return data.data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	async getCategories(): Promise<ProductCategoryModel[]> {
		try {
			const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}store/products/categories`);
			const data = resp.data as ApiResponse<ProductCategoryModel[]>;
			return data.data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
