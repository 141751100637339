import Dimens from 'assets/dimens';
import { Box } from '@mui/material';
import React from 'react';

export const TermsAndConditionsOfUse = () => (
	<Box justifyContent="center" display="flex" sx={{ padding: '16px', mb: 16 }}>
		<Box maxWidth={Dimens.PAGE_MAX_WIDTH} flex={1}>
			<h2>
				Termos e condições de uso
			</h2>

			<section id="content" className="page-content page-cms page-cms-1 col-xs-12 col-sm-12">
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<span>
						A
						VIRGÍLIO &amp; OLIVEIRA, LDA, adiante designada por VOLT, sedeada em
						Travessa do Pisão Edifício DC1, Parceiros, 2400-473 Leiria, com o número
						de contribuinte 500 784 531, responsável por este site (
						<a
							href="https://www.volt.pt/"
						>
							<span>www.volt.pt</span>
						</a>
						) descreve
						nesta página os termos e condições de utilização e a informação
						pré-contratual relativa aos contratos celebrados à distância neste site
						(
						<a href="https://www.volt.pt/"><span>www.volt.pt</span></a>
						), a que
						alude o artigo 4.º do Decreto-Lei n.º 24/2014 de 14 de Fevereiro.
					</span>
				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<span>
						A
						VOLT reserva-se ao direito de modificar livremente a informação
						pré-contratual de modo a adequá-la à legislação aplicável, sem
						necessidade de qualquer pré-aviso.
						<br />
					</span>

				</p>
				<ul style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<li>• Condições de Utilização do Site</li>
					<li>• Política de Proteção de Dados</li>
					<li>• Política de Preços</li>
					<li>• Métodos de Pagamento</li>
					<li>• Expedição de Encomendas e Portes</li>
					<li>• Avarias e Garantias</li>
					<li>• Política de Devoluções</li>
					<li>• Política de Cookies</li>
					<li>• Vírus</li>
					<li>• Resolução Online de Litígios</li>
				</ul>
				<p style={{ fontSize: '16px', fontWeight: '500', color: '#b9c92b' }}>
					1. Condições de
					Utilização do Site

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					Tanto a
					navegação por este site como a aquisição de qualquer produto ou serviço
					disponibilizado pelo mesmo pressupõe a aceitação das presentes informações e
					Condições Gerais de Utilização constantes nesta página. O utilizador
					compromete-se a utilizar este site em cumprimento com o disposto na
					legislação aplicável, abstendo-se de utilizar o site para atividades
					contrárias à lei, à moral e bons costumes ou direitos e interesses de
					terceiros.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					Este
					site pode possuir ligação a outros sites, os quais poderão conter
					informações ou ferramentas úteis para os nossos utilizadores, contundo as
					presentes Condições Gerais não serão aplicadas a esses sites terceiros. Como
					tal, o utilizador deve informar-se sobre os Termos e Condições de Uso do
					site para o qual foi redirecionado.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					A VOLT
					reserva-se ao direito de modificar unilateralmente e em qualquer momento,
					sem aviso prévio, a apresentação e conteúdo deste site, os seus serviços e
					as Condições Gerais de Utilização, ressalvando-se os contratos já celebrados
					e em execução. Estas alterações têm como finalidade a melhoria contínua
					deste site assim como dos serviços oferecidos ao utilizador que caso não
					concorde com as alterações efetuadas, poderá resolver o contrato.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					O
					conteúdo e informação criados para apresentação neste site é propriedade da
					VOLT, assim a sua utilização, reprodução, cópia ou divulgação por outros
					meios de logotipos, textos, imagens e vídeos constantes no site estão
					sujeitos à sua autorização prévia.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					A VOLT
					tem em consideração a informação relativa às características essenciais dos
					produtos mediante descrições técnicas partilhadas pelos seus fornecedores e
					de fotografias que ilustram os produtos comercializados. Como tal, as
					imagens dos produtos apresentadas no site são meramente ilustrativas. A VOLT
					recomenda que o cliente consulte a descrição detalhada do produto de modo a
					obter a informação completa acerca das respetivas características do mesmo,
					ou que entre em contacto com o Apoio ao Cliente.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					Tendo
					em conta que também são comercializados produtos usados podem existir
					diferenças entre a imagem e o produto em si, devido ao uso e historial do
					mesmo, que é singular em cada equipamento.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					São
					efetuados todos os esforços para que a informação aqui apresentada se
					encontre isenta de erros tipográficos e de imagem. Sempre que estes ocorram,
					a VOLT procederá logo que possível à respetiva correção dos mesmos.

				</p>
				<p style={{ fontSize: '16px', fontWeight: '500', color: '#b9c92b' }}>
					2. Política de Proteção
					de Dados

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					A VOLT
					está empenhada em respeitar a privacidade dos seus utilizadores, por esse
					motivo estruturámos o nosso site de modo a que seja possível navegar sem
					recurso à introdução dos dados pessoais. Quando for necessário recolher
					dados pessoais dos utilizadores para disponibilizar os nossos serviços ou
					quando este decidir partilhar os seus dados por iniciativa própria, a
					utilização dessa informação será realizada de acordo com a legislação em
					vigor – Regulamento Geral de Proteção de Dados (RGPD). Os dados partilhados
					pelos utilizadores estarão sujeitos a tratamento informático e constatarão
					na base de dados da VOLT.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					Quando
					o utilizador optar por nos fornecer informações relativas aos seus dados
					pessoais (quaisquer informações através das quais possa ser identificado),
					pode estar seguro de que essas informações só serão utilizadas para efeitos
					da sua relação enquanto cliente da VOLT.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					No
					planeamento deste site, adotámos medidas que consideramos ser as mais
					adequadas para assegurar a exatidão, integridade e confidencialidade dos
					dados pessoais dos utilizadores, bem como todos os demais direitos que lhe
					assistem. Os nossos registos incluem dados que foram obtidos ao longo da
					relação contratual que estabeleceu connosco e na sequência das diversas
					interações realizadas com o nosso site.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					Os
					utilizadores devem garantir a&nbsp;veracidade, exatidão, autenticidade e
					vigência dos dados de carácter pessoal que tenham fornecido à VOLT.

				</p>
				<ul style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<li>
						<strong>• Tipo de dados recolhidos através do nosso site:&nbsp;</strong>
						<br />
						Nome Completo; Número de Identificação Fiscal; género; Data de
						Nascimento; Contacto Telefónico, Morada Completa (de envio e/ou de
						faturação, caso estas sejam diferentes); Correio Eletrónico; Nome da
						Empresa.

					</li>
					<li>
						<strong>• Como utilizamos a informação recolhida:&nbsp;</strong>
						<br />
						A
						informação recolhida tem como finalidade a resposta a pedidos feitos
						pelos utilizadores, nomeadamente registo no site; processamento de
						encomendas (abertura de ficha de cliente e faturação dos
						produtos/serviços); subscrição voluntária do serviço de email marketing;
						respostas a pedidos de informação, sugestão ou reclamação; e comunicação
						com os clientes.

					</li>
					<li>
						<strong>• Com quem partilhamos os dados: </strong>
						<br />
						A VOLT assegura a
						confidencialidade dos dados pessoais fornecidos e só os transmite a
						terceiros com única e exclusiva finalidade a realização do serviço
						solicitado pelo utilizador, nomeadamente: envio de encomendas para a
						morada fornecida (transportadora); tratamento de garantias e/ou
						devolução de produtos (fornecedores); realização de contratos de
						serviços adquiridos (operadoras); e a autoridades judiciais e fiscais
						para cumprimento de imposições legais.

					</li>
					<li>
						<strong>• Proprietário e Controlador de Dados:&nbsp;</strong>
						<br />
						No
						âmbito e para efeitos dos serviços e vendas que prestamos aos nossos
						utilizadores, a VOLT (VIRGÍLIO &amp; OLIVEIRA, Lda) é a entidade
						responsável pelo tratamento dos seus dados pessoais e por assegurar a
						proteção da sua privacidade, atuando em conformidade com a legislação em
						vigor – Regulamento Geral de Proteção de Dados (RGPD).

					</li>
					<li>
						<strong>• Período de Conservação:&nbsp;</strong>
						<br />
						A VOLT conservará os
						dados recolhidos até requisição de esquecimento dos mesmos, por parte do
						titular dos dados.

					</li>
					<li>
						<strong>
							• Direito de aceder, alterar e remover os seus dados
							pessoais:&nbsp;
						</strong>
						<br />
						Em qualquer momento, o utilizador tem o
						direito de aceder aos seus dados pessoais, bem como, dentro dos limites
						do contratualizado e do RGPD, de as alterar, opor-se ao respetivo
						tratamento, decidir sobre o tratamento autorizado dos mesmos, retirar o
						consentimento e exercer os demais direitos previstos na lei (salvo
						quando os dados são indispensáveis para a respetiva faturação por parte
						da VOLT e ao cumprimento das obrigações legais que a VOLT está sujeita)
						Caso retire o seu consentimento, isto não compromete a licitude do
						tratamento efetuado até essa data. Tem o direito de ser notificado, nos
						termos previstos no Regulamento, caso ocorra uma violação dos seus dados
						pessoais, podendo apresentar reclamações perante a(s) autoridade(s).
					</li>
					<li>
						<strong>• Segurança:&nbsp;</strong>
						<br />
						A VOLT está empenhada em
						assegurar a segurança da informação que o utilizador partilha connosco.
						Para impedir o acesso ou a publicidade não autorizados, bem como manter
						a precisão dos dados e assegurar a correta utilização da informação
						implementámos procedimentos físicos, eletrónicos e de gestão para
						proteger a informação que recolhemos online. Utilizamos encriptação
						quando recolhemos ou transferimos dados sensíveis.

					</li>
					<li>
						<strong>
							• Contacto do Encarregado da Proteção de
							Dados:&nbsp;
						</strong>
						info@volt.pt

					</li>
				</ul>
				<p style={{ fontSize: '16px', fontWeight: '500', color: '#b9c92b' }}>
					3. Política de Preços
				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<span>
						Os preços dos produtos e serviços disponibilizados neste site são
						exclusivos para as aquisições realizadas por esta via. Estes são
						apresentados em euros e com o valor do IVA incluído.
					</span>

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					No caso
					da ocorrência de um erro informático, manual, técnico ou de qualquer outra
					origem que cause uma alteração notória e não prevista pela VOLT no preço de
					venda ao público, de tal modo que este se torne pouco acessível ou
					manifestamente irrisório, o pedido de compra irá ser considerado inválido e
					anulado.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					A VOLT
					reserva-se ao direito de alterar os preços em qualquer momento. No entanto,
					a VOLT compromete-se a aplicar as taxas e preços indicados no momento da
					realização do pedido de compra.&nbsp;

				</p>
				<p style={{ fontSize: '16px', fontWeight: '500', color: '#b9c92b' }}>
					4. Métodos de Pagamento
				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					A VOLT
					disponibiliza diversas opções de Pagamento:

				</p>
				<ul style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<li>
						<strong>
							• Referência Multibanco:&nbsp;
							<br />
						</strong>
						Os pagamentos por
						Referência Multibanco podem ser efetuados pelo Homebanking ou pela Rede
						de Caixas Multibanco. No final da sua encomenda, será gerada a
						referência multibanco que será enviada para o seu email. Para efetuar o
						pagamento tem de selecionar a opção “Pagamentos de Serviços/Compras” e
						inserir a entidade, referência e valor correspondente que foram enviados
						por email.

					</li>
					<li>
						<strong>
							• Transferência Bancária:&nbsp;
							<br />
						</strong>
						Se escolher o
						pagamento por Transferência Bancária, poderá consultar os dados para
						realizar a transferência no ato da encomenda ou no seu email. A sua
						encomenda será enviada após a confirmação do pagamento, que dependendo
						dos bancos pode demorar até dois dias úteis.

					</li>
					<li>
						<strong>
							• Paypal:&nbsp;
							<br />
						</strong>
						O pagamento por&nbsp;
						<a
							href="https://paypal.pt/"
						>
							Paypal
						</a>
						&nbsp;é um método seguro
						utilizado em todo o mundo. Ao selecionar este meio de pagamento, o
						utilizador será redirecionado para a página de Login do Paypal. O valor
						da compra será debitado da conta Paypal, acrescido de 3% que corresponde
						às taxas aplicadas por este meio de pagamento. A VOLT não guarda os
						dados de acesso à conta Paypal.

					</li>
					{/* <li>
						<strong>
							• Mbway:&nbsp;
							<br />
						</strong>
						Com o Mbway poderá pagar a sua
						encomenda através do seu número de telemóvel, sem custos adicionais.
						Deverá ter a aplicação ativa no seu telemóvel para poder efetuar o
						pagamento por esta via. Para obter mais informações sobre este meio de
						pagamento,&nbsp;
						<a
							href="https://www.mbway.pt/perguntas-frequentes/"
						>
							clique aqui.
						</a>
					</li>
					<li>
						<strong>
							• Payshop:&nbsp;
							<br />
						</strong>
						Para efetuar o pagamento da
						encomenda por Payshop, deve dirigir-se a um dos agentes Payshop (
						<a
							href="http://www.payshop.pt/fepsapl/app/open/showSearchAgent.jspx"
						>
							ver
							aqui
						</a>
						), indicar a referência que obteve com a encomenda e fazer o
						respetivo pagamento.

					</li> */}
				</ul>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					A VOLT
					guarda-se o direito de alterar as formas de pagamento sem aviso próprio

				</p>
				<p />
				<p style={{ fontSize: '16px', fontWeight: '500', color: '#b9c92b' }}>
					5. Política de Cookies
				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<strong>
						O que são cookies?&nbsp;
						<br />
					</strong>
					“Cookies” são pequenas
					etiquetas de software que são armazenadas nos equipamentos de acesso através
					do navegador (browser), retendo apenas informação relacionada com as
					preferências, não incluindo, como tal, os dados pessoais.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<strong>
						Para que servem os Cookies?&nbsp;
						<br />
					</strong>
					Os cookies servem para
					ajudar a determinar a utilidade, interesse e o número de utilizações dos
					sites, permitindo uma navegação mais rápida e eficiente, eliminando a
					necessidade de introduzir repetidamente as mesmas informações./p&gt;

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<strong>
						Que tipo de cookies existem?
						<br />
					</strong>
					Existem dois grupos cookies
					que podem ser utilizados

				</p>
				<ul style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<li>
						<strong>• Cookies permanentes</strong>
						&nbsp;– são cookies que ficam
						armazenados ao nível do browser nos equipamentos de acesso (PC, mobile e
						tablet) e que são utilizados sempre que faz uma nova visita ao site da
						VOLT. São utilizados, geralmente, para direcionar a navegação aos
						interesses do utilizador, permitindo prestar um serviço mais
						personalizado.

					</li>
					<li>
						<strong>• Cookies de sessão</strong>
						&nbsp;– são cookies temporários que
						permanecem no arquivo de cookies do browser até sair do site. A
						informação obtida por estes cookies serve para analisar padrões de
						tráfego na web, permitindo identificar problemas e fornecer uma melhor
						experiência de navegação.

					</li>
				</ul>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<strong>Para que fins utilizamos cookies?</strong>

				</p>
				<ul style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<li>
						<strong>• Cookies estritamente necessários&nbsp;</strong>
						– Permitem a
						navegação no site e utilização das aplicações, bem como aceder a áreas
						seguras do site. Sem estes cookies, os serviços requeridos não podem ser
						prestados.

					</li>
					<li>
						<strong>• Cookies analíticos&nbsp;</strong>
						– São utilizados anonimamente
						para efeitos de criação e análise de estatísticas, no sentido de
						melhorar o funcionamento do site.

					</li>
					<li>
						<strong>• Cookies de funcionalidade&nbsp;</strong>
						– Guardam as
						preferências do utilizador relativamente à utilização do site, para que
						não seja necessário voltar a configurar o site cada vez que o visita.
					</li>
					<li>
						<strong>• Cookies de terceiros&nbsp;</strong>
						– Medem o sucesso de
						aplicações e a eficácia da publicidade de terceiros. Podem também ser
						utilizados no sentido de personalizar um widget com dados do utilizador.
					</li>
					<li>
						<strong>• Como pode gerir os cookies?&nbsp;</strong>
						Todos os browsers
						permitem ao utilizador aceitar, recusar ou apagar cookies, e ainda
						informar o utilizador sempre que um cookie é recebido, nomeadamente
						através da seleção das definições apropriadas no respetivo navegador. O
						utilizador pode configurar os cookies no menu “opções” ou “preferências”
						do seu browser.

					</li>
				</ul>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					Note-se, no entanto, que, ao desativar cookies, pode impedir que alguns
					serviços da web funcionem corretamente, afetando, parcial ou totalmente, a
					navegação no site.

				</p>
				<p style={{ fontSize: '16px', fontWeight: '500', color: '#b9c92b' }}>6. Vírus</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<span>
						A
						VOLT não é responsável pelos prejuízos decorrentes de quaisquer vírus
						informáticos ou quaisquer outras situações a que seja alheia e que
						impeçam o acesso e correto funcionamento deste site bem como dos
						serviços disponibilizados no mesmo.&nbsp;
					</span>

				</p>
				<p style={{ fontSize: '16px', fontWeight: '500', color: '#b9c92b' }}>
					7. Resolução Online de
					Litígios

				</p>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					A
					resolução alternativa de litígios é a possibilidade que todos os
					consumidores têm ao seu dispor. Podem recorrer a entidades oficiais que os
					ajudem na resolução ou orientação de algum conflito antes de abrirem
					processos litigiosos nos Tribunais. Em caso de litígio de consumo, o
					consumidor pode recorrer à seguinte entidade de resolução alternativa de
					litígios de consumo:

				</p>
				<ul style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					<li>
						• Centro Nacional de Informação e Arbitragem de Conflitos de Consumo
					</li>
					<li>
						• Telefone: 213 847 484&nbsp;
						<em>(Chamada para rede fixa nacional)</em>
					</li>
					<li>• E-mail:&nbsp;cniacc@uni.pt</li>
					<li>• Website:&nbsp;https://www.cniacc.pt/pt/</li>
				</ul>
				<p style={{
					fontSize: '14px', fontWeight: '400', color: '#585858', lineHeight: '1.6',
				}}
				>
					Para
					mais informações, consulte o Portal do Consumidor em&nbsp;
					<a
						href="http://www.consumidor.pt/"
					>
						www.consumidor.pt
					</a>
					<br />
					(ao abrigo do
					artigo 18.º da Lei n.º 144/2015, de 8 de setembro)&nbsp;

				</p>
			</section>
		</Box>
	</Box>
);
