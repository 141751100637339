import React from 'react';
import { Button, useTheme } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

interface Props {
	onClick: () => void
}

const BackButton: React.FC<Props> = (props) => {
	const { onClick } = props;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const theme = useTheme();
	return (
		<Button
			variant="text"
			startIcon={<ArrowBackIos />}
			onClick={onClick}
			color="secondary"
		>
			{'Voltar'.toUpperCase()}
		</Button>
	);
};

export default BackButton;
