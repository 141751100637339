import React from 'react';
import LandingHero from './LandingHero';
import ServicesSection from './ServicesSection';
import ProductSelection from './ProductSelection';
import { ProductProvider } from '../../products/presentation/provider/product.provider';

const LandingPage = () => (
	<main>
		<ProductProvider>
			<LandingHero />
			<ServicesSection />
			<ProductSelection />
		</ProductProvider>
	</main>
);

export default LandingPage;
