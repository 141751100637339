/* eslint-disable class-methods-use-this */
import { AxiosProgressEvent } from 'axios';
import {
	UserCreateModel, UserModel, UserReadModel, UserUpdateModel,
} from '../dto/user-model';
import { AuthApi } from './auth-api';
import storeHttp, { uploadFile as upload } from 'common/data/remote/store-http';
import { ApiResponse } from 'common/domain/entities/api-response';
import { httpGet, httpPatch, httpPost } from 'common/utils/http.utils';

export class AuthApiImpl implements AuthApi {
	apiService = storeHttp;

	async loginUser(username: string, password: string): Promise<ApiResponse<UserModel>> {
		return httpPost('/users/authenticate', {
			username,
			password,
		}, this.apiService);
	}

	async createUser(user: UserCreateModel): Promise<ApiResponse<UserReadModel>> {
		return httpPost('/users', user, this.apiService);
	}

	async updateUser(user: UserUpdateModel): Promise<ApiResponse<UserReadModel>> {
		return httpPatch('/users', user, this.apiService);
	}

	async getUserInfo(): Promise<ApiResponse<UserReadModel>> {
		return httpGet('/users/info', this.apiService);
	}

	async uploadFile(
		file: File,
		onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined,
	): Promise<ApiResponse<{fileUrl: string}[]>> {
		return upload(file, '/files/images', onUploadProgress);
	}
}
