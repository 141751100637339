import {
	Checkbox, FormControlLabel, Stack,
} from '@mui/material';
import React from 'react';
import { Brand } from '../../domain/entities/Brand';
import { BrandMenuItem } from './BrandMenyItem';

type BrandComponentProsp = {
	brands: Brand[]
	brandSelected: number[]
	materialSelected: number[]
	colorSelected: number[]
	onBrandSelected: (value: number[]) => void
	onColorSelected: (colors: number[]) => void
}

export const BrandComponent: React.FC<BrandComponentProsp> = (props) => {
	const {
		brands,
		brandSelected,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		materialSelected, colorSelected,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onBrandSelected, onColorSelected,
	} = props;

	return (
		<Stack>
			{brands.map((el, key) => (
				<Stack key={`brands-${key.toString()}`}>
					<FormControlLabel
						checked={brandSelected.indexOf(el.id) !== -1}
						control={(
							<Checkbox
								onChange={(item) => {
									if (item.target.checked) {
										brandSelected.push(el.id);
									} else {
										const index = brandSelected.indexOf(el.id);
										if (index !== -1) {
											brandSelected.splice(index, 1);
										}
									}
									onBrandSelected(brandSelected);
								}}
							/>
						)}
						label={el.name}
					/>
					{el.materials.length !== 0 && el.materials.map((material) => (

						<BrandMenuItem
							key={`brand-${material.id.toString()}`}
							item={material}
							selectedColors={colorSelected}
							onColorsChange={onColorSelected}
						/>
					))}

				</Stack>
			))}
		</Stack>
	);
};
