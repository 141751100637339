import {
	Slide, Stack, SxProps, Theme,
} from '@mui/material';

import React from 'react';
import TitleSmall from '../../../../common/presentation/components/title-small/TitleSmall';
import { ProductCategoryModel } from '../../data/dto/product-category-model';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CategoryMenuItem } from './CategoryMenuItem';

interface Props {
	sx?: SxProps<Theme>,
	selected?: number;
	categories: ProductCategoryModel[],
	onClick?: (value: number | undefined) => void;
}

export const searchParentTree = (tree: ProductCategoryModel[], nodeId?: number) => {
	if (!nodeId) return undefined;
	let item: ProductCategoryModel | undefined;
	for (let index = 0; index < tree.length; index += 1) {
		const parent = tree[index];
		if (parent.productCategoryId === nodeId) {
			item = parent;
			break;
		} else if (parent.childrenCategories && parent.childrenCategories.length > 0) {
			const result = searchParentTree(parent.childrenCategories, nodeId);
			if (result !== undefined) {
				item = parent;
			}
		}
	}
	return item;
};

export const createBreadCrumb = (
	tree: ProductCategoryModel[],
	nodeId?: number | undefined,
): ProductCategoryModel[] => {
	let bread: ProductCategoryModel[] = [];
	const result = searchParentTree(tree, nodeId);
	if (result) {
		bread.push(result);
		if (result !== undefined && result.productCategoryId !== nodeId) {
			const selectedChild = createBreadCrumb(result.childrenCategories, nodeId);
			bread = [...bread, ...selectedChild];
		}
	}
	return bread;
};

export const CategoryMenu: React.FC<Props> = ({
	sx,
	selected,
	categories,
	onClick,
}) => {
	const [category, setCategory] = React.useState<ProductCategoryModel | undefined>();

	React.useEffect(() => {
		const result = searchParentTree(categories, selected);
		setCategory(result);
		if (result !== undefined && result.productCategoryId !== selected) {
			// const selectedChild = searchParentTree(result.childrenCategories, selected);
			// setExpanded(`panel-${selectedChild?.productCategoryId}`);
		}
	}, [categories, selected]);

	return (
		<Stack sx={sx}>
			<Stack onClick={() => onClick?.call(0, undefined)} direction="row" justifyItems="center" gap={1}>
				{category && (
					<ArrowBackIcon onClick={() => onClick?.call(0, undefined)} />
				)}
				<TitleSmall sx={{
					borderBottom: '#E9E9E9 solid 1px', pb: 2, mb: 2, textTransform: 'uppercase',
				}}
				>
					{category?.name}
				</TitleSmall>
			</Stack>
			<Slide direction="right" in={!!category}>
				<div>
					{category?.childrenCategories?.map((cat) => (
						<CategoryMenuItem
							key={`cat_${cat.productCategoryId.toString()}`}
							category={cat}
							selected={selected}
							onClick={onClick}
						/>
					))}
				</div>
			</Slide>

			<Slide direction="right" in={!category} unmountOnExit>
				<Stack gap={0.5}>
					{categories.map((cat) => (
						<Stack
							key={`cat_${cat.productCategoryId.toString()}`}
							sx={{
								background: '#F5F5F5',
								p: 1.5,
								textTransform: 'uppercase',
								color: '#374B54',
								fontWeight: '700',
								fontSize: '14px',
								cursor: 'pointer',
							}}
							direction="row"
							justifyContent="space-between"
							onClick={() => onClick?.call(0, cat.productCategoryId)}
						>
							{cat.name}

							<KeyboardArrowRightIcon />
						</Stack>
					))}
				</Stack>
			</Slide>
		</Stack>
	);
};
