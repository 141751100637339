import { zilaSlab } from 'assets/theme/theme';
import {
	Stack, SvgIcon, SxProps, Theme,
} from '@mui/material';
import React from 'react';
import BodyText from '../body-text/BodyText';

interface ListItemProps {
	title: string;
	icon: React.ReactNode
	onClick?: () => void
	sx?: SxProps<Theme>
}

const ListItemWithIcon: React.FC<ListItemProps> = (props) => {
	const {
		title, icon, onClick, sx = {},
	} = props;
	return (
		<Stack direction="row" sx={{ mt: 1.5, ...sx }} onClick={onClick}>
			<SvgIcon>
				{icon}
			</SvgIcon>
			<BodyText sx={{ ml: 1, fontFamily: zilaSlab }}>{title}</BodyText>
		</Stack>
	);
};

export default ListItemWithIcon;
