import React from 'react';
import LoginPage from './LoginPage';

const Login = () => (
	<section>
		<LoginPage />
	</section>
);

export default Login;
