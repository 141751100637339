/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box, CircularProgress, Grid, Stack, SvgIcon, Typography, styled, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import {
	AtField, Call, CellField, Mail, PersonField,
} from 'assets/icons';
import {
	BodyText, DefaultButton, DefaultDropdown, SizedBox, TitleLarge,
} from 'common/presentation/components';
import TextField from 'common/presentation/components/text-field/TextField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Validation } from 'common/domain/entities/validation';
import useContactContext from './provider/contact.provider';
import { Toast } from 'primereact/toast';
import useNotificationsContext from 'common/presentation/providers/notifications.provider';

type Props = {
  validation: Validation
}

const ContactUsSection:React.FC<Props> = ({ validation }) => {
	const {
		subjects, loading, getSujects, createSupport,
	} = useContactContext();
	const { toast } = useNotificationsContext();

	const SmallText = styled(Typography)(({ theme }) => ({
		fontSize: Dimens.FONT_SIZE_EXTRA_SMALL,
		color: theme.palette.textMain.main,
	}));
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const [state, setState] = React.useState({
		isFormValid: true,
		mainError: '',
		subjectId: -1,
		subjectError: '',
		email: '',
		name: '',
		emailError: '',
		nameError: '',
		phone: '',
		phoneError: '',
		message: '',
		messageError: '',
	});

	React.useEffect(() => {
		getSujects();
	}, []);

	const clearForm = () => {
		setState((prev) => ({
			...prev,
			isFormValid: true,
			mainError: '',
			subjectId: -1,
			subjectError: '',
			email: '',
			name: '',
			emailError: '',
			nameError: '',
			phone: '',
			phoneError: '',
			message: '',
			messageError: '',
		}));
	};

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const {
			email, phone, name, message,
		} = state;
		const formData = {
			email, phone, name, message,
		};
		const emailError = validation.validate('email', formData);
		const nameError = validation.validate('name', formData);
		const phoneError = validation.validate('phone', formData);
		const messageError = validation.validate('message', formData);
		let subjectError = '';
		if (state.subjectId === -1) {
			subjectError = 'required';
		}
		const isFormValid = !(!!emailError
		|| !!nameError || !!phoneError || !!messageError || !!subjectError);
		setState({
			...state,
			emailError,
			nameError,
			phoneError,
			messageError,
			subjectError,
			isFormValid,
		});

		if (isFormValid) {
			createSupport({
				senderFullName: name,
				senderEmail: email,
				senderPhoneNumber: phone,
				content: message,
				subjectId: state.subjectId,
			}).then((didSuccess) => {
				if (didSuccess) {
					toast.current?.show({
						severity: 'success',
						summary: 'Sucesso',
						detail: 'Aguarde o contato da nossa equipa.',
					});
					clearForm();
				} else {
					toast.current?.show({
						severity: 'error',
						summary: 'Falha',
						detail: 'Uma ou mais informação não foi possível ser salva.',
					});
				}
			});
		}
		event.preventDefault();
		return false;
	};

	const onBlurValidation = (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
	) => {
		const {
			email, phone, name, message,
		} = state;
		const formData = {
			email, phone, name, message,
		};
		const fieldError = validation.validate(event.target.name, formData);
		setState({
			...state,
			[`${event.target.name}Error`]: fieldError,
		});
	};

	return (
		<Stack
			direction={{ md: 'row', xs: 'column' }}
			spacing={3}
			justifyContent="space-between"
			alignItems="center"
			sx={{
				pl: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },
				pr: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },
				pb: Dimens.SECTION_PADDING_BOTTOM,
				mt: { md: 4, sm: 8, xs: 8 },
			}}
		>
			<Toast ref={toast} />
			<Box sx={{ width: { md: '50%', xs: '100%' } }}>
				<form onSubmit={onSubmit}>
					<TitleLarge>
						{t('do_you_have_questions')}
					</TitleLarge>
					<TitleLarge>
						{t('contact_us')}
					</TitleLarge>
					<SizedBox height={3} />
					<BodyText>
						{t('contact_us_description')}
					</BodyText>
					<SizedBox height={5} />
					<DefaultDropdown
						label={t('interested_in')}
						value={state.subjectId.toString()}
						error={!!state.subjectError}
						options={[{ name: 'Escolha o assunto que gostaria de esclarecer', supportMessageSubjectId: -1 }, ...subjects]}
						renderItem={(item) => ({
							optionLabel: item.name, optionValue: item.supportMessageSubjectId,
						})}
						onChange={(e) => {
							let { subjectError } = state;
							if (e.target.value !== '-1') {
								subjectError = '';
							}
							setState((prev) => ({
								...prev, subjectId: parseInt(e.target.value, 10), subjectError,
							}));
						}}
					/>
					<SizedBox height={2.5} />
					<TextField
						id="name"
						name="name"
						value={state.name}
						error={Boolean(state.nameError)}
						helperText={state.nameError}
						onChange={handleChange}
						onBlur={onBlurValidation}
						startIcon={<PersonField />}
						label={t('name_and_surname')}
					/>
					<SizedBox height={2.5} />
					<Grid container spacing={3}>
						<Grid item md={6} xs={12}>
							<TextField
								name="email"
								value={state.email}
								error={Boolean(state.emailError)}
								helperText={state.emailError}
								onChange={handleChange}
								onBlur={onBlurValidation}
								startIcon={<AtField />}
								label="E-mail"
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								name="phone"
								value={state.phone}
								error={Boolean(state.phoneError)}
								helperText={state.phoneError}
								onChange={handleChange}
								onBlur={onBlurValidation}
								startIcon={<CellField />}
								label={t('cell_phone')}
							/>
						</Grid>
					</Grid>
					<SizedBox height={2.5} />
					<TextField
						name="message"
						label={t('message')}
						error={Boolean(state.messageError)}
						value={state.message}
						helperText={state.messageError}
						placeholder={t('describe_your_question')}
						onChange={handleChange}
						onBlur={onBlurValidation}
						multiline
						minRows={4}
					/>
					<SizedBox height={2.5} />
					<Stack>
						<DefaultButton disabled={loading} type="submit" sx={{ alignSelf: 'center' }}>
							{loading && <CircularProgress size={30} />}
							{!loading && t('submit_contact_request')}
						</DefaultButton>
					</Stack>
				</form>
			</Box>
			<Box sx={{ width: { md: '40%', xs: '100%' } }}>
				<BodyText sx={{ color: theme.palette.secondary.main }}>
					{t('our_contacts')}
				</BodyText>
				<SizedBox height={1.7} />
				<Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1.4}>
					<SvgIcon>
						<Call />
					</SvgIcon>
					<BodyText sx={{ color: theme.palette.textMain.main }}>
						+351 244 850 160
					</BodyText>
				</Stack>
				<SmallText>{t('Fixed mobile network call')}</SmallText>
				<SizedBox height={1.7} />
				<Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1.4}>
					<SvgIcon>
						<Mail />
					</SvgIcon>
					<BodyText sx={{ color: theme.palette.textMain.main }}>
						info@volt.pt
					</BodyText>
				</Stack>
			</Box>
		</Stack>
	);
};

export default ContactUsSection;
