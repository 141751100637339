import {
	Box, CssBaseline,
	Link,
	Stack, SvgIcon,
	Typography,
	styled,
	useTheme,
} from '@mui/material';
import AppLinks from 'assets/applinks.routes';
import Dimens from 'assets/dimens';
import { Catalog, Phone, Products } from 'assets/icons';
import { logo, logoXs } from 'assets/images';
import {
	AppBar, AppFooter, Image, SideBar, Tabbar,
} from 'common/presentation/components';
import AppBarSearch from 'common/presentation/components/app-bar/AppBarSearch';
import React from 'react';
import {
	Outlet, Link as RouterLink,
	useNavigate,
} from 'react-router-dom';

const Main = () => {
	const { mixins } = useTheme();
	const [drawerOpen, setDrawerOpen] = React.useState(false);
	const navigate = useNavigate();

	const handleDrawerClose = () => {
		setDrawerOpen(false);
	};

	const handleClick = (link?: string) => {
		if (link) {
			navigate(link);
		} else {
			setDrawerOpen(true);
		}
	};

	const LinkText = styled(Typography)(({ theme }) => ({
		fontSize: Dimens.FONT_SIZE_SMALL,
		marginLeft: Dimens.DEFAULT_PADDING,
		color: theme.palette.textMain.main,
		fontWeight: Dimens.FONT_BOLD,
	}));

	return (
		<>
			<CssBaseline />
			{/* Appbar  Component */}
			<AppBar
				logo={<Link to={AppLinks.LANDING} component={RouterLink}><Image imageMd={logo} imageXs={logoXs} alt="logo" /></Link>}
				routes={[{ name: 'PRODUTOS', icon: <Products /> }, { name: 'CATALOGOS', icon: <Catalog />, link: AppLinks.PRODUCT_CATELOG }, { name: 'CONTATOS', icon: <Phone />, link: AppLinks.CONTACT_US }]}
				renderRoutes={(el, index) => (
					<Stack
						direction="row"
						alignItems="center"
						sx={{ '&:hover': { cursor: 'pointer' } }}
						onClick={() => {
							if (index === 0) {
								handleClick();
							} else { handleClick(el.link); }
						}}
					>
						<SvgIcon>
							{el.icon}
						</SvgIcon>
						<LinkText>{el.name}</LinkText>
					</Stack>
				)}
				renderEndComponent={<AppBarSearch />}
			/>
			<SideBar open={drawerOpen} handleClose={handleDrawerClose} onClick={handleDrawerClose} />
			{/* Application body */}
			<Box
				sx={{
					pt: {
						md: `${parseInt(String(mixins.toolbar.minHeight), 10) + 60}px`,
						xs: `${parseInt(String(mixins.toolbar.minHeight), 10) + 24}px`,
					},
				}}
				component="main"
			>
				{/* <AppRouter /> */}
				<Outlet />
			</Box>
			{/* Application footer */}
			<AppFooter />

			<Tabbar onProductClick={() => handleClick()} />

		</>
	);
};

export default Main;
