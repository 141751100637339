import { Badge, BadgeProps, styled } from '@mui/material';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		left: '10px',
		top: -6,
		borderRadius: '0px',
		color: theme.palette.secondary.contrastText,
		'&::before': {
			content: '""',
			background: theme.palette.secondary.main,
			position: 'absolute',
			left: 0,
			top: 0,
			width: '100%',
			height: '100%',
			zIndex: -1,
			transition: '0.5s ease',
			transform: 'rotate(45deg) scale(1)',
		},
	},
}));

export default StyledBadge;
