import React from 'react';
import RegisterPage from './RegisterPage';
import { makeRegisterValidation } from '../builder/authentication-factory';

const Register = () => (
	<section>
		<RegisterPage validation={makeRegisterValidation()} />
	</section>
);

export default Register;
