import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { StockStatusBackoffice } from '../../data/dto/stock-status-backoffice-model';
import SettingApiImpl from '../../data/remote/setting-api-impl';
import { ApiResponse } from '@/common/domain/entities/api-response';

interface FetchState {
	loading: boolean;
	error: string;
}

type TContextProps = {
	stockStatusFetchState: FetchState
	stockStatuses: StockStatusBackoffice[]
	setStockStatuses: (value: StockStatusBackoffice[]) => void
	getStockStatus: () => Promise<boolean>
}

const StockContext = createContext({} as TContextProps);

export const StockBackofficeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const categoriesApi = new SettingApiImpl();
	const [stockStatuses, setStockStatuses] = useState<StockStatusBackoffice[]>([]);
	const [stockStatusFetchState, setStockStatusFetchState] = useState<FetchState>({
		loading: false,
		error: '',
	});

	const getStockStatus = () => {
		setStockStatusFetchState({ ...stockStatusFetchState, loading: true });

		return categoriesApi.getStockStatuses().then((res) => {
			setStockStatuses(res.data);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setStockStatusFetchState({ ...stockStatusFetchState, error: responseError.message });
			return false;
		}).finally(() => {
			setStockStatusFetchState({ ...stockStatusFetchState, loading: false });
		});
	};

	const value = useMemo(() => ({
		stockStatuses,
		stockStatusFetchState,
		setStockStatuses,
		getStockStatus,
	}), [stockStatuses, stockStatusFetchState]);

	return <StockContext.Provider value={value}>{children}</StockContext.Provider>;
};

export function useStockBackoffice() {
	return useContext(StockContext);
}
