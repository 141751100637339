import React, {
	createContext, useContext, useMemo, useState,
} from 'react';
import { SupportCreateModel } from '../../data/dto/support-create-model';
import { SupportApiImpl } from '../../data/remote/support-api-impl';
import { ApiResponse } from 'common/domain/entities/api-response';
import { SubjectReadModel } from '../../data/dto/subject-read-model';

type TContextProps = {
	loading: boolean;
    error: string;
	subjects: SubjectReadModel[],
	getSujects: () => Promise<boolean>,
	createSupport: (support: SupportCreateModel) => Promise<boolean>;
}

const ContactContext = createContext({} as TContextProps);

export const ContactProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const supportApi = new SupportApiImpl();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [subjects, setSubjecs] = useState<SubjectReadModel[]>([]);

	const createSupport = async (support: SupportCreateModel) => {
		setLoading(true);
		setError('');
		return supportApi.create(support).then(() => true).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setError(responseError.message);
			return false;
		}).finally(() => {
			setLoading(false);
		});
	};

	const getSujects = async () => {
		setLoading(true);
		setError('');
		return supportApi.getSubjects().then((result) => {
			setSubjecs(result.data);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setError(responseError.message);
			return false;
		}).finally(() => {
			setLoading(false);
		});
	};

	const value = useMemo(() => ({
		subjects,
		loading,
		error,
		getSujects,
		createSupport,
	}), [subjects, loading, error]);

	return <ContactContext.Provider value={value}>{children}</ContactContext.Provider>;
};

export default function useContactContext() {
	return useContext(ContactContext);
}
