import { Toast } from 'primereact/toast';
import React, { createContext, useMemo, useRef } from 'react';

type TNotificationsContextProps = {
	toast: React.RefObject<Toast>,
}

export const NotificationsContext = createContext<TNotificationsContextProps>(
	{} as TNotificationsContextProps,
);

export const NotificationsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const toast = useRef<Toast>(null);

	const value = useMemo(() => ({
		toast,
	}), [toast]);

	return (
		<NotificationsContext.Provider value={value}>
			<Toast ref={toast} style={{ zIndex: 999999 }} />
			{children}
		</NotificationsContext.Provider>
	);
};

export default function useNotifications() {
	return React.useContext(NotificationsContext);
}
