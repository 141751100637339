import { Either } from '@ajtec/either-typescript';
import { IProductRepository } from '../repository/product-repository';
import { Product } from '../entities/Product';
import { FilterParams } from '../../data/remote/product-api';
import { ApiResponse } from '@/common/domain/entities/api-response';

export class GetProductsByFilter {
	repository: IProductRepository;

	constructor(productRepository: IProductRepository) {
		this.repository = productRepository;
	}

	call(params: FilterParams): Promise<Either<string, ApiResponse<Product[]>>> {
		return this.repository.getProductsByFilter(params);
	}
}
