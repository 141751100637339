import { DefaultButton, SizedBox } from 'common/presentation/components';
import {
	Box,
	Chip,
	Grid,
	useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { DomainVerification } from '@mui/icons-material';
import Dimens from 'assets/dimens';
import { inter } from 'assets/theme/theme';
import { TextInput } from '../../components';
import { useStockBackoffice } from '../../provider/stock-backoffice-provider';

const StockDefinition = () => {
	const theme = useTheme();
	const { getStockStatus, stockStatuses, setStockStatuses } = useStockBackoffice();

	useEffect(() => {
		getStockStatus();
	}, []);

	const handleMinStockChange = (
		index: number,
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const mStockStatus = [...stockStatuses];
		mStockStatus[index].minValue = parseInt(e.target.value, 10);
		setStockStatuses(mStockStatus);
	};

	const handleMaxStockChange = (
		index: number,
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const mStockStatus = [...stockStatuses];
		mStockStatus[index].maxValue = parseInt(e.target.value, 10);
		setStockStatuses(mStockStatus);
	};

	return (
		<Box sx={{
			bgcolor: theme.palette.background.default,
			pl: 2,
			pb: 2,
			pr: 2,
		}}
		>
	  		<SizedBox height={2} />
			{stockStatuses.length > 0 && stockStatuses.map((stockItem, index) => (
				<Grid container spacing={3} alignItems="center" sx={{ mt: 2 }}>
					<Grid item xs={12} xl={1} lg={2} md={3}>
						<Chip label={stockItem.name} />
					</Grid>
					<Grid item xs={12} md={2}>
						<TextInput type="number" onChange={(e) => handleMinStockChange(index, e)} value={stockItem.minValue} label="Minimo de stock" />
					</Grid>
					<Grid item xs={12} md={2}>
						<TextInput type="number" onChange={(e) => handleMaxStockChange(index, e)} value={stockItem.maxValue} label="Maximo de stock" />
					</Grid>
				</Grid>
			))}
			<SizedBox height={2} />
			<DefaultButton
				startIcon={<DomainVerification />}
				sx={{
					borderRadius: Dimens.CARD_BORDER_RADIUS,
					textTransform: 'none',
					fontFamily: inter,
				}}
			>
				Guardar alterações
			</DefaultButton>
		</Box>
	);
};

export default StockDefinition;
