import {
	Box,
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Stack,
	Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'primereact/hooks';
import SearchIcon from '@mui/icons-material/Search';
import { FilterParams } from '../../data/remote/order-api';
import { useTranslation } from 'react-i18next';
import useCustomersBackoffice from '../provider/customer-backoffice.provider';
import { DataTable } from 'primereact/datatable';
import { CustomerBackofficeModel } from '../../data/dto/customer-backoffice-model';
import { Column } from 'primereact/column';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';
import { getClientTypeColors, getProductStatusColors, getStockColors } from 'common/utils/general.utils';
import { Tag } from 'primereact/tag';
import AppLinks from 'assets/applinks.routes';
import { useNavigate } from 'react-router-dom';
import { ToggleButton } from 'primereact/togglebutton';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

export const CustomersPage = () => {
	const { t } = useTranslation('translations');
	const {
		customers,
		getCustomers,
		loadingCustomers,
		getCustomerGroups,
		customerGroups,
		setCustomers,
		updateCustomerGroup,
		updateCustomerGroupState,
		updateCustomerStatus,
	} = useCustomersBackoffice();
	const navigate = useNavigate();
	const toast = useRef<Toast>(null);
	const [inputValue, debouncedValue, setInputValue] = useDebounce('', 400);
	const [filter, setFilter] = useState<FilterParams>({
		size: 20,
	} as FilterParams);
	const [totalOfProducts, setTotalOfProducts] = useState<number>(0);
	const [lazyState, setlazyState] = useState({
		first: 0,
		rows: 30,
		page: 1,
		sortField: null,
		sortOrder: null,
		filters: {},
	});
	const [customerStatuses] = useState<string[]>([t('active'), t('inactive')]);

	useEffect(() => {
		setFilter(
			(prev) => ({
				...prev,
				size: lazyState.rows,
				page: lazyState.page,
				search: debouncedValue,
			}),
		);
	}, [lazyState, debouncedValue]);

	useEffect(() => {
		getCustomers(filter).then((res) => {
			setTotalOfProducts(res.total);
		});
		getCustomerGroups({ size: 1000 });
	}, [filter]);

	const onPage = (event: any) => {
		setlazyState(event);
	};

	const customerStatusTag = (options: any) => {
		const { isActive } = options;
		const { color, bgColor } = getProductStatusColors(isActive);
		return (
			<Tag
				value={isActive ? customerStatuses[0] : customerStatuses[1]}
				style={{
					backgroundColor: bgColor,
					color,
					fontSize: 14,
					paddingLeft: 8,
					paddingRight: 8,
				}}
			/>
		);
	};

	const clientTypeTag = (options: any) => {
		const { customerType } = options;
		const { color, bgColor } = getClientTypeColors(customerType.id);
		return (
			<Tag
				value={customerType.name}
				style={{ backgroundColor: bgColor, color }}
			/>
		);
	};

	const clientStatusEditor = (options: any) => (
		<ToggleButton
			checked={options.value}
			onChange={(e) => options.editorCallback(e.value)}
			onLabel={t('active')}
			offLabel={t('inactive')}
			color="red"
			style={{ height: 35 }}
		/>
	);

	const clientTypeEditor = (options: any) => (
		<Dropdown
			value={options?.value}
			options={customerGroups}
			onChange={(e) => options.editorCallback(e.value)}
			placeholder="Select a Status"
			optionLabel="name"
			// eslint-disable-next-line react/no-unstable-nested-components
			itemTemplate={(ev) => {
				const { color, bgColor } = getStockColors(ev.stockStatusId);
				return (
					<Stack>
						<Tag value={ev.name} style={{ backgroundColor: bgColor, color }} />
					</Stack>
				);
			}}
		/>
	);

	const dateFormat = (rowData: CustomerBackofficeModel) => moment(rowData.createdAt).format('DD MMMM YYYY');

	const onRowEditComplete = async (e: any) => {
		const list = [...customers];
		const { newData, index } = e;
		const old = list[index];
		const prod = newData as CustomerBackofficeModel;
		list[index] = prod;
		setCustomers(list);

		try {
			await updateCustomerGroup(prod.customerId, newData.customerType.id);
			if (old.isActive !== newData.isActive) {
				await updateCustomerStatus(prod.customerId);
			}

			toast.current?.show({
				severity: 'success',
				summary: 'Sucesso',
				detail: 'Item atualizado com sucesso',
			});
		} catch (error) {
			list[index] = old;
			setCustomers(list);
			toast.current?.show({
				severity: 'error',
				summary: 'Falha',
				detail: 'Uma ou mais informação não foi possível ser salva.',
			});
		}
	};

	return (
		<Box>
			<Stack direction="row">
				<Box sx={{ flexGrow: 1 }} />
				<FormControl
					variant="outlined"
					sx={{ width: '40ch', alignSelf: 'start', mr: 4 }}
				>
					<OutlinedInput
						size="small"
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						placeholder={t('clients')}
						startAdornment={(
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						)}
						inputProps={{
							'aria-label': 'search',
						}}
					/>
				</FormControl>
			</Stack>
			<Box sx={{ overflow: 'auto' }}>
				<Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
					<DataTable
						value={customers}
						lazy
						paginator
						first={lazyState.first}
						onPage={onPage}
						rows={lazyState.rows}
						onRowEditComplete={onRowEditComplete}
						editMode="row"
						totalRecords={totalOfProducts}
						loading={loadingCustomers || updateCustomerGroupState.loading}
						dataKey="id"
						tableStyle={{ minWidth: '50rem' }}
					>
						<Column
							field="customerId"
							header={t('client_id')}
							align="center"
							// eslint-disable-next-line react/no-unstable-nested-components
							body={(rowData: CustomerBackofficeModel) => (
								<Typography sx={{ color: '#B9C92B', fontWeight: 600 }}>
									{`#${rowData.customerId}`}
								</Typography>
							)}
						/>
						<Column field="fullName" bodyStyle={{ fontSize: 14 }} header={t('client_name')} />
						<Column field="email" bodyStyle={{ fontSize: 14 }} header={t('label_email')} />
						<Column field="phoneNumber" bodyStyle={{ fontSize: 14 }} header={t('phone')} />

						<Column
							field="customerType"
							align="center"
							header={t('account_creation_date')}
							style={{ fontWeight: 600 }}
							body={(options) => dateFormat(options)}
						/>

						<Column
							field="customerType"
							header={t('client_type')}
							align="center"
							body={(options) => clientTypeTag(options)}
							editor={(options) => clientTypeEditor(options)}
							headerStyle={{ width: '10%', minWidth: '8rem' }}
						/>
						<Column
							field="isActive"
							header="Status"
							align="center"
							body={(options) => customerStatusTag(options)}
							editor={(options) => clientStatusEditor(options)}
							headerStyle={{ width: '10%', minWidth: '8rem' }}
						/>
						<Column
							rowEditor
							headerStyle={{ width: '10%', minWidth: '8rem' }}
							bodyStyle={{ textAlign: 'center' }}
						/>
						<Column
							headerStyle={{ width: '10%', minWidth: '8rem' }}
							// eslint-disable-next-line react/no-unstable-nested-components
							body={(options) => (
								<IconButton onClick={() => navigate(`${AppLinks.CUSTOMERS}/${options.customerId}`)}>
									<ArrowForwardIosIcon />
								</IconButton>
							)}
							bodyStyle={{ textAlign: 'center' }}
						/>
					</DataTable>
				</Box>
			</Box>
		</Box>
	);
};
