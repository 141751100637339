import { BodyText } from 'common/presentation/components';
import { Checkbox, Stack, useTheme } from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';

interface Props {
	label: string;
	positiveButtonText: string;
	negativeButtonText: string;
	positiveChecked: boolean;
	negativeChecked: boolean;
	positiveAction: () => void;
	negativeAction: () => void
}

const CheckItem: React.FC<Props> = (props) => {
	const {
		label, positiveButtonText, negativeButtonText, positiveChecked,
		negativeChecked,
		positiveAction,
		negativeAction,
	} = props;
	const theme = useTheme();
	return (
		<Stack sx={{ mt: 5 }} direction="row" justifyContent="center" alignItems="center">
			<BodyText
				sx={{ color: theme.palette.textMain.main, fontWeight: Dimens.FONT_NORMAL }}
			>
				{label}
			</BodyText>
			<Checkbox color="secondary" checked={positiveChecked} onChange={positiveAction} />
			<BodyText sx={{
				color: theme.palette.textMain.main,
				fontWeight: Dimens.FONT_THIN,
			}}
			>
				{positiveButtonText}

			</BodyText>
			<Checkbox color="secondary" checked={negativeChecked} onChange={negativeAction} />
			<BodyText sx={{
				color: theme.palette.textMain.main,
				fontWeight: Dimens.FONT_THIN,
			}}
			>
				{negativeButtonText}
			</BodyText>
		</Stack>
	);
};

export default CheckItem;
