import {
	Box,
	Stack,
	Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { DefaultButton, SizedBox } from 'common/presentation/components';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { getProductStatusColors } from 'common/utils/general.utils';
import { Dialog } from 'primereact/dialog';

interface Props {
	handleClose: () => void;
	open: boolean;
	handleFilterClick: (value: boolean) => void
}

const StatusFilterModal: React.FC<Props> = (props) => {
	const { handleClose, open, handleFilterClick } = props;
	const [status, setStatus] = useState({ id: 1, name: 'Active' });
	const handleConfirm = () => {
		handleFilterClick(status.id === 1);
		handleClose();
	};
	return (
		<Dialog header="Filtros de pesquisa" onHide={handleClose} visible={open}>
			<Box sx={{ p: 2 }}>
				<Typography>
					Status
				</Typography>
				<Dropdown
					options={[
						{ id: 1, name: 'Active' },
						{ id: 2, name: 'Inactive' },
					]}
					value={status}
					placeholder="Select a Status"
					optionLabel="name"
					style={{ width: '100%' }}
					onChange={(e) => setStatus(e.value)}
					// eslint-disable-next-line react/no-unstable-nested-components
					itemTemplate={(ev) => {
						const { color, bgColor } = getProductStatusColors(ev.id === 1);
						return (
							<Stack sx={{ zIndex: 10001 }}>
								<Tag value={ev.name} style={{ backgroundColor: bgColor, color }} />
							</Stack>
						);
					}}
				/>
				<SizedBox height={2} />
				<DefaultButton fullWidth onClick={handleConfirm}>
					Filtrar
				</DefaultButton>
			</Box>
		</Dialog>
	);
};

export default StatusFilterModal;
