import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { CustomerBackofficeModel, CustomerGroupModel, FullCustomerModel } from '../../data/dto/customer-backoffice-model';
import { FilterParams } from '../../data/remote/customers-api';
import { ApiResponse } from 'common/domain/entities/api-response';
import { CustomersApiImpl } from '../../data/remote/customer-api-impl';
import { ClientOrderBackoffice } from '../../data/dto/order-backoffice-model';

interface FetchState {
	loading: boolean;
	error: string;
}

type TContextProps = {
	customers: CustomerBackofficeModel[]
	loadingCustomers: boolean;
	getCustomers: (filter: FilterParams) => Promise<{
		success: boolean;
		message: string;
		total: number;
	}>;
	customerById: FullCustomerModel | null;
	customerFetchState: FetchState;
	getCustomerById: (id: string) => Promise<boolean>;
	customerOrders: ClientOrderBackoffice[];
	setCustomerOrders: (orders: ClientOrderBackoffice[]) => void
	orderFetchState: FetchState;
	getCustomerOrders: (id: string) => Promise<{
		success: boolean;
		message: string;
		total: number;
	}>
	customerGroups: CustomerGroupModel[]
	getCustomerGroups: (params: FilterParams) => Promise<{
		success: boolean;
		message: string;
		total: number;
	}>
	setCustomers: (customers: CustomerBackofficeModel[]) => void
	customerGroupsState: FetchState
	updateCustomerGroup: (id: number, groupId: number) => Promise<boolean>
	updateCustomerGroupState: FetchState
	updateCustomerStatus: (id: number) => Promise<boolean>
	setCustomerGroup: (groups: CustomerGroupModel[]) => void
}

const CustomersBackofficeContext = createContext({} as TContextProps);

export const CustomersBackofficeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [customers, setCustomers] = useState<CustomerBackofficeModel[]>([]);
	const [loadingCustomers, setLoadingCustomers] = useState(false);

	const [customerById, setCustomerById] = useState<FullCustomerModel | null>(null);
	const [customerFetchState, setCustomerFetchState] = useState<FetchState>({
		loading: false,
		error: '',
	});

	const [customerOrders, setCustomerOrders] = useState<ClientOrderBackoffice[]>([]);
	const [orderFetchState, setOrderFetchState] = useState<FetchState>({
		loading: false,
		error: '',
	});
	const [customerGroups, setCustomerGroup] = useState<CustomerGroupModel[]>([]);
	const [customerGroupsState, setCustomerGroupState] = useState<FetchState>({
		loading: false,
		error: '',
	});
	const [updateCustomerGroupState, setUpdateCustomerGroupState] = useState({
		loading: false,
		error: '',
	});

	const customerApi = new CustomersApiImpl();

	const getCustomers = async (filter: FilterParams) => {
		setLoadingCustomers(true);
		return customerApi.getCustomers(filter).then((res) => {
			setCustomers(res.data);
			return ({ success: true, message: '', total: res.total });
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			return { success: false, message: responseError.message, total: 0 };
		}).finally(() => setLoadingCustomers(false));
	};

	const getCustomerById = async (id: string) => {
		setCustomerFetchState({ ...customerFetchState, loading: true });
		return customerApi.getCustomerById(id).then((res) => {
			setCustomerById(res.data);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setCustomerFetchState({ ...customerFetchState, error: responseError.message });
			return false;
		}).finally(() => {
			setCustomerFetchState({ ...customerFetchState, loading: false });
		});
	};

	const getCustomerOrders = async (id: string) => {
		setOrderFetchState({ ...orderFetchState, loading: true });
		return customerApi.getCustomerOrders(id).then((res) => {
			setCustomerOrders(res.data);
			return ({ success: true, message: '', total: res.total });
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			return { success: false, message: responseError.message, total: 0 };
		}).finally(() => setOrderFetchState({ ...orderFetchState, loading: false }));
	};

	const getCustomerGroups = () => {
		setCustomerGroupState({ loading: true, error: '' });
		return customerApi.getCustomerGroups().then((res) => {
			setCustomerGroup(res.data);
			return ({ success: true, message: '', total: res.total });
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setCustomerGroupState({ ...customerGroupsState, error: responseError.message });
			return { success: false, message: responseError.message, total: 0 };
		}).finally(() => {
			setCustomerGroupState({ ...customerGroupsState, loading: false });
		});
	};

	const updateCustomerGroup = async (id: number, groupId: number) => {
		setUpdateCustomerGroupState({ loading: true, error: '' });
		return customerApi.updateCustomerGroup(id, groupId).then(() => true).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setUpdateCustomerGroupState((prev: FetchState) => ({
				...prev, error: responseError.message,
			}));
			return false;
		}).finally(() => {
			setUpdateCustomerGroupState((prev: FetchState) => ({ ...prev, loading: false }));
		});
	};

	const updateCustomerStatus = async (id: number) => {
		setUpdateCustomerGroupState({ loading: true, error: '' });
		return customerApi.updateCustomerStatus(id).then(() => true).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setUpdateCustomerGroupState((prev: FetchState) => ({
				...prev, error: responseError.message,
			}));
			return false;
		}).finally(() => {
			setUpdateCustomerGroupState((prev: FetchState) => ({ ...prev, loading: false }));
		});
	};

	const value = useMemo(() => ({
		customers,
		loadingCustomers,
		getCustomers,
		customerById,
		customerFetchState,
		getCustomerById,
		customerOrders,
		orderFetchState,
		getCustomerOrders,
		setCustomerOrders,
		customerGroups,
		getCustomerGroups,
		setCustomers,
		updateCustomerGroup,
		updateCustomerGroupState,
		customerGroupsState,
		updateCustomerStatus,
		setCustomerGroup,
	}), [
		customers,
		loadingCustomers,
		customerById,
		customerFetchState,
		customerOrders,
		orderFetchState,
		customerGroups,
		updateCustomerGroupState,
		customerGroupsState,
	]);

	return (
		<CustomersBackofficeContext.Provider value={value}>
			{children}
		</CustomersBackofficeContext.Provider>
	);
};

export default function useCustomersBackoffice() {
	return useContext(CustomersBackofficeContext);
}
