/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import {
	Card, useTheme, Stack, SvgIcon, useMediaQuery, Grid, Skeleton,
} from '@mui/material';
import { imageNotLoaded } from 'assets/images';
import Dimens from 'assets/dimens';
import { zilaSlab } from 'assets/theme/theme';
import { ShoppingCart } from '@mui/icons-material';
import BodyText from '../body-text/BodyText';
import DefaultButton from '../default-button/DefaultButton';
import { processImg } from 'common/utils/general.utils';

interface Props {
	price: string;
	info: string;
	reference: string;
	image?: string;
	width?: string,
	onDetailClick: () => void;
	onCartClick: () => void
}

const ProductCard: React.FC<Props> = (props) => {
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('sm'));

	const [imageProcessed, setImageProcessed] = useState<string>();
	const [loading, setLoading] = useState<boolean>(true);

	const {
		price, info, reference, image = imageNotLoaded, onDetailClick, onCartClick, width,
	} = props;

	useEffect(() => {
		setLoading(true);
		processImg(image).then((processed) => {
			setImageProcessed(processed);
		}).catch(() => {
			setImageProcessed(undefined);
		}).finally(() => {
			setLoading(false);
		});
	}, [image]);
	return (
		!isXs ? (
			<Card
				elevation={0}
				sx={{
					width: width || '230px',
					minHeight: '344px',
					position: 'relative',
					// height: '325px',
					boxShadow: ` 0px 0px 1.80666px ${theme.palette.secondary.main}`,
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					p: '14px',
				}}
			>
				{loading && (
					<Skeleton variant="rectangular" width="100%" height={165} />
				)}
				{!loading && (
					<img
						src={imageProcessed || imageNotLoaded}
						width="100%"
						height={165}
						style={{ objectFit: 'contain', minHeight: 165 }}
						alt={info}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = imageNotLoaded;
						}}
					/>
				)}
				<BodyText sx={{
					fontSize: Dimens.FONT_SIZE_LARGE_SMALL,
					fontFamily: zilaSlab,
					fontWeight: Dimens.FONT_BOLD,
					color: theme.palette.primary.contrastText,
				}}
				>
					{Number.parseFloat(price).toFixed(2)}
					{' '}
					€
				</BodyText>
				<BodyText
					sx={{
						fontSize: '12px',
						fontFamily: zilaSlab,
						fontWeight: Dimens.FONT_THIN,
						lineClamp: '2',
						WebkitLineClamp: '2',
						textOverflow: 'ellipsis',
						color: theme.palette.primary.contrastText,
					}}
					title={reference}
				>
					Ref:
					{' '}
					{reference}
				</BodyText>
				<BodyText
					sx={{
						fontSize: Dimens.FONT_SIZE_SMALL,
						fontFamily: zilaSlab,
						fontWeight: Dimens.FONT_THIN,
						lineClamp: '2',
						WebkitLineClamp: '2',
						textOverflow: 'ellipsis',
						color: theme.palette.primary.contrastText,
					}}
					title={info}
				>
					{info}
				</BodyText>
				<div style={{ flexGrow: 1 }} />
				<Stack
					direction="row"
					justifyContent="space-around"
					alignItems="center"
					sx={{
						mt: 2, gap: 1, position: 'absolute', bottom: 14,
					}}
				>
					<DefaultButton onClick={onDetailClick} sx={{ fontSize: '13px' }}>VER DETALHES</DefaultButton>
					<DefaultButton onClick={onCartClick} sx={{ bgcolor: theme.palette.background.paper, border: `1px solid ${theme.palette.secondary.main}`, height: '100%' }}>
						<SvgIcon color="secondary">
							<ShoppingCart />
						</SvgIcon>
					</DefaultButton>
				</Stack>
			</Card>
		) : (
			<Card
				elevation={0}
				sx={{
					minWidth: width,
					boxShadow: ` 0px 0px 1.80666px ${theme.palette.secondary.main}`,
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					p: '14px',
				}}
			>
				<Stack direction="row" gap={2}>
					{loading && (
						<Skeleton width={204} height={204} />
					)}
					{!loading && (
						<img
							src={imageProcessed || imageNotLoaded}
							width="45%"
							style={{ objectFit: 'contain' }}
							alt={info}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = imageNotLoaded;
							}}
						/>
					)}
					<Stack direction="column" justifyContent="center">
						<BodyText
							sx={{
								fontSize: '14px',
								fontFamily: zilaSlab,
								fontWeight: Dimens.FONT_THIN,
								lineClamp: '3',
								WebkitLineClamp: '3',
								textOverflow: 'ellipsis',
								color: theme.palette.primary.contrastText,
							}}
							title={info}
						>
							{info}
						</BodyText>
						<BodyText sx={{
							fontSize: '24px',
							fontFamily: zilaSlab,
							fontWeight: Dimens.FONT_BOLD,
							color: theme.palette.primary.contrastText,
						}}
						>
							{Number.parseFloat(price).toFixed(2)}
							{' '}
							€
						</BodyText>
						<Grid
							container
							columns={2}
							gap={1}
							alignItems="center"
							sx={{
								gap: 1, bottom: 14,
							}}
						>
							<DefaultButton fullWidth onClick={onDetailClick} sx={{ fontSize: '12px' }}>VER DETALHES</DefaultButton>
							<DefaultButton fullWidth onClick={onCartClick} sx={{ bgcolor: theme.palette.background.paper, border: `1px solid ${theme.palette.secondary.main}` }}>
								<SvgIcon color="secondary">
									<ShoppingCart />
								</SvgIcon>
							</DefaultButton>
						</Grid>
					</Stack>
				</Stack>
			</Card>
		)
	);
};

export default ProductCard;
