/* eslint-disable no-useless-constructor */
import { InvalidFieldError } from '../../errors';
import { FieldValidation } from '../../protocols/field-validation';

export class CompareFieldValidation implements FieldValidation {
	constructor(
    readonly field: string,
    private readonly valueToCompare: string,
	) {}

	validate(input: object): Error | null {
		return (
			input[this.field as keyof typeof input] !== input[this.valueToCompare as keyof typeof input]
				? new InvalidFieldError() : null
		);
	}
}
