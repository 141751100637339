import React from 'react';
import {
	Stack, useTheme, Box, useMediaQuery,
} from '@mui/material';
import Dimens from 'assets/dimens';
import {
	BodyText, SizedBox, TitleLarge,
} from 'common/presentation/components';
import { useTranslation } from 'react-i18next';
import { voltPubliVideo } from 'assets/videos';

const ServicesSection = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<section>
			<Stack
				direction={{ md: 'row', xs: 'column' }}
				spacing={5}
				justifyContent="space-between"
				sx={{
					pt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
					pb: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
					pl: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },
					pr: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },
					bgcolor: theme.palette.primary.main,
				}}
			>
				<Box sx={{ mb: 2 }}>
					<video loop controls={false} width={isSm ? '100%' : 600} autoPlay muted src={voltPubliVideo} />
				</Box>
				<Box sx={{ width: { md: '60%', xs: '100%' } }}>
					<BodyText
						sx={{
							fontSize: Dimens.FONT_SIZE_SMALL,
							color: theme.palette.secondary.main,
							fontWeight: Dimens.FONT_BOLDER,
							mt: {
								md: '-50px',
								sm: '-30p',
							},
						}}
					>
						{t('who_we_are').toUpperCase()}
					</BodyText>
					<SizedBox height={3} />
					<TitleLarge>
						{t('service_quality')}
					</TitleLarge>
					<SizedBox height={5} />
					<BodyText>
						{t('volt_company_history_para_one')}
					</BodyText>
					<SizedBox height={3} />
					<BodyText>
						{t('volt_company_history_para_two')}
					</BodyText>
					<SizedBox height={3} />
					<BodyText>
						{t('volt_company_history_para_three')}
					</BodyText>
				</Box>
			</Stack>
		</section>
	);
};

export default ServicesSection;
