import Dimens from 'assets/dimens';
import React, { useEffect } from 'react';
import {
	Box, useTheme, Stack, Skeleton,
} from '@mui/material';
import { Image, SizedBox, TitleLarge } from 'common/presentation/components';
import { productSelectionImage } from 'assets/images';
import { useTranslation } from 'react-i18next';
import ProductCard from 'common/presentation/components/product-card/ProductCard';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import useCart from 'common/presentation/providers/cart.provider';
import useProduct from '../../products/presentation/provider/product.provider';

const ProductSelection = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { products, getProductsByFilter, productLoading } = useProduct();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { addToCart } = useCart();

	useEffect(() => {
		getProductsByFilter({
			size: 6,
		});
	}, []);

	const handleDetailClick = (id: number) => { navigate(`${AppLinks.PRODUCT_DETAIL}/${id}`); };
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const handleCartClick = () => { };
	return (
		<section style={{ position: 'relative' }}>
			<Box sx={{
				display: { md: 'block', xs: 'none' }, position: 'absolute', left: 0, top: 0,
			}}
			>
				<Image imageMd={productSelectionImage} width="180px" alt="Product" />
			</Box>
			<Box
				sx={{
					pt: { md: Dimens.PAGE_PADDING_MEDIUM, xs: Dimens.PAGE_PADDING_MEDIUM_MOBILE },
					pb: Dimens.SECTION_SPACING,
					pl: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },
					pr: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },
					bgcolor: theme.palette.primary.main,
				}}
			>
				<TitleLarge textAlign="center">{t('our_selection_of_products')}</TitleLarge>
				<SizedBox height={8} />
				<Stack
					direction="row"
					spacing={2}
					sx={{
						overflowX: 'scroll',
						'&::-webkit-scrollbar': { display: 'none' },
						scrollbarWidth: 'none',
						msOverflowStyle: 'none',
					}}
				>
					{!productLoading && products.map((product, key) => (
						<ProductCard reference={product.reference || ''} width="290px" key={`product-${key.toString}`} image={product.image} price={`${product.priceWithTaxes}`} info={product.name ?? ''} onDetailClick={() => handleDetailClick(product.productId)} onCartClick={handleCartClick} />
					))}

					{productLoading && [...Array(6).keys()].map((item) => (
						<Stack spacing={0.5} key={`skeleton-product-${item}`}>
							<Skeleton variant="rectangular" width={230} height={210} />
							<Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
							<Skeleton variant="rounded" height={40} />
							<Stack direction="row" gap={1}>
								<Skeleton variant="rectangular" sx={{ flex: 1 }} height={60} />
								<Skeleton variant="rounded" width={85} height={60} />
							</Stack>
						</Stack>
					))}
				</Stack>
			</Box>
		</section>
	);
};

export default ProductSelection;
