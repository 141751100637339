/* eslint-disable indent */
import CloseIcon from '@mui/icons-material/Close';
import {
	IconButton, Stack, useMediaQuery, useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Dimens from 'assets/dimens';
import { BodyText, DefaultButton } from 'common/presentation/components';
import CounterButton from 'common/presentation/components/counter-button/CounterButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppLinks from 'assets/applinks.routes';
import { useNavigate } from 'react-router-dom';
import { imageNotLoaded } from 'assets/images';
import { Product } from '../../domain/entities/Product';
import { ICartProduct } from 'common/domain/entities/cart-product';

const style = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -40%)',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
};

interface ProductBuyModalParam {
	product: Product,
	open: boolean;
	onClose: () => void,
	onAddToCart: (item: ICartProduct) => void,
}

export const ModalProductBuy: React.FC<ProductBuyModalParam> = ({
	product,
	open,
	onClose,
	onAddToCart,
}) => {
	const handleClose = () => onClose();
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const navigate = useNavigate();
	const [count, setCount] = useState(1);

	if (product === undefined) {
		return (
			<span />
		);
	}

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				sx={{ overflow: 'scroll' }}
			>
				<Box sx={{ ...style, width: isMd ? 650 : '90%' }}>
					<Box dir="rtl">
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Box>

					<Box>
						<Stack
							direction={{
								md: 'row',
								xs: 'column',
							}}
							spacing={2}
						>
							<div style={{
								display: 'flex',
								outline: '1px solid #f7f7f7',
								minWidth: 165,
								height: 223,
								alignItems: 'center',
							}}
							>
								<img src={product.image || imageNotLoaded} width="100%" height={165} style={{ objectFit: 'contain' }} alt="catelog background" />
							</div>
							<div>
								<Stack direction={{ md: 'row', sm: 'column' }} justifyContent="space-between">
									<BodyText sx={{
										color: theme.palette.secondary.main,
										fontWeight: Dimens.FONT_NORMAL,
										display: 'flex',
										fontSize: 12,
										gap: 1,
									}}
									>
										<BodyText component="span" sx={{ fontSize: 12 }}>{t('code').toUpperCase()}</BodyText>
										{product.reference}
									</BodyText>

									<Stack direction="row">
										<BodyText sx={{
											color: theme.palette.secondary.main,
											fontWeight: Dimens.FONT_NORMAL,
											display: 'flex',
											fontSize: 12,
											gap: 1,
										}}
										>
											<BodyText component="span" sx={{ fontSize: 12 }}>{t('barcode').toUpperCase()}</BodyText>
											{product.barcode}
										</BodyText>
									</Stack>
								</Stack>
								<div style={{ marginTop: 16 }}>
									<div style={{ width: '65px', display: 'flex', justifyContent: 'start' }}>
										<img src={product.brandImage || imageNotLoaded} height={30} style={{ objectFit: 'contain' }} alt="catelog background" />
									</div>
									<Typography
										sx={{
											fontSize: {
												md: Dimens.FONT_SIZE_EXTRA_LARGE_MOBILE,
												xs: '18px',
											},
											fontWeight: Dimens.FONT_BOLD,
										}}
									>
										{product.name}

									</Typography>
									<Typography sx={{
										fontWeight: Dimens.FONT_THIN,
										fontSize: Dimens.FONT_SIZE_EXTRA_SMALL,
										color: '#7B8A8A',
									}}
									>
										{product.description}

									</Typography>
								</div>
								<div style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
								}}
								>
									<Typography
										sx={{
											color: '#7E8A16',
											fontSize: Dimens.FONT_SIZE_EXTRA_SMALL,
											fontWeight: Dimens.FONT_NORMAL,
											mt: 2,
											mb: 2,
										}}
									>
										{product.stockStatus?.name}

									</Typography>
									<CounterButton
										count={count}
										onIncrement={() => setCount(count + 1)}
										onDecrement={() => (count > 1 ? setCount(count - 1) : null)}
									/>
								</div>

							</div>
						</Stack>

					</Box>
					<Stack
						textAlign="center"
						direction={{
							md: 'row',
							xs: 'column',
						}}
						sx={{
							display: 'flex',
							justifyContent: 'space-around',
							mt: 2,
						}}
						gap={1}
					>
						<DefaultButton
							variant="outlined"
							onClick={() => navigate(`${AppLinks.PRODUCT_DETAIL}/${product.productId}`)}
							sx={{ fontSize: 12 }}
						>
							{' '}
							{t('see_product_details')}
						</DefaultButton>
						<DefaultButton
						sx={{ fontSize: 12 }}
						onClick={() => {
							onAddToCart({ quantity: count, ...product });
							setCount(1);
						}}
						>
							{' '}
							{t('add_product_car')}
						</DefaultButton>
					</Stack>
				</Box>
			</Modal>
		</div>
	);
};
