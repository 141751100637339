import { Theme, ThemeProvider, createTheme } from '@mui/material';
import { getDesignTokens } from 'common/utils/theme.util';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './app.css';
import { CartProvider } from './common/presentation/providers/cart.provider';
import { LanguageProvider } from './common/presentation/providers/language.provider';
import { AuthProvider } from './features/authentication/presentation/provider/auth.provider';
import { SearchProvider } from './features/products/presentation/provider/search.provider';
import AppRouter from './router';
import { AuthBackofficeProvider } from './features/backoffice/presentation/provider/auth-backoffice.provider';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { NotificationsProvider } from './common/presentation/providers/notifications.provider';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/lara-light-teal/theme.css';

const App = () => {
	const [appTheme, setAppTheme] = React.useState<Theme>();

	React.useEffect(() => {
		getDesignTokens('light').then((th) => {
			setAppTheme(createTheme(th));
		});
	}, []);

	return (
		<BrowserRouter>
			<PrimeReactProvider>
				<NotificationsProvider>
					<LanguageProvider>
						{
							!appTheme ? <div /> : (
								<ThemeProvider theme={appTheme}>
									<AuthBackofficeProvider>
										<AuthProvider>
											<CartProvider>
												<SearchProvider>
													<AppRouter />
												</SearchProvider>
											</CartProvider>
										</AuthProvider>
									</AuthBackofficeProvider>
								</ThemeProvider>
							)
						}
					</LanguageProvider>
				</NotificationsProvider>
			</PrimeReactProvider>
		</BrowserRouter>
	);
};

export default App;
