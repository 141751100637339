import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import React, { useEffect } from 'react';

type CheckoutFormProps = {
	didStripeLoad: (stripe: Stripe) => void;
	didElementLoad: (elements: StripeElements) => void;
}

export const CheckoutForm: React.FC<CheckoutFormProps> = (props) => {
	const { didElementLoad, didStripeLoad } = props;
	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		if (stripe) {
			didStripeLoad(stripe);
		}

		if (elements) {
			didElementLoad(elements);
		}
	}, [stripe, elements]);

	return (
		<form>
			<PaymentElement />
		</form>
	);
};
