import { BackButton, DefaultButton, SizedBox } from 'common/presentation/components';
import {
	Box,
	Card,
	CircularProgress,
	Grid,
	Stack,
	TextareaAutosize,
	Typography,
	useTheme,
} from '@mui/material';
import { ProgressBar } from 'primereact/progressbar';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useOrdersBackoffice from '../../provider/order-backoffice.provider';
import Usercard from '../../components/user-card/Usercard';
import { PriceCard } from '../../components';
import Dimens from 'assets/dimens';
import { LocationOn } from '@mui/icons-material';
import { inter } from 'assets/theme/theme';
import { Tag } from 'primereact/tag';
import { getOrderStatusColors } from 'common/utils/general.utils';
import { OrderBackofficeStatus, OrderItemsModel } from '../../../data/dto/order-backoffice-model';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useNotificationsContext from 'common/presentation/providers/notifications.provider';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const OrderDetail = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const {
		orderByIdFetchState,
		getOrderById,
		order,
		getPurchaseProofFile, proofLoading,
	} = useOrdersBackoffice();
	const { t } = useTranslation('translations');
	const { toast } = useNotificationsContext();

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getOrderById(parseInt(id, 10));
		}
	}, []);

	const orderStatusTag = (status: OrderBackofficeStatus) => {
		const { color, bgColor } = getOrderStatusColors(status.id);
		return (
			<Tag
				value={status.name}
				style={{ backgroundColor: bgColor, color }}
			/>
		);
	};

	const priceBodyTemplate = (rowData: OrderItemsModel) => new Intl.NumberFormat('pt-EU', {
		style: 'currency',
		currency: 'EUR',
	}).format(rowData.priceWithTaxes);

	const priceTotalTemplate = (rowData: OrderItemsModel) => new Intl.NumberFormat('pt-EU', {
		style: 'currency',
		currency: 'EUR',
	}).format(rowData.priceWithTaxes * rowData.quantity);

	const handleProofClick = () => {
		const orderId = parseInt(id || '0', 10);
		getPurchaseProofFile(orderId).then((result) => {
			if (result.success && result.file) {
				const fileUrl = window.URL.createObjectURL(result.file);
				const a = document.createElement('a');
				a.href = fileUrl;
				a.download = result.file.name;
				document.body.appendChild(a);
				a.click();
				a.remove();
			} else {
				toast.current?.show({
					severity: 'error',
					summary: 'Falha',
					detail: result.message,
				});
			}
		});
	};

	if (orderByIdFetchState.loading || !order) return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />;

	return (
		<Box>
			<Box>
				<BackButton onClick={() => navigate(-1)} />
			</Box>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Stack direction={{ md: 'row', xs: 'column' }}>
					<Typography fontFamily={inter}>{t('order_id')}</Typography>
					<Typography color="secondary" sx={{ ml: 1 }}>
						#
						{' '}
						{order?.id}
					</Typography>
					<Typography sx={{ ml: 2 }} fontFamily={inter}>{t('order_date')}</Typography>
					<Typography color={theme.palette.textLight.main} sx={{ ml: 1 }}>
						{moment(order?.createdAt).format('DD MMMM YYYY')}
					</Typography>
				</Stack>
				<Stack direction="row" spacing={2}>
					<Tag icon={proofLoading ? <CircularProgress sx={{ color: '#B9C92B', m: 0.5, mr: 1 }} size={18} /> : <DownloadForOfflineIcon sx={{ mr: 1 }} />} value="Comprovativo de compra" onClick={handleProofClick} style={{ cursor: 'pointer' }} />
					{order && orderStatusTag(order?.status)}
				</Stack>
			</Stack>
			<SizedBox height={2} />
			<Grid container spacing={3}>
				<Grid item md={4} xs={12}>
					<Usercard
						fullName={order?.customer?.fullName}
						lastOrderDate={order?.customer?.lastOrderDate}
						hasAccount={order?.customer?.hasAccount}
						phoneNumber={order?.customer.phoneNumber}
						email={order?.customer?.email}
						taxId={order?.customer?.taxId}
					/>
					<SizedBox height={3} />
					<PriceCard amounts={order?.amounts} />
				</Grid>
				<Grid item md={8} xs={12}>
					<Card
						elevation={0}
						sx={{
							bgcolor: theme.palette.background.default,
							pt: Dimens.CARD_PADDING_VERTICAL,
							pb: Dimens.CARD_PADDING_VERTICAL,
							pl: Dimens.CARD_PADDING_HORIZANTAL,
							pr: Dimens.CARD_PADDING_HORIZANTAL,
						}}
					>
						<Stack
							alignItems="center"
							justifyContent="center"
							component="span"
							sx={{
								height: Dimens.ICON_BOX_DIEMENSIONS,
								width: Dimens.ICON_BOX_DIEMENSIONS,
								borderRadius: Dimens.AVATAR_BORDER_RADIUS,
								bgcolor: theme.palette.secondary.main,
								color: theme.palette.secondary.contrastText,
							}}
						>
							<LocationOn />
						</Stack>
						<SizedBox height={3} />
						<Grid container spacing={2}>
							<Grid item md={5} xs={12}>
								<Typography
									fontFamily={inter}
									color={theme.palette.textLight.main}
								>
									Morada
								</Typography>
								<Typography
									fontFamily={inter}
									color={theme.palette.textMain.main}
								>
									{order?.shippingAddress.streetAddress}
									,
									{' '}
									{order.shippingAddress.zipCode}
									,
									{' '}
									{order.shippingAddress.cityName}
								</Typography>
							</Grid>
							<Grid item md={5} xs={12}>
								<Typography
									fontFamily={inter}
									color={theme.palette.textLight.main}
								>
									Morada de faturação
								</Typography>
								<Typography
									fontFamily={inter}
									color={theme.palette.textMain.main}
								>
									{order?.billingAddress.streetAddress}
									,
									{' '}
									{order.billingAddress.zipCode}
									{' '}
									,
									{order.billingAddress.cityName}
								</Typography>
							</Grid>
						</Grid>
						<SizedBox height={2} />
						{/*
						<Alert severity="error" sx={{
							width: '70%', bgcolor: theme.palette.secondary.light, fontFamily: inter }}>
							Caso não selecione uma opção de envio o cliente não poderá efetuar o pagamento.
						</Alert>
						<SizedBox height={2} />
						<Grid container spacing={3}>
							<Grid item md={5} xs={12}>
								<TextInput disabled
								label="Referência a pagamento" placeholder="Escreva aqui a referência" />
							</Grid>
							<Grid item md={5} xs={12}>
								<TextInput disabled
								label="Portes de envio" placeholder="Coloque aqui o valor dos portes" />
							</Grid>
						</Grid>
						*/}
						<SizedBox height={2} />
						<Grid container spacing={3}>
							<Grid item md={10} xs={12}>
								<TextareaAutosize
									disabled
									minRows={6}
									style={{
										width: '100%',
										backgroundColor: theme.palette.secondary.light,
									}}
								/>
							</Grid>
						</Grid>
						<SizedBox height={2} />
						<DefaultButton disabled sx={{ borderRadius: '5px' }}>
							Enviar mensagem
						</DefaultButton>
					</Card>
				</Grid>
			</Grid>
			<SizedBox height={3} />
			<Card
				elevation={0}
				sx={{
					bgcolor: theme.palette.background.default,
					pt: Dimens.CARD_PADDING_VERTICAL,
					pb: Dimens.CARD_PADDING_VERTICAL,
					pl: Dimens.CARD_PADDING_HORIZANTAL,
					pr: Dimens.CARD_PADDING_HORIZANTAL,
				}}
			>
				<Stack direction="row">
					<Typography fontFamily={inter}>{t('all_item')}</Typography>
				</Stack>
				<SizedBox height={3} />
				<Box sx={{ overflow: 'auto' }}>
					<Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
						<DataTable
							value={order.items}
							totalRecords={order.itemsQuantity}
							editMode="row"
							loading={orderByIdFetchState.loading}
							dataKey="id"
							tableStyle={{ minWidth: '50rem' }}
						>

							<Column
								// eslint-disable-next-line react/no-unstable-nested-components
								body={(rowData: OrderItemsModel) => (
									<Typography>
										{rowData.product.reference}
									</Typography>
								)}
								header={t('reference')}
							/>

							<Column
								// eslint-disable-next-line react/no-unstable-nested-components
								body={(rowData: OrderItemsModel) => (
									<Typography>
										{rowData.product.barcode}
									</Typography>
								)}
								header="EAN"
							/>

							<Column
								field="product"
								// eslint-disable-next-line react/no-unstable-nested-components
								body={(rowData: OrderItemsModel) => (
									<Typography>
										{rowData.product.name}
									</Typography>
								)}
								header="Nome do Produto"
							/>

							<Column
								field="amount"
								align="center"
								header="Preço uni."
								body={(options) => priceBodyTemplate(options)}
							/>

							<Column
								field="itemsQuantity"
								header="Quantidade"
								align="center"
								// eslint-disable-next-line react/no-unstable-nested-components
								body={(rowData: OrderItemsModel) => (
									<Typography sx={{ fontWeight: 600 }}>
										{`${rowData.quantity}`}
									</Typography>
								)}
							/>

							<Column
								align="center"
								header="Total"
								body={(options) => priceTotalTemplate(options)}
							/>
						</DataTable>
					</Box>
				</Box>
			</Card>
		</Box>
	);
};

export default OrderDetail;
