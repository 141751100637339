import { Either } from '@ajtec/either-typescript';
import { ApiResponse } from '@/common/domain/entities/api-response';
import { Catelog } from '../entities/catelog';
import { ICatelogRepository } from '../repositories/catelog-repository';

export class GetCatelogs {
	repository: ICatelogRepository;

	constructor(homeRepository: ICatelogRepository) {
		this.repository = homeRepository;
	}

	call(): Promise<Either<string, ApiResponse<Catelog[]>>> {
		return this.repository.getCatelogs();
	}
}
