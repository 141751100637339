/* eslint-disable no-useless-constructor */
import { FieldValidation } from '../../protocols/field-validation';
import { CompareFieldValidation } from '../compare-fields/compare-fields-validation';
import { EmailValidation } from '../email/email-validation';
import { NIFValidation } from '../nif/nif-validations';
import { RequiredFieldValidation } from '../required-field/required-field-validation';

export class ValidationBuilder {
	private constructor(
    private readonly fieldName: keyof object,
    private readonly validations: FieldValidation[],
	) {}

	static field(fieldName: keyof object): ValidationBuilder {
		return new ValidationBuilder(fieldName, []);
	}

	required(): ValidationBuilder {
		this.validations.push(new RequiredFieldValidation(this.fieldName));
		return this;
	}

	email(): ValidationBuilder {
		this.validations.push(new EmailValidation(this.fieldName));
		return this;
	}

	nif(): ValidationBuilder {
		this.validations.push(new NIFValidation(this.fieldName));
		return this;
	}

	// min(length: number): ValidationBuilder {
	// 	this.validations.push(new MinLengthValidation(this.fieldName, length));
	// 	return this;
	// }

	sameAs(fieldToCompare: string): ValidationBuilder {
		this.validations.push(new CompareFieldValidation(this.fieldName, fieldToCompare));
		return this;
	}

	build(): FieldValidation[] {
		return this.validations;
	}
}
