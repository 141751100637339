import Dimens from 'assets/dimens';
import { Box } from '@mui/material';
import React from 'react';

export const GuaranteesAndReturns = () => (
	<Box justifyContent="center" display="flex" sx={{ padding: '16px', mb: 16 }}>
		<Box maxWidth={Dimens.PAGE_MAX_WIDTH} flex={1}>
			<h2>
				Garantias e Devoluções
			</h2>

			<section id="content" className="page-content page-cms page-cms-6 col-xs-12 col-sm-12">
				<p style={{ fontSize: '16px', fontWeight: '500', color: '#b9c92b' }}>Avarias e Garantias</p>
				<p style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				>
					Todos
					os produtos comercializados pela VOLT não são fabricados pela mesma. No
					momento da venda, a VOLT passa para o utilizador a garantia desse(s)
					mesmo(s) produto(s).
				</p>
				<p style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				>
					<strong>1. Equipamentos dentro de Garantia</strong>
					&nbsp;
					<br />
					Nos termos do
					disposto no Decreto-Lei n.º 67/2003, de 8 de Abril, com alterações
					introduzidas pelo Decreto-Lei n.º 84/2008, de 21 de Maio, todos os produtos
					vendidos em estado de novo serão sujeitos a uma garantia de conformidade de
					2 anos, assegurada pelas respetivas marcas. No caso de produto com defeito,
					deve comunicar o mesmo à VOLT, que se encarregará da gestão deste
					processo.&nbsp;Havendo acordo por parte da VOLT e do cliente, todos os
					produtos vendidos em estado de usado têm uma garantia de 12
					meses.&nbsp;Produtos consumíveis, cuja utilização regular importa a sua
					destruição ou alienação, devido à sua específica natureza poderão,
					dependendo do fabricante, ter um prazo de garantia de bom funcionamento
					inferior a 2 anos.
					<br />
					<br />
					<strong>
						2. Garantia do Serviço de
						Reparação
					</strong>
					&nbsp;
					<br />
					A garantia do serviço de reparação da VOLT é
					de 90 dias, a contar da data de finalização da prestação de serviço. Esta
					garantia limitada não afeta os direitos legais estabelecidos na legislação
					nacional, sobre os componentes novos utilizados na reparação. A garantia das
					reparações não contempla as seguintes condições:
				</p>
				<ul style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				>
					<li>
						• Danos físicos (equipamento partido/danificado/corroído/empenado,
						etc.);
					</li>
					<li>
						• Uso indevido, danos causados por quedas, negligência, fogo, água,
						perturbações elétricas, atmosféricas, etc;
					</li>
					<li>
						• Danos causados por transporte efetuado pelo Cliente ou terceiros em
						sua representação;
					</li>
					<li>
						• Utilização de opções ou consumíveis não apropriados ao equipamento em
						questão;
					</li>
					<li>
						• Durante este período o equipamento tiver sofrido intervenção técnica,
						com mão-de-obra e peças alheias aos serviços da VOLT;
					</li>
					<li>
						• A reparação anterior tiver sido efetuada sob pedido e responsabilidade
						do cliente.
					</li>
				</ul>
				<p style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				>
					<strong>3. Avarias</strong>
					&nbsp;
					<br />
					Caso o produto adquirido apresente
					defeito ou avaria, o cliente deverá comunicar a situação ao&nbsp;
					<a
						href="https://www.volt.pt/pt/contactos"
					>
						Apoio ao Cliente
					</a>
					&nbsp;da
					VOLT, informando o número da sua encomenda e a descrição do mau
					funcionamento.&nbsp;Após a receção do artigo, nas instalações da VOLT, este
					será enviado para o representante da marca e, em caso de ausência de
					qualquer inconformidade, o artigo será substituído ou reparado. Neste caso,
					a VOLT procederá à entrega do artigo ao cliente, pelo mesmo meio que foi
					entregue para reparação.&nbsp;Se a verificação técnica detetar sinais de mau
					uso, e/ou qualquer problema que possa ter causado o mau funcionamento do
					artigo (por ex: queda, humidade, etc.) o cliente será contactado para
					indicar se pretende que se proceda à reparação do equipamento. Nesse caso a
					VOLT fornecerá um orçamento gratuito.
					<br />
					<br />
					<strong>
						4. Equipamentos fora de
						Garantia
					</strong>
					&nbsp;
					<br />
					Mesmo que o seu equipamento esteja fora do
					prazo de garantia de conformidade, a VOLT disponibiliza um serviço de
					reparação em condições a acordar, pelo que deverá dirigir-se a uma das
					nossas lojas.&nbsp;Caso o cliente pretenda a reparação de um equipamento
					fora de garantia deverá contactar o&nbsp;
					<a
						href="https://www.volt.pt/s/pedido-de-orcamento/"
					>
						Centro
						Técnico
					</a>
					&nbsp;da VOLT indicando o equipamento em questão e a sua
					avaria. A VOLT informará o cliente sobre a morada na qual terá que entregar
					o equipamento. No caso desta opção não ser possível, o cliente poderá enviar
					o produto por correio registado para a morada indicada, ao cuidado do Centro
					Técnico da VOLT.&nbsp;Aquando a receção do equipamento do cliente, os
					técnicos da VOLT procederão à análise do mesmo verificando a avaria descrita
					pelo cliente. Após essa análise, o cliente será contactado pela nossa equipa
					para ser informado sobre o orçamento para a reparação do equipamento.&nbsp;A
					reparação só será iniciada após aceitação expressa do orçamento por parte do
					Cliente.
					<br />
					<br />
					<strong>
						5. Garantia Direta com o
						fabricante
					</strong>
					&nbsp;
					<br />
					Algumas marcas disponibilizam
					garantia/suporte direto ao Cliente. Nestes casos pode entrar em contacto
					diretamente com a marca para tratar da garantia do equipamento.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				/>
				<p style={{ fontSize: '16px', fontWeight: '500', color: '#b9c92b' }}>
					Política de Devoluções
				</p>
				<p style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				>
					Quando
					o utilizador compra um produto neste site está automaticamente coberto pela
					Garantia de Satisfação, onde nos primeiros
					{' '}
					<span style={{ color: '#d0121a' }}>
						14
						dias
					</span>
					, após tomar posse física dos bens, pode devolver ou trocar o
					produto, de acordo com o Decreto-Lei n.º 24/2014, de 14 de fevereiro.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				>
					<strong>1. Encargos</strong>
					&nbsp;
					<br />
					Nos casos de livre Resolução de
					Contrato, os portes de devolução ficam a cargo do consumidor, conforme
					estipulado no&nbsp;n.º 2 do artigo 13º do Decreto-Lei n.º 24/2014, de 14 de
					fevereiro.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				>
					<strong>2. Condições</strong>
					&nbsp;
					<br />
					Apesar de serem aceites
					devoluções/trocas nos primeiros 14 dias em compras à distância (conforme
					descrito acima), os bens devolvidos só serão aceites caso
					respeitem&nbsp;rigorosamente&nbsp;as seguintes condições:&nbsp;O artigo
					devolvido tem de estar intacto e sem qualquer marca/dano de uso;

				</p>
				<ul style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				>
					<li>
						• Terá de ser devolvido o artigo juntamente com a sua embalagem, manuais
						e acessórios, em estado imaculado;

					</li>
					<li>
						• Juntamente com o bem devolvido, deverá estar a acompanhar o mesmo, a
						fatura onde consta esse mesmo artigo;

					</li>
					<li>
						• No caso de ser um artigo da Apple ou um artigo propositadamente
						encomendado por vontade do cliente não pode estar com os selos da marca
						quebrados;

					</li>
					<li>
						• O Serial Number do equipamento não poderá ter sido associado a nenhuma
						conta online da marca do produto.

					</li>
				</ul>
				<p style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				>
					No caso
					da violação de um destes pontos, não poderemos aceitar a devolução (e o
					consequente reembolso) ou a troca. Salvo anomalia técnica, existem artigos
					que pela sua natureza (por questões de higiene, segurança, risco de
					transmissão de dados, recomendações do fabricante, etc) não podem ser
					aceites para devoluções/trocas.&nbsp;As encomendas efetuadas pela Loja
					Online e em que o pagamento tenha sido realizado apenas no ato de
					levantamento em Loja Física, não são abrangidas pelo Decreto de Lei
					mencionado no ponto 1. Uma vez que o cliente tem acesso ao produto que está
					a comprar antes de fazer o respetivo pagamento.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				>
					<strong>
						3. Trocas ou Devoluções
						<br />
					</strong>
					Para dar início ao processo de
					devolução/troca, o cliente deve:

				</p>
				<ol style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				>
					<li>
						<strong>1.</strong>
						{' '}
						Aceder à página Minha Conta;
					</li>
					<li>
						<strong>2.</strong>
						{' '}
						{'Selecionar a opção "Devolução de Produtos" e '}
						escolher a encomenda que pretende devolver/trocar
						;

					</li>
					<li>
						<strong>3.</strong>
						{' '}
						Escolher o produto que pretende devolver/trocar;
					</li>
					<li>
						<strong>4.</strong>
						{' '}
						Descrever o motivo;
					</li>
					<li>
						<strong>5.</strong>
						{' '}
						Inserir o IBAN, caso o pagamento tenha sido feito em
						Transferência Bancária, Multibanco, Payshop, Mbway ou numerário;

					</li>
					<li>
						<strong>6.</strong>
						{' '}
						Inserir o Tipo de Devolução: Garantia ou
						Devolução/Troca;

					</li>
					<li>
						<strong>7.</strong>
						{' '}
						Adicionar fotografias do estado do produto.
					</li>
				</ol>
				<p style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				>
					Após o
					envio deste formulário o cliente deve aguardar a análise do seu pedido por
					parte da VOLT. No caso do pedido ser aceite, o cliente deverá enviar o
					produto para a morada indicada nas instruções recebidas. Mediante a
					verificação do cumprimento das condições do número anterior, a VOLT irá dar
					seguimento ao pedido e procederá à troca ou reembolso do produto como
					solicitado. Caso o produto não respeite as condições este será devolvido ao
					cliente nas mesmas condições que foi recebido.

				</p>
				<p style={{
					fontSize: '14px', fontWeight: 400, color: '#585858', lineHeight: '1.6',
				}}
				>
					Os
					reembolsos serão efetuados por transferência bancária para o IBAN indicado
					no formulário de devolução ou por PayPal, caso tenha sido este o método de
					pagamento. Este procedimento não se aplica a revendedores.

				</p>

			</section>
		</Box>
	</Box>
);
