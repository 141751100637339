import { inter, zilaSlab } from 'assets/theme/theme';
import {
	Box,
	Stack,
	Typography,
	useTheme,
	Switch,
	Checkbox,
	SvgIcon,
} from '@mui/material';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useCustomersBackoffice from '../../provider/customer-backoffice.provider';
import { TextInput } from '../../components';
import Dimens from 'assets/dimens';
import { Checked, Unchecked } from 'assets/icons';
import { SizedBox } from 'common/presentation/components';
import useSettingsBackoffice from '../../provider/settings-backoffice.provider';

const Clients = () => {
	const theme = useTheme();
	const toast = useRef<Toast>(null);
	const { t } = useTranslation('translations');
	const [lazyState, setlazyState] = useState({
		first: 0,
		rows: 30,
		page: 1,
		sortField: null,
		sortOrder: null,
		filters: {},
	});
	const [totalOfCustomerGroups, setTotalOfCustomerGroups] = useState<number>(0);
	const {
		getCustomerGroups,
		customerGroups,
		setCustomerGroup,
		customerGroupsState,
	} = useCustomersBackoffice();

	const {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		updateGroup,
	} = useSettingsBackoffice();

	useEffect(() => {
		getCustomerGroups({ size: lazyState.rows, page: lazyState.page }).then((res) => {
			setTotalOfCustomerGroups(res.total);
		});
	}, []);
	const onPage = (event: any) => {
		setlazyState(event);
	};
	const getShippingFeeLabel = (options: any) => {
		const { shouldPayShipmentFee } = options;
		const label = shouldPayShipmentFee ? t('yes') : t('no');
		return <Typography>{label}</Typography>;
	};

	const getDiscountTemplate = (options: any) => {
		const { discount } = options;
		let label = '';
		if (!discount) {
			return <Typography>0</Typography>;
		}
		const { fixedValue, percentageValue } = discount;
		if (fixedValue) {
			label = new Intl.NumberFormat('pt-EU', {
				style: 'currency',
				currency: 'EUR',
			}).format(fixedValue);
		} else if (percentageValue) {
			label = `${percentageValue}%`;
		}
		return <Typography>{label}</Typography>;
	};

	const shippingFeeCheck = (options: any) => (
		<Switch
			color="secondary"
			checked={options?.value}
			onChange={(e) => options.editorCallback(e.target.checked)}
		/>
	);

	const inputTextEditor = (options: any) => (
		<TextInput
			value={options?.value}
			type="text"
			onChange={(e) => options.editorCallback(e.target.value)}
		/>
	);

	const discountEditor = (options: any) => {
		const percentageValue = !!options?.value?.percentageValue;
		const fixedValue = !!options?.value?.fixedValue;
		const value = options?.value?.percentageValue || options?.value?.fixedValue;
		const checkedPercModel = { fixedValue: 0, percentageValue: value || 10, isPercentage: true };
		const checkedAbsModel = { fixedValue: value || 10, percentageValue: 0, isPercentage: false };
		return (
			<Box>
				<Stack direction="row" gap={4}>
					<Stack direction="row" alignItems="center">
						<Checkbox
							icon={<SvgIcon component="div"><Unchecked /></SvgIcon>}
							checkedIcon={<SvgIcon component="div"><Checked /></SvgIcon>}
							checked={percentageValue}
							onChange={(e) => options.editorCallback(
								e.target.checked ? checkedPercModel : checkedAbsModel,
							)}
						/>
						<Typography
							color={theme.palette.textLight.main}
							fontSize={Dimens.FONT_SIZE_SMALL}
							fontFamily={inter}
							fontWeight={400}
							sx={{ ml: 0.5 }}
						>
							Percentual %
						</Typography>
					</Stack>
					<Stack direction="row" alignItems="center">
						<Checkbox
							checked={fixedValue}
							icon={<SvgIcon component="div"><Unchecked /></SvgIcon>}
							checkedIcon={<SvgIcon component="div"><Checked /></SvgIcon>}
							onChange={(e) => options.editorCallback(
								e.target.checked ? checkedAbsModel : checkedPercModel,
							)}
						/>
						<Typography
							color={theme.palette.textLight.main}
							fontSize={Dimens.FONT_SIZE_SMALL}
							fontFamily={inter}
							fontWeight={400}
							sx={{ ml: 0.5 }}
						>
							Exato €
						</Typography>
					</Stack>
				</Stack>
				<SizedBox height={1} />
				<TextInput
					type="number"
					value={value}
					onChange={(e) => {
						if (!options?.value.isPercentage || fixedValue) {
							options.editorCallback(
								{ fixedValue: e.target.value, percentageValue: 0, isPercentage: false },
							);
						} else {
							options.editorCallback(
								{ fixedValue: 0, percentageValue: e.target.value, isPercentage: true },
							);
						}
					}}
				/>
			</Box>
		);
	};

	const onRowEditComplete = async (e: any) => {
		const { newData, index } = e;
		try {
			const result = await updateGroup(newData);

			if (typeof result === 'boolean') {
				toast.current?.show({
					severity: 'error',
					summary: 'Falha',
					detail: 'Uma ou mais informação não foi possível ser salva.',
				});
				return;
			}

			const listGroups = [...customerGroups];
			listGroups[index] = result;
			setCustomerGroup(listGroups);

			toast.current?.show({
				severity: 'success',
				summary: 'Sucesso',
				detail: 'Item atualizado com sucesso',
			});
		} catch (error) {
			toast.current?.show({
				severity: 'error',
				summary: 'Falha',
				detail: 'Uma ou mais informação não foi possível ser salva.',
			});
		}
	};

	return (
		<Box sx={{
			bgcolor: theme.palette.background.default,
			pl: 2,
			pt: 2,
			pb: 2,
		}}
		>
			<Toast ref={toast} />
			<Stack direction="row" sx={{ p: 2 }}>
				<Typography fontSize={22} fontFamily={zilaSlab} fontWeight={400}>
					{t('clients_categories')}
				</Typography>
			</Stack>
			<Box sx={{ overflow: 'auto' }}>
				<Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
					<DataTable
						value={customerGroups}
						lazy
						paginator
						first={lazyState.first}
						onPage={onPage}
						rows={lazyState.rows}
						onRowEditComplete={onRowEditComplete}
						editMode="row"
						totalRecords={totalOfCustomerGroups}
						loading={customerGroupsState.loading}
						dataKey="id"
						tableStyle={{ minWidth: '50rem' }}
					>
						<Column field="id" bodyStyle={{ fontSize: 14 }} header="ID" />
						<Column
							field="name"
							bodyStyle={{ fontSize: 14 }}
							header={t('name')}
							editor={(options) => inputTextEditor(options)}
						/>
						<Column
							field="description"
							bodyStyle={{ fontSize: 14 }}
							header={t('description')}
							editor={(options) => inputTextEditor(options)}
						/>
						<Column
							field="shouldPayShipmentFee"
							bodyStyle={{ fontSize: 14 }}
							header={t('shipping_fee')}
							body={(options) => getShippingFeeLabel(options)}
							editor={(options) => shippingFeeCheck(options)}
						/>
						<Column
							field="discount"
							bodyStyle={{ fontSize: 14 }}
							header={t('discount')}
							body={(options) => getDiscountTemplate(options)}
							editor={(options) => discountEditor(options)}
						/>
						<Column
							rowEditor
							headerStyle={{ width: '10%', minWidth: '8rem' }}
							bodyStyle={{ textAlign: 'center' }}
						/>
					</DataTable>
				</Box>
			</Box>
		</Box>
	);
};

export default Clients;
