import { ValidationComposite } from 'common/presentation/validation/validators';
import { ValidationBuilder } from 'common/presentation/validation/validators/builder/validation-builder';

export const makeRegisterValidation = (): ValidationComposite => ValidationComposite.build([
	...ValidationBuilder.field('name' as keyof object).required().build(),
	...ValidationBuilder.field('email' as keyof object).required().email().build(),
	...ValidationBuilder.field('password' as keyof object).required().build(),
	...ValidationBuilder.field('repeatPassword' as keyof object).sameAs('password').build(),
]);

export const makeLoginValidation = (): ValidationComposite => ValidationComposite.build([
	...ValidationBuilder.field('email' as keyof object).required().email().build(),
	...ValidationBuilder.field('password' as keyof object).required().build(),
]);
