/* eslint-disable indent */
import useAuth from 'features/authentication/presentation/provider/auth.provider';
import CloseIcon from '@mui/icons-material/Close';
import {
	CircularProgress,
	Divider, Grid, IconButton, Stack, useMediaQuery, useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Dimens from 'assets/dimens';
import { AtField, Key } from 'assets/icons';
import { DefaultButton } from 'common/presentation/components';
import TextField from 'common/presentation/components/text-field/TextField';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeLoginValidation } from 'features/authentication/presentation/builder/authentication-factory';
import AppLinks from 'assets/applinks.routes';
import { useNavigate } from 'react-router-dom';

const style = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
};

type TModalFormCustomerProps = {
	open: boolean;
	onOpenChange: (value: boolean) => void;
	onClickNoAuth: () => void;
	onClickCreateAccount: () => void;
}

export const ModalFormCustomer: React.FC<TModalFormCustomerProps> = ({
	onClickNoAuth,
	open,
	onOpenChange,
	onClickCreateAccount,
}) => {
	const handleClose = () => onOpenChange(false);
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const { login, loading, error } = useAuth();
	const [{ email, password }, setCredentials] = useState({ email: '', password: '' });
	const [{ emailError, passwordError }, setErrors] = useState({ emailError: '', passwordError: '' });
	const validation = makeLoginValidation();

	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const formData = { email, password };

		const emailErrorV = validation.validate('email', formData);
		const passwordErrorV = validation.validate('password', formData);

		const isFormValid = !(!!emailErrorV || !!passwordErrorV);

		setErrors({ emailError: emailErrorV, passwordError: passwordErrorV });

		if (isFormValid) {
			login(email, password).then((result) => {
				if (result) {
					navigate(AppLinks.PURCHASE);
				}
			});
		}

		return false;
	};

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setCredentials((prev) => ({ ...prev, [event.target.name]: event.target.value }));
	};

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				sx={{ overflow: 'scroll' }}
			>
				<Box sx={{ ...style, width: isMd ? 550 : '90%' }}>
					<Box dir="rtl">
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Box>

					<Box textAlign="center">
						<Typography
							sx={{
								color: theme.palette.primary.contrastText,
								fontSize: Dimens.FONT_SIZE_MEDIUM,
								fontWeight: Dimens.FONT_BOLDER,
							}}
						>
							{t('modal_form_customer_title')}
						</Typography>
						<Typography sx={{ paddingInline: 3, mb: 2 }}>
							{t('modal_form_customer_subtitle')}
						</Typography>

						<DefaultButton onClick={onClickNoAuth}>
							{t('modal_form_customer_btn_buy')}
						</DefaultButton>

						<Divider sx={{ mt: 2, mb: 2 }} />
					</Box>
					<Stack mt={5} alignItems="center" sx={{ textAlign: 'center' }}>
						<Typography color="red" sx={{ textAlign: 'center' }}>
							{error}
						</Typography>
					</Stack>
					<form onSubmit={onSubmit}>
						<Grid container spacing={2}>
							<Grid item md={12} xs={12}>
								<TextField
									name="email"
									placeholder="Email"
									error={!!emailError}
									helperText={emailError}
									onChange={handleChange}
									fullWidth
									startIcon={<AtField />}
								/>
							</Grid>
							<Grid item md={12} xs={12}>
								<TextField
									name="password"
									type="password"
									error={!!passwordError}
									helperText={passwordError}
									placeholder={t('senha')}
									fullWidth
									onChange={handleChange}
									startIcon={<Key />}
								/>
								{/* <Stack justifyContent="space-between" alignItems="center" direction="row">
									<FormControlLabel
										control={<Checkbox defaultChecked size="small" />}
										label={(
											<BodyText sx={{}}>
												{t('keep_session')}
											</BodyText>
										)}
									/>
									<BodyText sx={{}}>
											{t('forgot_password')}
										</BodyText>
								</Stack> */}
							</Grid>
							<Grid item md={12} xs={12}>
								<Stack>
									<DefaultButton type="submit" disabled={loading} sx={{ alignSelf: 'center', minWidth: 100 }}>
										{loading && <CircularProgress size={30} />}
										{!loading && t('enter')}
									</DefaultButton>
								</Stack>
							</Grid>
						</Grid>
					</form>
					<Divider sx={{ mt: 2, mb: 2 }} />
					<Box textAlign="center">
						<DefaultButton onClick={() => onClickCreateAccount()}>
							{' '}
							{t('modal_create_account_btn')}
						</DefaultButton>
					</Box>
				</Box>
			</Modal>
		</div>
	);
};
