import React from 'react';
import {
	Box, Toolbar, AppBar as MuiAppBar, Stack, SxProps,
} from '@mui/material';
import Dimens from 'assets/dimens';

interface AppBarProps<T> {
	color?: 'inherit' | 'primary' | 'secondary' | 'default';
	position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative';
	appBarSx?: SxProps,
	appBarElevation?: number,
	logo: React.ReactNode;
	routes: T[];
	renderRoutes: (item: T, index: number) => React.ReactNode;
	renderEndComponent: React.ReactNode
}

const AppBar = <T, >(props: AppBarProps<T>) => {
	const {
		color = 'primary',
		position = 'fixed',
		appBarSx = {
			height: Dimens.APP_BAR_HEIGHT,
			justifyContent: 'center',
			p: 0,
		},
		appBarElevation = 0,
		logo,
		routes,
		renderRoutes,
		renderEndComponent,
	} = props;
	return (
		<header>
			<Box sx={{ flexGrow: 1 }}>
				<MuiAppBar
					position={position}
					color={color}
					sx={appBarSx}
					elevation={appBarElevation}
				>
					<Toolbar>
						<Box sx={{
							display: 'flex',
							flex: 1,
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
						>
							<Box sx={{ mr: { md: 1, xs: 2 } }}>
								{logo}
							</Box>
							<Box sx={{
								display: { md: 'flex', xs: 'none' },
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								flex: 1,
							}}
							>
								<Stack
									direction="row"
									justifyContent="space-evenly"
									sx={{
										flex: 1,
									}}
								>
									{routes.map((el, index) => (
										<span key={`app-bar-${index + 1}`}>
											{renderRoutes(el, index)}
										</span>
									))}
								</Stack>
							</Box>
							<Box>
								{renderEndComponent}
							</Box>
						</Box>
					</Toolbar>
				</MuiAppBar>
			</Box>
		</header>
	);
};

export default AppBar;
