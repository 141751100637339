import { Either } from '@ajtec/either-typescript';
import { IProductRepository } from '../repository/product-repository';
import { Product } from '../entities/Product';

export class GetProductById {
	repository: IProductRepository;

	constructor(productRepository: IProductRepository) {
		this.repository = productRepository;
	}

	call(id: number): Promise<Either<string, Product>> {
		return this.repository.getProductById(id);
	}
}
