/* eslint-disable no-useless-constructor */
import { RequiredFieldError } from '../../errors';
import { FieldValidation } from '../../protocols/field-validation';

export class RequiredFieldValidation implements FieldValidation {
	constructor(readonly field: keyof object) {}

	validate(input: object): Error | null {
		return input[this.field] ? null : new RequiredFieldError();
	}
}
