import { ApiResponse } from 'common/domain/entities/api-response';
import { UserBackofficeModel, SimpleUserBackofficeModel, SimpleUserBackofficeRolesModel } from '../dto/user-backoffice-model';
import { FilterParams, UserApi } from './user-api';
import http from './http';
import axios, { AxiosError } from 'axios';
import { serializeFormQuery } from 'common/utils/general.utils';

export class UserApiImpl implements UserApi {
	apiService = http;

	async getUserByFilter(filter: FilterParams): Promise<ApiResponse<UserBackofficeModel[]>> {
		try {
			const query = serializeFormQuery(filter);
			const resp = await this.apiService.get(`/users${query}`);
			const data = resp.data as ApiResponse<UserBackofficeModel[]>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async loginBackofficeUser(
		username: string,
		password: string,
	): Promise<ApiResponse<UserBackofficeModel>> {
		try {
			const resp = await this.apiService.post('/users/authenticate', {
				username,
				password,
			});
			const data = resp.data as ApiResponse<UserBackofficeModel>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async createBackofficeUser(
		user: SimpleUserBackofficeModel,
	): Promise<ApiResponse<SimpleUserBackofficeRolesModel>> {
		try {
			const resp = await this.apiService.post('/users', {
				...user,
			});
			const data = resp.data as ApiResponse<SimpleUserBackofficeRolesModel>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async forgotPassword(userName: string): Promise<ApiResponse<void>> {
		try {
			const resp = await this.apiService.post('/users/forgot-password', {
				userName,
			});
			const data = resp.data as ApiResponse<void>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async resetPassword(
		username: string,
		token: string,
		newPassword: string,
	): Promise<ApiResponse<void>> {
		try {
			const resp = await this.apiService.post('/users/reset-password', {
				username,
				token,
				newPassword,
			});
			const data = resp.data as ApiResponse<void>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}
}
