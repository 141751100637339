import CloseIcon from '@mui/icons-material/Close';
import {
	Grid,
	IconButton, Stack, useMediaQuery, useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Dimens from 'assets/dimens';
import { DefaultButton } from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const style = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 500,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
};

type TModalConfirmOrder = {
	open: boolean;
	onOpenChange: (value: boolean) => void;
	onConfirmClick: () => void
}

export const ModalConfirmOrder: React.FC<TModalConfirmOrder> = ({
	open,
	onOpenChange,
	onConfirmClick,
}) => {
	const handleClose = () => {
		onOpenChange(false);
	};
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				sx={{ overflow: 'scroll' }}
			>
				<Box sx={{ ...style, width: isMd ? 500 : '90%' }}>
					<Box dir="rtl">
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Box>

					<Stack textAlign="center">
						<Typography
							sx={{
								color: theme.palette.primary.contrastText,
								fontSize: Dimens.FONT_SIZE_EXTRA_LARGE_MOBILE,
								fontWeight: Dimens.FONT_BOLDER,
							}}
						>
							{t('modal_confirm_order_title')}
						</Typography>
						<Typography mt={2} mb={2}>
							{t('final_amount_email')}
						</Typography>
						<Grid justifyContent="center" container gap={2}>
							<Grid item>
								<DefaultButton variant="outlined" fullWidth onClick={handleClose}>
									{t('cancel')}
								</DefaultButton>
							</Grid>
							<Grid item>
								<DefaultButton onClick={onConfirmClick}>
									{t('confirm')}
								</DefaultButton>
							</Grid>
						</Grid>
					</Stack>
				</Box>
			</Modal>
		</div>
	);
};
