/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import Dimens from 'assets/dimens';
import TitleSmall from 'common/presentation/components/title-small/TitleSmall';
import { useTranslation } from 'react-i18next';
import { DefaultDivider, SizedBox } from 'common/presentation/components';
import CatelogItem from './components/CatelogItem';
import useCatelogs from './provider/catelog.provider';

const ProductCatelogPage = () => {
	const { t } = useTranslation('translations');
	const { catelogs, getCatelogs } = useCatelogs();

	useEffect(() => {
		getCatelogs();
	}, [getCatelogs]);

	const handleSeeClick = (fileUrl: string) => {
		// console.log(fileUrl);
	};

	return (
		<Box sx={{ pb: Dimens.SECTION_PADDING_BOTTOM }}>
			<Box sx={{
				ml: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },
				mr: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },

			}}
			>
				<TitleSmall>
					{t('product_catalogs').toUpperCase()}
				</TitleSmall>
				<SizedBox height={1} />
				<DefaultDivider />
				<SizedBox height={6} />
			</Box>
			<Box sx={{
				pl: {
					md: Dimens.PAGE_PADDING_SMALL,
					sm: Dimens.PAGE_PADDING_MEDIUM_MOBILE,
					xs: Dimens.PAGE_PADDING_MEDIUM_MOBILE,
				},
				pr: {
					md: Dimens.PAGE_PADDING_SMALL,
					sm: Dimens.PAGE_PADDING_MEDIUM_MOBILE,
					xs: Dimens.PAGE_PADDING_MEDIUM_MOBILE,
				},
			}}
			>
				<Grid
					container
					spacing={3}
					md={24}
					justifyContent="center"
				>
					{catelogs && (catelogs.map((catelog) => (
						<Grid
							item
						>
							<CatelogItem
								title={catelog.name}
								image={catelog.imageUrl}
								fileLink={catelog.fileUrl}
								onSeeClick={() => handleSeeClick(catelog.fileUrl)}
							/>
						</Grid>
					)))}
				</Grid>
			</Box>
		</Box>
	);
};

export default ProductCatelogPage;
