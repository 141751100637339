import { Either, Left, Right } from '@ajtec/either-typescript';
import { IProductRepository } from '../../domain/repository/product-repository';
import { ProductApiImpl } from '../remote/product-api-impl';
import { Product } from '../../domain/entities/Product';
import { Brand } from '../../domain/entities/Brand';
import { FilterParams } from '../remote/product-api';
import { ApiResponse } from '../../../../common/domain/entities/api-response';
import { ProductCategoryModel } from '../dto/product-category-model';

export class ProductRepositoryImpl implements IProductRepository {
	productApi = new ProductApiImpl();

	async getProductById(id: number): Promise<Either<string, Product>> {
		try {
			const products = await this.productApi.getProductById(id);
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getProductsByFilter(params: FilterParams): Promise<Either<string, ApiResponse<Product[]>>> {
		try {
			const products = await this.productApi.getProductsByFilter(params);
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getBrands(): Promise<Either<string, Brand[]>> {
		try {
			const products = await this.productApi.getBrands();
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getCategories(): Promise<Either<string, ProductCategoryModel[]>> {
		try {
			const categories = await this.productApi.getCategories();
			return new Right(categories);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
