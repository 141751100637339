import {
	Checkbox, Fade, FormControlLabel, Stack, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { ProductFinishingMaterialModel } from '../../data/dto/product-finishing-materials-model';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type BrandMenuItemProps = {
	item: ProductFinishingMaterialModel,
	selectedColors: number[];
	onColorsChange?: (colors: number[]) => void;
};

export const BrandMenuItem: React.FC<BrandMenuItemProps> = (props) => {
	const {
		item,
		selectedColors,
		onColorsChange,
	} = props;
	const [expand, setExpand] = useState<boolean>(false);

	const onHandleExpand = () => {
		setExpand((prev) => !prev);
	};

	return (
		<Stack direction="column" ml={4}>
			<Stack
				direction="row"
				justifyContent="space-between"
				sx={{
					p: 0.2,
				}}
			>
				<Typography
					sx={{
						fontSize: 14,
						textTransform: 'capitalize',
						fontWeight: '500',
						flex: 1,
						cursor: 'pointer',
					}}
					onClick={onHandleExpand}
				>
					{item.name.toLocaleLowerCase()}
				</Typography>

				<KeyboardArrowDownIcon
					onClick={onHandleExpand}
					style={{
						transition: 'all 0.2s ease',
						transform: `rotate(${expand ? '0.5turn' : '0turn'})`,
					}}
				/>
			</Stack>

			<Fade in={expand} appear unmountOnExit>
				<Stack direction="column" ml={2}>
					{item.colors.map((color, key) => {
						const selected = selectedColors.indexOf(color.id) !== -1;
						return (
							<FormControlLabel
								key={`colors-${key.toString()}`}
								checked={selected}
								sx={{
									p: 0,
									ml: 0.5,
								}}
								control={(
									<Checkbox
										sx={{
											p: 0,
											pr: 0.1,
										}}
										onChange={(chbox) => {
											if (chbox.target.checked) {
												selectedColors.push(color.id);
											} else {
												const index = selectedColors.indexOf(color.id);
												if (index !== -1) {
													selectedColors.splice(index, 1);
												}
											}
											onColorsChange?.call(0, selectedColors);
										}}
									/>
								)}
								label={color.name}
							/>
						);
					})}
				</Stack>
			</Fade>
		</Stack>
	);
};
