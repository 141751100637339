import Dimens from 'assets/dimens';
import { AssignmentReturn, CarbonDeliveryParcel } from 'assets/icons';
import {
	Box, Stack, useTheme,
} from '@mui/material';
import React from 'react';
import {
	BodyText, DefaultButton, DefaultDivider, ListItemWithIcon,
} from 'common/presentation/components';
import { zilaSlab } from 'assets/theme/theme';
import CounterButton from 'common/presentation/components/counter-button/CounterButton';
import { StockStatus } from '../../domain/entities/Product';

interface ProductActionsProps {
	price: string;
	stockStatus?: StockStatus,
	minDeliveryDays?: number,
	maxDeliveryDays?: number,
	quantity: number,
	onIncrement?: (prevCount: number) => void
	onDecrement?: (newCount: number) => void
	onAddToCartClick: () => void;
	onFinishPurchaseClick: () => void
}

const ProductActions: React.FC<ProductActionsProps> = (props) => {
	const {
		price, stockStatus,
		onAddToCartClick, onFinishPurchaseClick,
		minDeliveryDays, maxDeliveryDays,
		quantity, onIncrement, onDecrement,
	} = props;
	const theme = useTheme();

	return (
		<Box sx={{ padding: Dimens.PAGE_PADDING_SMALL_MOBILE, border: `1px solid ${theme.palette.primary.main}` }}>
			{minDeliveryDays && minDeliveryDays && (<ListItemWithIcon title={`Entregas entre ${minDeliveryDays} a ${maxDeliveryDays} dias úteis`} icon={<CarbonDeliveryParcel />} />)}
			<ListItemWithIcon title="Política de retorno" icon={<AssignmentReturn />} />

			<Stack direction="column" alignItems="center" sx={{ mt: 6 }}>
				<BodyText sx={{ color: theme.palette.secondary.main, mb: 2 }}>
					{stockStatus?.name}
				</BodyText>
				<CounterButton
					count={quantity}
					onIncrement={onIncrement}
					onDecrement={onDecrement}
				/>
			</Stack>
			<Stack
				sx={{ mt: 5 }}
				direction="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<BodyText sx={{ fontFamily: zilaSlab }}>Preço c/ IVA</BodyText>
				<BodyText sx={{
					fontSize: Dimens.FONT_SIZE_LARGE_SMALL,
					fontFamily: zilaSlab,
					fontWeight: Dimens.FONT_BOLD,
					color: theme.palette.primary.contrastText,
				}}
				>
					{price}
				</BodyText>
			</Stack>
			<DefaultDivider sx={{ mt: 2, mb: 2 }} width="100%" />
			<Stack sx={{ mt: 2 }}>
				<DefaultButton
					onClick={() => onAddToCartClick()}
					fullWidth
				>
					Adicionar ao carrinho

				</DefaultButton>
				<DefaultButton onClick={onFinishPurchaseClick} variant="outlined" fullWidth sx={{ mt: 1.5 }}>Finalizar compra</DefaultButton>
			</Stack>
		</Box>
	);
};

export default ProductActions;
