/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { zilaSlab } from 'assets/theme/theme';
import { BackButton, DefaultButton, ProductImageView } from 'common/presentation/components';
import {
	Box,
	Card,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	SvgIcon,
	TextField,
	Typography,
} from '@mui/material';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Dropdown } from 'primereact/dropdown';
import { createBreadCrumb, getStockColors } from 'common/utils/general.utils';
import { StockStatus } from 'features/products/domain/entities/Product';
import { Tag } from 'primereact/tag';
import { Editor } from 'primereact/editor';
import { Galleria, GalleriaResponsiveOptions } from 'primereact/galleria';
import {
	FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent, FileUploadUploadEvent,
} from 'primereact/fileupload';
import LocalStorageWrapper, { LocalStorageKeys } from 'common/utils/storage-utils';
import { useNavigate, useParams } from 'react-router-dom';
import useProductBackoffice from '../../provider/product-backoffice.provider';
import { ProductModel } from '@/features/products/data/dto/product-model';
import { ProgressBar } from 'primereact/progressbar';
import { ApiResponse } from '@/common/domain/entities/api-response';
import { TreeNode } from 'primereact/treenode';
import {
	Tree, TreeCheckboxSelectionKeys, TreeExpandedKeysType, TreeMultipleSelectionKeys,
} from 'primereact/tree';
import useSearch from 'features/products/presentation/provider/search.provider';
import { ProductCategoryModel } from 'features/products/data/dto/product-category-model';
import { useMountEffect } from 'primereact/hooks';
import { Tax } from '@/features/products/domain/entities/Tax';
import { useTranslation } from 'react-i18next';
import { AppEnv } from 'common/constants/app-env';
import Tags from '../../components/tags/Tags';
import { currency } from 'common/utils/currency-utils';

export const ProductForm = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const [statuses] = useState<StockStatus[]>([
		{ stockStatusId: 1, name: 'Sem Stock', description: '' },
		{ stockStatusId: 2, name: 'Stock Baixo', description: '' },
		{ stockStatusId: 3, name: 'Stock Médio', description: '' },
		{ stockStatusId: 4, name: 'Stock Alto', description: '' },
	]);
	const { id } = useParams();
	const {
		getProductById,
		product,
		productState,
		updateProduct,
		productsUpdateState,
	} = useProductBackoffice();
	const { categories, getCategories } = useSearch();

	const [edit, setEdit] = useState<ProductModel>();
	const [images, setImages] = useState<string[]>([]);
	const [newImages, setNewImages] = useState<string[]>([]);
	const [activeIndex, setActiveIndex] = useState(0);
	const toast = useRef<Toast>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [nodes, setNodes] = useState<TreeNode[]>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [selectedKeys, setSelectedKeys] = useState<
		string | TreeCheckboxSelectionKeys | TreeMultipleSelectionKeys | null>(null);
	const [expandedKeys, setExpandedKeys] = useState<TreeExpandedKeysType>({ 65: true, '0-0': true });

	const fileUploadRef = useRef<FileUpload>(null);
	const [totalSize, setTotalSize] = useState(0);

	const responsiveOptions: GalleriaResponsiveOptions[] = [
		{
			breakpoint: '991px',
			numVisible: 4,
		},
		{
			breakpoint: '767px',
			numVisible: 3,
		},
		{
			breakpoint: '575px',
			numVisible: 1,
		},
	];

	const categoriesToNode = (cat: ProductCategoryModel[]) => {
		if (cat == null || cat.length === 0) {
			return [] as TreeNode[];
		}
		const list: TreeNode[] = cat.map((item) => ({
			key: item.productCategoryId,
			label: item.name,
			data: item.description,
			children: categoriesToNode(item.childrenCategories),

		} as TreeNode));
		return list;
	};

	useEffect(() => {
		getCategories();
		if (id) {
			const identification = parseInt(id || '1', 10);
			getProductById(identification);
		}
	}, []);

	useEffect(() => {
		if (product) {
			setImages(product.images);
		}

		if (product) {
			setEdit(product);
		}
	}, [product]);

	useEffect(() => {
		const nod = categoriesToNode(categories);
		setNodes(nod);
	}, [categories]);

	useEffect(() => {
		if (product) {
			setSelectedKeys({
				[product.category?.productCategoryId?.toString() ?? '']: {
					checked: true,
					partialChecked: false,
				},
			});
			const expanded: TreeExpandedKeysType = {};
			createBreadCrumb(categories, product.category?.productCategoryId).forEach((item) => {
				expanded[item.productCategoryId] = true;
			});
			setExpandedKeys(expanded);
		}
	}, [nodes]);

	const itemTemplate = (item: string) => <img src={item} alt="produto" height={300} style={{ display: 'block', padding: 24 }} />;
	const thumbnailTemplate = (item: string) => <img src={item} alt="produto" height={60} style={{ display: 'block' }} />;

	const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
		const {
			className, chooseButton, uploadButton,
		} = options;
		const value = totalSize / 10000;
		const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

		return (
			<div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
				{chooseButton}
				{uploadButton}
				<div className="flex align-items-center gap-3 ml-auto">
					<span>
						{formatedValue}
						{' '}
						/ 10 MB
					</span>
					<ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }} />
				</div>
			</div>
		);
	};

	const emptyTemplate = () => (
		<div className="flex align-items-center flex-column">
			<i
				className="pi pi-images mt-1 p-1"
				style={{
					fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)',
				}}
			/>
			<span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-1">
				{t('drag_and_drop_excel')}
			</span>
		</div>
	);

	const onTemplateSelect = (e: FileUploadSelectEvent) => {
		let mTotalSize = totalSize;
		const { files } = e;

		for (let i = 0; i < files.length; i += 1) {
			mTotalSize += files[i].size || 0;
		}

		setTotalSize(mTotalSize);
	};

	const onTemplateUpload = (e: FileUploadUploadEvent) => {
		let mTotalSize = 0;

		e.files.forEach((file: any) => {
			mTotalSize += file.size || 0;
		});

		setTotalSize(mTotalSize);
		toast.current?.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
	};

	// eslint-disable-next-line @typescript-eslint/ban-types
	const onTemplateRemove = (file: File, callback: Function) => {
		setTotalSize(totalSize - file.size);
		callback();
	};

	const onTemplateClear = () => {
		setTotalSize(0);
	};

	const handleSubmit = () => {
		if (edit) {
			const nImage: string[] = newImages.filter((item) => !!item).map((item) => item.split(/(\\|\/)/).pop()!.split(/(\\|\/|")/)[0]);
			updateProduct(edit.productId, {
				name: edit.name,
				reference: edit.reference,
				barcode: edit.barcode,
				price: edit.priceWithoutTaxes,
				brandId: 1,
				productCategoryId: parseInt(Object.keys(selectedKeys || {}).slice(-1)[0], 10),
				taxCategoryId: edit.tax?.taxCategoryId,
				stockStatusId: edit.stockStatus.stockStatusId,
				description: edit.description,
				images: nImage,
			});
		}
	};

	const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setEdit((prev) => ({
			...prev, priceWithoutTaxes: e.target.value,
		} as unknown as ProductModel));
	};

	const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
	const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
	const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

	return (
		<Box>
			<Toast ref={toast} />
			{(productsUpdateState.loading) && (
				<ProgressBar mode="indeterminate" style={{ height: '6px' }} />
			)}
			<Box>
				<BackButton onClick={() => navigate(-1)} />
			</Box>
			<Stack direction="row" mt={4}>
				<Typography fontSize={22} fontFamily={zilaSlab} fontWeight={600}>
					Detalhes de produto
				</Typography>
				<Box sx={{ flexGrow: 1 }} />
				{/* 	<DefaultButton sx={{
					borderRadius: 3,
					fontSize: 10,
					mr: 2,
					color: '#CC5F5F',
					bgcolor: '#FEECEB',
					'&:hover': {
						color: '#303030',
						bgcolor: '#f6c3cc',
					},
					fontFamily: zilaSlab,
				}}
				>
					{t('Guardar produto inativo')}
				</DefaultButton> */}
				<DefaultButton
					disabled={productsUpdateState.loading}
					sx={{ borderRadius: 3, fontSize: 14 }}
					onClick={handleSubmit}
				>
					<SvgIcon component={AddIcon} fontSize="small" sx={{ mr: 1 }} />
					{t('save_changes')}
				</DefaultButton>
			</Stack>

			{(productState.loading) && (
				<ProgressBar mode="indeterminate" style={{ height: '6px' }} />
			)}

			<Grid container mt={4} spacing={2}>
				<Grid item md={5}>
					<Card
						sx={{
							boxShadow: 'none',
							p: 4,
							gap: 2,
							display: 'flex',
						}}
					>
						<Stack direction="column" spacing={4} flex={1}>
							<div>
								<InputLabel shrink htmlFor="bootstrap-input">
									Nome de produto
								</InputLabel>
								<TextField
									fullWidth
									value={edit?.name ?? ''}
									onChange={(e) => setEdit((prev) => ({
										...prev, name: e.target.value,
									} as ProductModel))}
								/>
							</div>
							<div>
								<InputLabel shrink htmlFor="bootstrap-input">
									Referencia de produto
								</InputLabel>
								<TextField
									fullWidth
									value={edit?.reference ?? ''}
									onChange={(e) => setEdit((prev) => ({
										...prev, reference: e.target.value,
									} as ProductModel))}
								/>
							</div>

							<div>
								<InputLabel shrink htmlFor="bootstrap-input">
									Código de barras de produto
								</InputLabel>
								<TextField
									fullWidth
									value={edit?.barcode ?? ''}
									onChange={(e) => setEdit((prev) => ({
										...prev, barcode: e.target.value,
									} as ProductModel))}
								/>
							</div>
							<Grid container gap={2}>
								<Grid item md={6}>

									<InputLabel shrink htmlFor="bootstrap-input">
										Preço de produto
									</InputLabel>
									<TextField
										fullWidth
										value={edit?.priceWithoutTaxes ?? 0}
										onChange={(e) => handlePriceChange(currency(e))}
									/>

								</Grid>
								<Grid item md={5}>
									<div>
										<InputLabel shrink>
											Taxa de IVA
										</InputLabel>
										<Select
											id="tax-select"
											label="Age"
											value={edit?.tax?.taxCategoryId ?? 1}
											fullWidth
											onChange={(e) => setEdit((prev) => ({
												...prev,
												tax: {
													taxCategoryId: e.target.value,
												},
											} as ProductModel))}
										>
											<MenuItem value={1}>23%</MenuItem>
										</Select>
									</div>
								</Grid>
							</Grid>
							<Stack>
								<Dropdown
									options={statuses}
									placeholder="Select a Status"
									optionLabel="name"
									optionValue="stockStatusId"
									value={edit?.stockStatus?.stockStatusId ?? 1}
									onChange={(e) => setEdit((prev) => ({
										...prev, stockStatus: { stockStatusId: e.value },
									} as ProductModel
									))}
									// eslint-disable-next-line react/no-unstable-nested-components
									itemTemplate={(ev) => {
										const { color, bgColor } = getStockColors(ev.stockStatusId);
										return (
											<Stack>
												<Tag
													value={ev.name}
													style={{ backgroundColor: bgColor, color }}
												/>
											</Stack>
										);
									}}
								/>
							</Stack>
							<Stack>
								<InputLabel shrink htmlFor="bootstrap-input">
									Descrição de produto
								</InputLabel>
								<Editor
									style={{ height: '320px' }}
									value={edit?.description || ''}
									onTextChange={(e) => setEdit((prev) => ({
										...prev, description: e.htmlValue,
									} as ProductModel))}
								/>
							</Stack>
							{/*
							<Stack>
								<InputLabel shrink htmlFor="bootstrap-input">
									Este produto relaciona-se com
								</InputLabel>
								<TextField
									fullWidth
									InputProps={{
										startAdornment: (
											<Box sx={{
												margin: '0 0.2rem 0 0',
												display: 'flex',
											}}
											>
												{['122', '233', '121'].map((data) => (
													<Tags tag={data} key={data} />
												))}
											</Box>
										),
									}}
								/>
							</Stack>
							*/}
						</Stack>
					</Card>
					<Card
						sx={{
							boxShadow: 'none',
							p: 4,
							gap: 2,
							display: 'flex',
						}}
					>
						<Stack direction="column" spacing={4} flex={1}>

							<div>
								<InputLabel shrink htmlFor="bootstrap-input">
									Categoria de produto
								</InputLabel>
								<Tree
									value={nodes}
									selectionMode="checkbox"
									selectionKeys={selectedKeys}
									propagateSelectionUp
									metaKeySelection
									expandedKeys={expandedKeys}
									onToggle={(e) => setExpandedKeys(e.value)}
									onNodeClick={(e) => setSelectedKeys({
										[e.node.key!.toString()]: {
											checked: true, partialChecked: false,
										},
									})}
									className="w-full"
								/>
							</div>
						</Stack>
					</Card>
				</Grid>
				<Grid item md={6}>

					<ProductImageView
						images={images}
					/>

					<FileUpload
						name="files"
						url={`${AppEnv.BASE_URL}backoffice/files/images`}
						multiple
						accept="image/*"
						maxFileSize={1000000}
						style={{ marginTop: 16 }}
						onBeforeSend={(e) => {
							const key = LocalStorageWrapper.get<string>(LocalStorageKeys.AUTH_TOKEN);
							if (key) {
								e.xhr.setRequestHeader('Authorization', `Bearer ${key}`);
							}
						}}
						onUpload={(e) => {
							const files = e.files.map((file: any) => file.objectURL);
							const result = JSON.parse(e.xhr.response) as ApiResponse<{ fileUrl: string }[]>;
							setImages((prev) => [...prev, ...files]);

							setNewImages((prev) => [...prev, ...result.data.map((item) => item.fileUrl)]);
						}}
						headerTemplate={headerTemplate}
						onSelect={onTemplateSelect}
						onError={onTemplateClear}
						onClear={onTemplateClear}
						chooseOptions={chooseOptions}
						uploadOptions={uploadOptions}
						cancelOptions={cancelOptions}
						emptyTemplate={emptyTemplate}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};
