import { Stack, useMediaQuery, useTheme } from '@mui/material';
import Dimens from 'assets/dimens';
import { notFound } from 'assets/images';
import {
	BodyText, DefaultButton, SizedBox, TitleLarge,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NotFoundSection = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<Stack direction="column" alignItems="center" sx={{ pb: Dimens.SECTION_PADDING_BOTTOM }}>
			<img src={notFound} width={isMd ? Dimens.NOT_FOUND_IMAGE_WIDTH : Dimens.NOT_FOUND_IMAGE_WIDTH_MOBILE} alt="404" />
			<SizedBox height={10} />
			<TitleLarge sx={{ color: theme.palette.error.main }}>
				404
			</TitleLarge>
			<SizedBox height={1} />
			<TitleLarge sx={{ width: { md: Dimens.TEXT_BOX_WIDTH_SMALL, xs: '100%' } }} textAlign="center">
				{t('looking_for_not_found')}
			</TitleLarge>
			<SizedBox height={2.5} />
			<BodyText sx={{ width: { md: Dimens.TEXT_BOX_WIDTH_SMALL, xs: '100%' } }} textAlign="center">
				{t('expolore_and_discover_products')}
			</BodyText>
			<SizedBox height={3.5} />
			<DefaultButton>
				{t('see_our_product_catelog')}
			</DefaultButton>
		</Stack>
	);
};

export default NotFoundSection;
