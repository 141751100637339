import AppLinks from 'assets/applinks.routes';
import LocalStorageWrapper, { LocalStorageKeys } from 'common/utils/storage-utils';
import React, {
	createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { UserApiImpl } from '../../data/remote/user-api-impl';
import { ApiResponse } from 'common/domain/entities/api-response';
import { SimpleUserBackofficeRolesModel } from '../../data/dto/user-backoffice-model';

type TContextProps = {
    loading: boolean;
    error: string;
    login: (username: string, password: string) => Promise<boolean>;
    loggedUser: SimpleUserBackofficeRolesModel | null;
}
const AuthContext = createContext({} as TContextProps);

export const AuthBackofficeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const navigate = useNavigate();
	const authApi = new UserApiImpl();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [loggedUser, setLoggedUser] = useState<SimpleUserBackofficeRolesModel | null >(null);

	useEffect(() => {
		const key = LocalStorageWrapper.get<string>(LocalStorageKeys.REFRESH_TOKEN);
		if (!key && (window.location.href.indexOf(AppLinks.DASHBOARD) > -1)) {
			navigate(AppLinks.LOGIN_BACKOFFICE);
			return;
		}
		const user = LocalStorageWrapper.get<SimpleUserBackofficeRolesModel>(LocalStorageKeys.USER);
		setLoggedUser(user);
	}, []);

	const login = async (username: string, password: string) => {
		setLoading(true);
		setError('');
		return authApi.loginBackofficeUser(username, password).then((result) => {
			const { authToken: newAuthToken } = result.data;
			LocalStorageWrapper.set(LocalStorageKeys.AUTH_TOKEN, newAuthToken);
			LocalStorageWrapper.set(LocalStorageKeys.REFRESH_TOKEN, result.data.refreshToken);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const user: any = result.data;
			delete user.authToken;
			delete user.refreshToken;
			LocalStorageWrapper.set(LocalStorageKeys.USER, user);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setError(responseError.message);
			return false;
		}).finally(() => {
			setLoading(false);
		});
	};

	const value = useMemo(() => ({
		login,
		loading,
		error,
		loggedUser,
	}), [loading, error, loggedUser]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuthBackoffice() {
	return useContext(AuthContext);
}
