/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as BagIcon } from 'assets/icons/bag.svg';
import { ReactComponent as CatalogIcon } from 'assets/icons/catalog-item.svg';
import { ReactComponent as CustomersIcon } from 'assets/icons/customer.svg';
import { ReactComponent as DashIcon } from 'assets/icons/dash.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/setting.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';

import {
	CSSObject, Theme, styled,
	useTheme,
} from '@mui/material/styles';
import React from 'react';

import SearchIcon from '@mui/icons-material/Search';

import {
	AppBar,
	Avatar,
	Drawer,
	FormControl,
	IconButton, InputAdornment, OutlinedInput, Stack, Toolbar, Typography,
	useMediaQuery,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { logo, logoXs } from 'assets/images';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { zilaSlab } from 'assets/theme/theme';
import AppLinks from 'assets/applinks.routes';
import { useTranslation } from 'react-i18next';
import { stringAvatar } from 'common/utils/general.utils';
import LocalStorageWrapper, { LocalStorageKeys } from 'common/utils/storage-utils';
import useAuthBackoffice from './provider/auth-backoffice.provider';

const drawerWidth = 260;
const drawerWidthClosed = 70;

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBarStyled = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	boxShadow: 'none',
	background: theme.palette.background.default,
	transition: theme.transitions.create(['width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
}));

interface DrawerMenuItemProps {
	open?: boolean;
	label: string,
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
		title?: string | undefined;
	}>
	link: string,
	onClick: () => void;
}

const DrawerMenuItem = ({
	open, label, icon: ItemIcon, link, onClick,
}: DrawerMenuItemProps) => {
	const selected = !!useMatch(link);
	const navigate = useNavigate();
	const theme = useTheme();
	return (
		<ListItem
			disablePadding
			sx={{ mb: 1 }}
			onClick={() => {
				navigate(link);
				onClick.call(0);
			}}
		>
			<ListItemButton
				sx={{
					height: 48,
					justifyContent: open ? 'initial' : 'center',
					backgroundColor: selected ? '#F1F5C5' : 'none',
					color: selected ? '#F1F5C5' : 'none',
					borderRadius: '0 31px 31px 0',
					pt: 0,
					pb: 0,
				}}
			>
				<ListItemIcon
					sx={{
						minWidth: 0,
						mr: open ? 1 : 'auto',
						justifyContent: 'center',
					}}
				>
					<ItemIcon height={20} width={20} />
				</ListItemIcon>
				<ListItemText
					primary={(
						<Typography
							sx={{
								opacity: open ? 1 : 0,
								transition: theme.transitions.create(['opacity'], {
									easing: theme.transitions.easing.sharp,
									duration: theme.transitions.duration.leavingScreen,
								}),
								whiteSpace: 'nowrap',
								fontWeight: selected ? Dimens.FONT_BOLD : Dimens.FONT_THIN,
								fontFamily: zilaSlab,
								color: '#5C6464',
								fontSize: selected
									? Dimens.FONT_SIZE_SMALL_TITLE_MOBILE : Dimens.FONT_SIZE_SMALL,
							}}
						>
							{label}
						</Typography>
					)}
				/>

				{open && selected && (
					<IconButton
						edge="end"
					>
						<ArrowIcon height={30} width={30} />
					</IconButton>
				)}
			</ListItemButton>
		</ListItem>
	);
};

export const MainBackOffice = () => {
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [isClosing, setIsClosing] = React.useState(false);

	const isXS = useMediaQuery(theme.breakpoints.down(601));

	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { loggedUser } = useAuthBackoffice();

	const handleDrawerOpen = () => {
		setIsClosing(false);
		setMobileOpen(true);
	};

	const handleDrawerClose = () => {
		setIsClosing(true);
		setMobileOpen(false);
	};

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleDrawerTransitionEnd = () => {
		setIsClosing(false);
	};

	const drawer = (
		<>
			<DrawerHeader onClick={handleDrawerToggle} sx={{ mt: { xs: 2, sm: 0 } }}>
				{mobileOpen ? <img src={logo} alt="Volt" /> : <img src={logoXs} alt="Volt" />}
			</DrawerHeader>
			<Divider />
			<List sx={{ mr: mobileOpen ? 2 : 0.5, mt: 2, overflowX: 'hidden' }}>
				<DrawerMenuItem
					label="Encomendas"
					icon={BagIcon}
					link={AppLinks.ORDERS}
					open={mobileOpen}
					onClick={isXS ? handleDrawerToggle : () => undefined}
				/>
				<DrawerMenuItem
					label="Clientes"
					icon={CustomersIcon}
					link={AppLinks.CUSTOMERS}
					open={mobileOpen}
					onClick={isXS ? handleDrawerToggle : () => undefined}
				/>
				<DrawerMenuItem
					label="Inventário e produtos"
					icon={FolderIcon}
					link={AppLinks.INVENTORY}
					open={mobileOpen}
					onClick={isXS ? handleDrawerToggle : () => undefined}
				/>
				<DrawerMenuItem
					label="Catálogos"
					icon={CatalogIcon}
					open={mobileOpen}
					link={AppLinks.CATALOG}
					onClick={isXS ? handleDrawerToggle : () => undefined}
				/>
				<DrawerMenuItem
					label="Definições"
					icon={SettingsIcon}
					open={mobileOpen}
					link={AppLinks.SETTIGNS}
					onClick={isXS ? handleDrawerToggle : () => undefined}
				/>
			</List>

			<Box sx={{ flexGrow: 1 }} />
			<List sx={{ mb: 2, mr: mobileOpen ? 1 : 0.5, overflowX: 'hidden' }}>
				<ListItem
					disablePadding
				>
					<ListItemButton
						onClick={() => {
							LocalStorageWrapper.remove(LocalStorageKeys.AUTH_TOKEN);
							LocalStorageWrapper.remove(LocalStorageKeys.USER);
							LocalStorageWrapper.remove(LocalStorageKeys.REFRESH_TOKEN);

							navigate(AppLinks.LOGIN_BACKOFFICE);
						}}
						sx={{
							minHeight: 48,
							justifyContent: mobileOpen ? 'initial' : 'center',
							borderRadius: '0 31px 31px 0',
							pt: 0,
							pb: 0,
						}}
					>
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: mobileOpen ? 1 : 'auto',
								justifyContent: 'center',
							}}
						>
							<LogoutIcon height={40} width={40} />
						</ListItemIcon>
						<ListItemText
							primary={(
								<Typography
									sx={{
										opacity: mobileOpen ? 1 : 0,
										fontWeight: Dimens.FONT_THIN,
										fontFamily: zilaSlab,
										color: '#5C6464',
										fontSize: Dimens.FONT_SIZE_SMALL,
									}}
								>
									{t('logout')}
								</Typography>
							)}
						/>
					</ListItemButton>
				</ListItem>
			</List>
		</>
	);

	return (
		<Box sx={{ display: 'flex', backgroundColor: '#F7F7F7' }}>
			<CssBaseline />
			<AppBarStyled
				position="fixed"
				sx={{
					width: { sm: `calc(100% - ${drawerWidthClosed}px)` },
					ml: { sm: `${drawerWidthClosed}px` },
				}}
			>
				<Toolbar>
					<img src={logoXs} style={{ display: isXS ? 'block' : 'none' }} alt="volt" width={40} height={40} />
					<Typography variant="h6" noWrap component="div" sx={{ color: 'gray', ml: 2, mr: 2 }}>
						{t('welcome_to_backoffice')}
						{!isXS ? ', ' : ''}
						{!isXS ? t('welcome_info_backoffice').toLocaleLowerCase() : ''}
					</Typography>
					<Box sx={{ flexGrow: 1 }} />
					<Box sx={{ alignSelf: 'center', display: { xs: 'none', sm: 'block' } }} title={loggedUser?.fullName}>
						<Avatar {...stringAvatar(loggedUser?.fullName || 'Volt Admin', 40)} />
					</Box>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: 'none' } }}
					>
						<MenuIcon />

					</IconButton>
				</Toolbar>
			</AppBarStyled>
			<Box
				component="nav"
				sx={{ width: { xs: drawerWidth, sm: drawerWidthClosed }, flexShrink: { sm: 0 } }}
				aria-label="mailbox folders"
			>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				{isXS && (
					<Drawer
						id="mobile-drawer"
						variant="temporary"
						open={mobileOpen}
						onTransitionEnd={handleDrawerTransitionEnd}
						onClose={handleDrawerClose}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
						sx={{
							display: { xs: 'block', sm: 'none' },
							'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
						}}
					>
						{drawer}
					</Drawer>
				)}
				<Drawer
					variant="permanent"
					onMouseEnter={handleDrawerOpen}
					onMouseLeave={handleDrawerClose}
					sx={{
						display: { xs: 'none', sm: 'block' },
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: mobileOpen ? drawerWidth : drawerWidthClosed,
							transition: theme.transitions.create(['width'], {
								easing: theme.transitions.easing.sharp,
								duration: theme.transitions.duration.leavingScreen,
							}),
						},
					}}
					open
				>
					{drawer}
				</Drawer>
			</Box>

			<Box
				component="main"
				sx={{
					p: 3,
					pt: 7,
					flex: 1,
					boxSizing: 'border-box',
					backgroundColor: '#F7F7F7',
					minHeight: '100vh',
				}}
			>
				<DrawerHeader />
				<Outlet />
			</Box>
		</Box>
	);
};
