/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance } from 'axios';
import { ApiResponse } from '../domain/entities/api-response';

export const httpPost = async <T>(
	endpoint: string,
	body: any,
	http: AxiosInstance,

): Promise<ApiResponse<T>> => {
	try {
		const resp = await http.post(endpoint, {
			...body,
		});
		const data = resp.data as ApiResponse<T>;
		return Promise.resolve(data);
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const errData = err as AxiosError;
			if (errData.response?.data) {
				const data = errData.response?.data as ApiResponse<void>;
				return Promise.reject(data);
			}
		}
		const error = { message: 'unexpected_error' } as ApiResponse<void>;
		return Promise.reject(error);
	}
};

export const httpPatch = async <T>(
	endpoint: string,
	body: any,
	http: AxiosInstance,

): Promise<ApiResponse<T>> => {
	try {
		const resp = await http.patch(endpoint, {
			...body,
		});
		const data = resp.data as ApiResponse<T>;
		return Promise.resolve(data);
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const errData = err as AxiosError;
			if (errData.response?.data) {
				const data = errData.response?.data as ApiResponse<void>;
				return Promise.reject(data);
			}
		}
		const error = { message: 'unexpected_error' } as ApiResponse<void>;
		return Promise.reject(error);
	}
};

export const httpPut = async <T>(
	endpoint: string,
	body: any,
	http: AxiosInstance,

): Promise<ApiResponse<T>> => {
	try {
		const resp = await http.put(endpoint, {
			...body,
		});
		const data = resp.data as ApiResponse<T>;
		return Promise.resolve(data);
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const errData = err as AxiosError;
			if (errData.response?.data) {
				const data = errData.response?.data as ApiResponse<void>;
				return Promise.reject(data);
			}
		}
		const error = { message: 'unexpected_error' } as ApiResponse<void>;
		return Promise.reject(error);
	}
};

export const httpGet = async <T>(
	endpoint: string,
	http: AxiosInstance,

): Promise<ApiResponse<T>> => {
	try {
		const resp = await http.get(endpoint);
		const data = resp.data as ApiResponse<T>;
		return Promise.resolve(data);
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const errData = err as AxiosError;
			if (errData.response?.data) {
				const data = errData.response?.data as ApiResponse<void>;
				return Promise.reject(data);
			}
		}
		const error = { message: 'unexpected_error' } as ApiResponse<void>;
		return Promise.reject(error);
	}
};

export const httpDelete = async <T>(
	endpoint: string,
	body: any,
	http: AxiosInstance,

): Promise<ApiResponse<T>> => {
	try {
		const resp = await http.delete(endpoint, {
			...body,
		});
		const data = resp.data as ApiResponse<T>;
		return Promise.resolve(data);
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const errData = err as AxiosError;
			if (errData.response?.data) {
				const data = errData.response?.data as ApiResponse<void>;
				return Promise.reject(data);
			}
		}
		const error = { message: 'unexpected_error' } as ApiResponse<void>;
		return Promise.reject(error);
	}
};
