import { BackButton, SizedBox } from 'common/presentation/components';
import {
	Box,
	Card,
	Grid,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useCustomersBackoffice from '../../provider/customer-backoffice.provider';
import { ProgressBar } from 'primereact/progressbar';
import { inter } from 'assets/theme/theme';
import { useTranslation } from 'react-i18next';
import Usercard from '../../components/user-card/Usercard';
import Dimens from 'assets/dimens';
import { LocationOn } from '@mui/icons-material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ClientOrderBackoffice } from 'features/backoffice/data/dto/order-backoffice-model';
import AppLinks from 'assets/applinks.routes';
import { getOrderStatusColors } from 'common/utils/general.utils';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import useNotifications from 'common/presentation/providers/notifications.provider';
import useOrdersBackoffice from '../../provider/order-backoffice.provider';
import moment from 'moment';

const CustomerDetail = () => {
	const navigate = useNavigate();
	const {
		getCustomerById,
		customerById,
		customerFetchState,
		getCustomerOrders,
		customerOrders,
		orderFetchState,
		setCustomerOrders,
	} = useCustomersBackoffice();
	const {
		orderStatus,
		updateOrderStatus,
		updatingStatus,
		getOrderStatus,
	} = useOrdersBackoffice();
	const { id } = useParams();
	const { toast } = useNotifications();
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const [totalOfProducts, setTotalOfProducts] = useState<number>(0);

	useEffect(() => {
		getCustomerById(id || '');
		getCustomerOrders(id || '').then((res) => {
			setTotalOfProducts(res.total);
		});
	}, []);

	useEffect(() => {
		getOrderStatus();
	}, []);

	const handleDetailClick = (options: any) => {
		navigate(AppLinks.ORDER_DETAIL.replace(':id', options.id));
	};

	const orderStatusTag = (options: any) => {
		const { status } = options;
		const { color, bgColor } = getOrderStatusColors(status.id);
		return (
			<Tag
				value={status.name}
				style={{ backgroundColor: bgColor, color }}
			/>
		);
	};

	const statusEditor = (options: any) => {
		console.log(options);
		return (
			<Dropdown
				value={options?.value}
				options={orderStatus}
				onChange={(e) => options.editorCallback(e.value)}
				placeholder="Select a Status"
				optionLabel="name"
				// eslint-disable-next-line react/no-unstable-nested-components
				itemTemplate={(ev) => {
					const { color, bgColor } = getOrderStatusColors(ev.id);
					return (
						<Stack>
							<Tag value={ev.name} style={{ backgroundColor: bgColor, color }} />
						</Stack>
					);
				}}
			/>
		);
	};

	const onRowEditComplete = (e: any) => {
		console.log(e);
		const list = [...customerOrders];
		const { newData, index } = e;
		const old = list[index];
		const order = newData as ClientOrderBackoffice;
		list[index] = order;
		setCustomerOrders(list);

		Promise.all([
			updateOrderStatus(order.id, order.status.id),
		])
			.then(() => {
				toast.current?.show({
					severity: 'success',
					summary: t('success'),
					detail: t('items_update_success'),
				});
			})
			.catch(() => {
				list[index] = old;
				setCustomerOrders(list);
				toast.current?.show({
					severity: 'error',
					summary: t('failure'),
					detail: t('failure_info'),
				});
			});
	};

	const priceBodyTemplate = (rowData: ClientOrderBackoffice) => new Intl.NumberFormat('pt-EU', {
		style: 'currency',
		currency: 'EUR',
	}).format(rowData.amount);

	if (customerFetchState.loading) return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />;
	return (
		<Box>
			<Box>
				<BackButton onClick={() => navigate(-1)} />
			</Box>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Stack direction={{ md: 'row', xs: 'column' }}>
					<Typography fontFamily={inter}>{t('client_id')}</Typography>
					<Typography color="secondary" sx={{ ml: 1 }}>
						#
						{' '}
						{customerById?.customerId}
					</Typography>
					<Typography sx={{ ml: 2 }} fontFamily={inter}>{t('account_creation_date')}</Typography>
					<Typography color={theme.palette.textLight.main} sx={{ ml: 1 }}>
						{/* {moment(customerById).format('DD MMMM YYYY')} */}
					</Typography>
				</Stack>
				{/* <Stack direction="row" spacing={2}>
					<Tag value="Comprovativo de compra" onClick={handleProofClick} />
					{order && orderStatusTag(order?.status)}
				</Stack> */}
			</Stack>
			<SizedBox height={2} />
			<Grid container spacing={3}>
				<Grid item md={4} xs={12}>
					<Usercard
						fullName={customerById?.fullName}
						hasAccount
						phoneNumber={customerById?.phoneNumber}
						email={customerById?.email}
					/>
				</Grid>
				<Grid item md={8} xs={12}>
					<Card
						elevation={0}
						sx={{
							bgcolor: theme.palette.background.default,
							pt: Dimens.CARD_PADDING_VERTICAL,
							pb: Dimens.CARD_PADDING_VERTICAL,
							pl: Dimens.CARD_PADDING_HORIZANTAL,
							pr: Dimens.CARD_PADDING_HORIZANTAL,
						}}
					>
						<Stack
							alignItems="center"
							justifyContent="center"
							component="span"
							sx={{
								height: Dimens.ICON_BOX_DIEMENSIONS,
								width: Dimens.ICON_BOX_DIEMENSIONS,
								borderRadius: Dimens.AVATAR_BORDER_RADIUS,
								bgcolor: theme.palette.secondary.main,
								color: theme.palette.secondary.contrastText,
							}}
						>
							<LocationOn />
						</Stack>
						<SizedBox height={3} />
						<Grid container spacing={2}>
							<Grid item md={5} xs={12}>
								<Typography
									fontFamily={inter}
									color={theme.palette.textLight.main}
								>
									{t('address')}
								</Typography>
								<Typography
									fontFamily={inter}
									color={theme.palette.textMain.main}
								>
									{customerById?.streetAddress}
									{' '}
									{customerById?.zipCode}
									{' '}
									{customerById?.cityName}
								</Typography>
							</Grid>
							<Grid item md={5} xs={12}>
								<Typography
									fontFamily={inter}
									color={theme.palette.textLight.main}
								>
									{t('billing_address')}
								</Typography>
								{/* <Typography
									fontFamily={inter}
									color={theme.palette.textMain.main}
								>
									{order?.billingAddress.streetAddress}
									,
									{' '}
									{order.billingAddress.zipCode}
									{' '}
									,
									{order.billingAddress.cityName}
								</Typography> */}
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
			<SizedBox height={3} />
			<Card
				elevation={0}
				sx={{
					bgcolor: theme.palette.background.default,
					pt: Dimens.CARD_PADDING_VERTICAL,
					pb: Dimens.CARD_PADDING_VERTICAL,
					pl: Dimens.CARD_PADDING_HORIZANTAL,
					pr: Dimens.CARD_PADDING_HORIZANTAL,
				}}
			>
				<Stack direction="row">
					<Typography fontFamily={inter}>{t('client_orders').replace('{0}', customerById?.fullName || '')}</Typography>
				</Stack>
				<SizedBox height={3} />
				<Box sx={{ overflow: 'auto' }}>
					<Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
						<DataTable
							value={customerOrders}
							totalRecords={totalOfProducts}
							editMode="row"
							onRowEditComplete={onRowEditComplete}
							loading={orderFetchState.loading || updatingStatus}
							dataKey="id"
							tableStyle={{ minWidth: '50rem' }}
						>
							<Column
								field="productId"
								header="ID da encomenda"
								align="center"
								// eslint-disable-next-line react/no-unstable-nested-components
								body={(rowData: ClientOrderBackoffice) => (
									<Typography sx={{ color: '#B9C92B', fontWeight: 600 }}>
										{`#${rowData.id}`}
									</Typography>
								)}
							/>
							<Column
								field="customerName"
								bodyStyle={{ fontSize: 14 }}
								header="Data da encomenda"
								// eslint-disable-next-line react/no-unstable-nested-components
								body={(rowData: ClientOrderBackoffice) => (
									<Typography>
										{moment(rowData.createdAt).format('DD MMMM YYYY - hh:mm')}
									</Typography>
								)}
							/>
							<Column
								field="customerName"
								bodyStyle={{ fontSize: 14 }}
								header="Nome do cliente"
								// eslint-disable-next-line react/no-unstable-nested-components
								body={() => (
									<Typography>
										{customerById?.fullName}
									</Typography>
								)}
							/>

							<Column
								field="amount"
								align="center"
								header="Valor da encomenda"
								style={{ fontWeight: 600 }}
								body={(options) => priceBodyTemplate(options)}
							/>

							<Column
								field="itemsQuantity"
								header="N Itens"
								align="center"
								// eslint-disable-next-line react/no-unstable-nested-components
								body={(rowData: ClientOrderBackoffice) => (
									<Typography sx={{ fontWeight: 600 }}>
										{`${rowData.items.length} ${rowData.items.length > 1 ? 'itens' : 'item'}`}
									</Typography>
								)}
							/>

							<Column
								header="Status"
								align="center"
								field="status"
								body={(options) => orderStatusTag(options)}
								editor={(options) => statusEditor(options)}
								headerStyle={{ width: '10%', minWidth: '8rem' }}
							/>
							<Column
								rowEditor
								headerStyle={{ width: '10%', minWidth: '8rem' }}
								bodyStyle={{ textAlign: 'center' }}
							/>
							<Column
								headerStyle={{ width: '10%', minWidth: '8rem' }}
								// eslint-disable-next-line react/no-unstable-nested-components
								body={(options) => (
									<IconButton onClick={() => handleDetailClick(options)}>
										<ArrowForwardIosIcon />
									</IconButton>
								)}
								bodyStyle={{ textAlign: 'center' }}
							/>
						</DataTable>
					</Box>
				</Box>
			</Card>
		</Box>
	);
};

export default CustomerDetail;
