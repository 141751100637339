import { Button, ButtonProps } from '@mui/material';
import React from 'react';

type Props = ButtonProps

const DefaultButton: React.FC<Props> = (props) => {
	const {
		variant = 'contained', sx, children, ...remainingProps
	} = props;
	return (
		<Button
			color="secondary"
			disableElevation
			variant={variant}
			sx={{ borderRadius: 0, ...sx }}
			{...remainingProps}
		>
			{children}
		</Button>
	);
};

export default DefaultButton;
