import React, {
	createContext, useContext, useMemo, useState,
} from 'react';
import { Brand } from '../../domain/entities/Brand';
import { ProductRepositoryImpl } from '../../data/repository/product-repository-impl';
import { GetBrands } from '../../domain/usecases/get-brands';
import { Product } from '../../domain/entities/Product';
import { GetProductById } from '../../domain/usecases/get-product-by-id';
import { GetProductsByFilter } from '../../domain/usecases/get-products-by-filter';
import { FilterParams } from '../../data/remote/product-api';

type TContextProps = {
	brandsLoading: boolean;
	brandError: string;
	brands: Brand[];
	getBrands: () => Promise<void>;

	productDetail: Product;
	productDetailLoading: boolean;
	productDetailError: string;
	getProductById: (id: number) => Promise<void>;

	productLoading: boolean;
	products: Product[];
	productsError: string;
	totalProducts: number,
	getProductsByFilter: (params: FilterParams) => Promise<void>;
}

const ProductContext = createContext({} as TContextProps);

export const ProductProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [brands, setBrands] = useState([] as Brand[]);
	const [brandsLoading, setBrandsLoading] = useState(false);
	const [brandError, setBrandError] = useState('');

	const [productDetail, setProductDetail] = useState({} as Product);
	const [productDetailLoading, setProductDetailLoading] = useState(false);
	const [productDetailError, setProductDetailError] = useState('');

	const [productLoading, setProductLoading] = useState(false);
	const [products, setProducts] = useState([] as Product[]);
	const [totalProducts, setTotalProducts] = useState(0);
	const [productsError, setProductError] = useState('');

	const productRepository = new ProductRepositoryImpl();

	const getProductsByFilter = async (params: FilterParams) => {
		setProductLoading(true);
		const result = await new GetProductsByFilter(productRepository).call(params);
		if (result.isRight()) {
			setProductError('');
			setProducts(result.value.data);
			setTotalProducts(result.value.total);
		} else {
			setProductError(result.error);
		}
		setProductLoading(false);
	};

	const getProductById = async (id: number) => {
		setProductDetailLoading(true);
		const productUseCase = new GetProductById(productRepository);
		const productResult = await productUseCase.call(id);
		if (productResult.isRight()) {
			setProductDetail(productResult.value);
		} else {
			setProductDetailError(productResult.error);
		}
		setProductDetailLoading(false);
	};

	const getBrands = async () => {
		setBrandsLoading(true);
		const productUseCase = new GetBrands(productRepository);
		const productResult = await productUseCase.call();
		if (productResult.isRight()) {
			setBrandError('');
			setBrands(productResult.value);
		} else {
			setBrandError(productResult.error);
		}
		setBrandsLoading(false);
	};

	const value = useMemo(() => ({
		brands,
		brandError,
		brandsLoading,
		getBrands,
		productDetail,
		productDetailLoading,
		productDetailError,
		getProductById,

		productLoading,
		products,
		productsError,
		totalProducts,
		getProductsByFilter,

	}), [
		brands,
		brandError,
		brandsLoading,
		productDetail,
		products,
		productLoading,
		totalProducts,
		productDetailLoading,
		productDetailError,
	]);

	return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};

export default function useProduct() {
	return useContext(ProductContext);
}
