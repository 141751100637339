import { inter } from 'assets/theme/theme';
import {
	Avatar,
	Box,
	Grid,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { TextInput } from '../../components';
import { DefaultButton, DefaultDropdown, SizedBox } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import { CameraAlt, DomainVerification } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import useSettingsBackoffice from '../../provider/settings-backoffice.provider';
import { FullUserBackofficeModel } from 'features/backoffice/data/dto/user-backoffice-model';

const MyProfile = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const {
		getUserProfile,
		getCountries,
		fullUserProfile,
		setFullUserProfile,
		countries,
		districts,
		updateUserProfile,
		userProfileErrors,
		setUserProfileErrors,
	} = useSettingsBackoffice();

	useEffect(() => {
		getUserProfile();
		getCountries();
	}, []);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name as keyof FullUserBackofficeModel;
		if (fullUserProfile) {
			setFullUserProfile({
				...fullUserProfile, [key]: e.target.value,
			});
			setUserProfileErrors({ ...userProfileErrors, [key]: e.target.value });
		}
	};

	const handleSubmit = () => {
		if (!fullUserProfile?.fullName) {
			setUserProfileErrors({ ...userProfileErrors, fullName: 'Required' });
		}
		updateUserProfile();
	};

	return (
		<Box sx={{ bgcolor: theme.palette.background.default, pl: 2, pb: 2 }}>
	  		<Typography fontSize={22} fontFamily={inter} fontWeight={400}>
				{t('edit_your_profile_here')}
    	</Typography>
			<Box sx={{ pl: 2 }}>
				<SizedBox height={2} />
	  			<Typography
					color={theme.palette.textLight.main}
					fontSize={Dimens.FONT_SIZE_SMALL}
					fontFamily={inter}
					fontWeight={400}
	  			>
					{t('my_profile_image')}
    		</Typography>
				<SizedBox height={1} />
				<Stack direction="row" alignItems="center">
					<Avatar src={fullUserProfile?.profileImage} sx={{ height: 80, width: 80, mr: 2 }} />
					<DefaultButton
						variant="outlined"
						startIcon={<CameraAlt />}
						sx={{
							borderRadius: Dimens.CARD_BORDER_RADIUS,
							textTransform: 'none',
							fontFamily: inter,
						}}
					>
						{t('select_image')}
					</DefaultButton>
				</Stack>
				<SizedBox height={4} />
				<Grid container spacing={2}>
					<Grid item md={3} xs={12}>
						<TextInput
							name="fullName"
							error={!!userProfileErrors.fullName}
							helperText={userProfileErrors.fullName}
							onChange={handleChange}
							value={fullUserProfile?.fullName}
							label="Nome e sobrenome"
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<TextInput
							name="email"
							onChange={handleChange}
							// error={!userProfileErrors.email}
							// helperText={userProfileErrors.fullName}
							value={fullUserProfile?.email}
							label="E-mail"
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<TextInput
							name="taxId"
							onChange={handleChange}
							error={!!userProfileErrors.taxId}
							helperText={userProfileErrors.taxId}
							value={fullUserProfile?.taxId}
							label="N de Contribuinte (NIF)"
						/>
					</Grid>
				</Grid>
				<SizedBox height={4} />
				<Grid container spacing={2}>
					<Grid item md={3} xs={12}>
						<TextInput
							name="phoneNumber"
							onChange={handleChange}
							error={!!userProfileErrors.phoneNumber}
							helperText={userProfileErrors.phoneNumber}
							value={fullUserProfile?.phoneNumber}
							label="Telemóvel"
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<TextInput
							name="secondaryPhoneNumber"
							error={!!userProfileErrors.secondaryPhoneNumber}
							helperText={userProfileErrors.secondaryPhoneNumber}
							onChange={handleChange}
							value={fullUserProfile?.secondaryPhoneNumber}
							label="Telefone"
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<TextInput
							name="companyName"
							onChange={handleChange}
							error={!!userProfileErrors.companyName}
							helperText={userProfileErrors.companyName}
							value={fullUserProfile?.companyName}
							label="Nome da empresa"
						/>
					</Grid>
				</Grid>
				<SizedBox height={4} />
				<Grid container spacing={2}>
					<Grid item md={3} xs={12}>
						<DefaultDropdown
							borderRadius="5px"
							size="medium"
							label="Pais"
							value={fullUserProfile?.country?.id}
							options={countries}
							renderItem={(item) => ({ optionValue: item.id, optionLabel: item.name })}
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<DefaultDropdown
							borderRadius="5px"
							size="medium"
							label="Cidade"
							value={fullUserProfile?.district?.id}
							options={districts}
							renderItem={(item) => ({ optionValue: item.id, optionLabel: item.name })}
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<TextInput
							name="streetAddress"
							onChange={handleChange}
							error={!!userProfileErrors.streetAddress}
							helperText={userProfileErrors.streetAddress}
							label="Endereço"
							value={fullUserProfile?.streetAddress}
						/>
					</Grid>
				</Grid>
				<SizedBox height={4} />
				<Grid container spacing={2}>
					<Grid item md={3} xs={12}>
						<TextInput
							name="zipCode"
							onChange={handleChange}
							error={!!userProfileErrors.zipCode}
							helperText={userProfileErrors.zipCode}
							value={fullUserProfile?.zipCode}
							label="Código postal"
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<TextInput
							name="cityName"
							onChange={handleChange}
							error={!!userProfileErrors.cityName}
							helperText={userProfileErrors.cityName}
							value={fullUserProfile?.cityName}
							label="Localidade"
						/>
					</Grid>
					<Grid item md={3} xs={12} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
						<DefaultButton
							onClick={handleSubmit}
							startIcon={<DomainVerification />}
							sx={{
								borderRadius: Dimens.CARD_BORDER_RADIUS,
								textTransform: 'none',
								fontFamily: inter,
							}}
						>
							Guardar alteracoes
						</DefaultButton>
					</Grid>
				</Grid>
			</Box>

			{/* <SizedBox height={4} />
			<Typography fontSize={22} fontFamily={inter} fontWeight={400}>
				Alterar palavra passe
			</Typography>
			<Box sx={{ pl: 2 }}>
				<SizedBox height={2} />
				<Grid container spacing={2}>
					<Grid item md={3} xs={12}>
						<TextInput disabled type="password" label="Palavra passe atual" />
					</Grid>
					<Grid item md={3} xs={12}>
						<TextInput disabled type="password" label="Nova palavra passe" />
					</Grid>
					<Grid item md={3} xs={12}>
						<TextInput disabled type="password" label="Confirmar palavra passe" />
					</Grid>
				</Grid>
				<SizedBox height={2} />
				<Grid container spacing={2}>
					<Grid item md={6} xs={12} />
					<Grid item md={3} xs={12} sx={{
						display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
						<DefaultButton
							disabled
							startIcon={<DomainVerification />}
							sx={{
								borderRadius: Dimens.CARD_BORDER_RADIUS,
								textTransform: 'none',
								fontFamily: inter,
							}}
						>
							Alterar palavra passe
						</DefaultButton>
					</Grid>
				</Grid>
			</Box> */}
		</Box>
	);
};

export default MyProfile;
