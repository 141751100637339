import useNotifications from 'common/presentation/providers/notifications.provider';
import CloseIcon from '@mui/icons-material/Close';
import {
	IconButton, Stack, useMediaQuery, useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Dimens from 'assets/dimens';
import {
	FileUpload, FileUploadHeaderTemplateOptions,
	FileUploadSelectEvent, FileUploadUploadEvent, ItemTemplateOptions,
} from 'primereact/fileupload';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import LocalStorageWrapper, { LocalStorageKeys } from 'common/utils/storage-utils';

const style = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 500,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
};

type TModalUploadExcel = {
	open: boolean;
	onOpenChange: (value: boolean) => void;
	onClose: () => void;
}

export const ModalUploadExcel: React.FC<TModalUploadExcel> = ({
	open,
	onOpenChange,
	onClose,
}) => {
	const handleClose = () => {
		onOpenChange(false);
		onClose();
	};
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const { toast } = useNotifications();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));

	const [totalSize, setTotalSize] = useState(0);
	const fileUploadRef = useRef<FileUpload>(null);

	const onTemplateSelect = (e: FileUploadSelectEvent) => {
		let mTotalSize = totalSize;
		const { files } = e;

		for (let i = 0; i < files.length; i += 1) {
			mTotalSize += files[i].size || 0;
		}

		setTotalSize(mTotalSize);
	};

	const onTemplateUpload = (e: FileUploadUploadEvent) => {
		let mTotalSize = 0;

		e.files.forEach((file: any) => {
			mTotalSize += file.size || 0;
		});

		setTotalSize(mTotalSize);
		toast.current?.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
	};

	// eslint-disable-next-line @typescript-eslint/ban-types
	const onTemplateRemove = (file: File, callback: Function) => {
		setTotalSize(totalSize - file.size);
		callback();
	};

	const onTemplateClear = () => {
		setTotalSize(0);
	};

	const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
		const {
			className, chooseButton, uploadButton,
		} = options;
		const value = totalSize / 100000;
		const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

		return (
			<div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
				{chooseButton}
				{uploadButton}
				<div className="flex align-items-center gap-3 ml-auto">
					<span>
						{formatedValue}
						{' '}
						/ 10 MB
					</span>
					<ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }} />
				</div>
			</div>
		);
	};

	const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
		const file = inFile as any;
		return (
			<div className="flex align-items-center flex-wrap">
				<div className="flex align-items-center" style={{ width: '60%' }}>
					<span className="flex flex-column text-left ml-3">
						{file.name}
						<small>{new Date().toLocaleDateString()}</small>
					</span>
				</div>
				<Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
				<Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
			</div>
		);
	};

	const emptyTemplate = () => (
		<div className="flex align-items-center flex-column">
			<i
				className="pi pi-file-excel mt-1 p-1"
				style={{
					fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)',
				}}
			/>
			<span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-1">
				{t('drag_and_drop_excel')}
			</span>
		</div>
	);

	const chooseOptions = { icon: 'pi pi-fw pi-file-excel', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
	const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
	const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				sx={{ overflow: 'scroll' }}
				disableEscapeKeyDown
				hideBackdrop
			>
				<Box sx={{ ...style, width: isMd ? 500 : '90%' }}>
					<Box dir="rtl">
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Box>

					<Stack textAlign="center">
						<Typography
							sx={{
								color: theme.palette.primary.contrastText,
								fontSize: Dimens.FONT_SIZE_EXTRA_LARGE_MOBILE,
								fontWeight: Dimens.FONT_BOLDER,
								mb: 2,
							}}
						>
							{t('import_products_from_excel')}
						</Typography>
						<div>
							<Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
							<Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
							<Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

							<FileUpload
								ref={fileUploadRef}
								name="file"
								url={`${process.env.REACT_APP_BASE_URL}backoffice/files/excel-upload`}
								accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
								maxFileSize={100000000}
								onUpload={onTemplateUpload}
								onSelect={onTemplateSelect}
								onError={onTemplateClear}
								onClear={onTemplateClear}
								headerTemplate={headerTemplate}
								itemTemplate={itemTemplate}
								emptyTemplate={emptyTemplate}
								chooseOptions={chooseOptions}
								uploadOptions={uploadOptions}
								cancelOptions={cancelOptions}
								onBeforeSend={(e) => {
									const key = LocalStorageWrapper.get<string>(LocalStorageKeys.AUTH_TOKEN);
									if (key) {
										e.xhr.setRequestHeader('Authorization', `Bearer ${key}`);
									}
								}}
							/>
						</div>
					</Stack>
				</Box>
			</Modal>
		</div>
	);
};
