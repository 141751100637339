import React from 'react';
import PurchasePage from './PurchasePage';

const Purchase = () => (
	<main>
		<PurchasePage />
	</main>
);

export default Purchase;
