import React from 'react';
import { MainBackOffice } from './MainBackOffice';
import { ProducthBackofficeProvider } from './provider/product-backoffice.provider';
import { OrderBackofficeProvider } from './provider/order-backoffice.provider';
import { SettingsBackofficeProvider } from './provider/settings-backoffice.provider';
import { StockBackofficeProvider } from './provider/stock-backoffice-provider';
import { CategoriesBackofficeProvider } from './provider/categories-backoffice.provider';
import { CustomersBackofficeProvider } from './provider/customer-backoffice.provider';
import { CatelogProvider } from './provider/catelog.provider';
import { ProductProvider } from 'features/products/presentation/provider/product.provider';

const Backoffice = () => (
	<main>
		<ProducthBackofficeProvider>
			<ProductProvider>
				<OrderBackofficeProvider>
					<SettingsBackofficeProvider>
						<StockBackofficeProvider>
							<CategoriesBackofficeProvider>
								<CustomersBackofficeProvider>
									<CatelogProvider>
										<MainBackOffice />
									</CatelogProvider>
								</CustomersBackofficeProvider>
							</CategoriesBackofficeProvider>
						</StockBackofficeProvider>
					</SettingsBackofficeProvider>
				</OrderBackofficeProvider>
			</ProductProvider>
		</ProducthBackofficeProvider>
	</main>
);

export default Backoffice;
