import Dimens from 'assets/dimens';
import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import TitleSmall from 'common/presentation/components/title-small/TitleSmall';
import { BodyText, DefaultDivider } from 'common/presentation/components';
import useCart from 'common/presentation/providers/cart.provider';
import { ICartProduct } from 'common/domain/entities/cart-product';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import { useTranslation } from 'react-i18next';
import CartItem from './components/CartItem';
import CartSideItem from './components/CartSideItem';
import { ModalFormCustomer } from 'features/products/presentation/modals/ModalFormCustomer';
import useAuth from 'features/authentication/presentation/provider/auth.provider';

const CartPage = () => {
	const navigate = useNavigate();
	const {
		userAuth,
	} = useAuth();
	const {
		cartProducts, removeFromCart, getTotalPrice, incrementProduct, decrementCart,
	} = useCart();
	const { t } = useTranslation('translations');
	const [open, setOpen] = useState<boolean>(false);
	const handleRemoveFromCart = (product: ICartProduct) => {
		removeFromCart(product);
	};

	const handleFinalizeClick = () => {
		if (userAuth) {
			navigate(AppLinks.PURCHASE);
		} else {
			setOpen(true);
		}
	};

	return (
		<Box sx={{
			pl: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },
			pr: { md: Dimens.PAGE_PADDING_SMALL, xs: Dimens.PAGE_PADDING_SMALL_MOBILE },
			pb: Dimens.SECTION_PADDING_BOTTOM,
		}}
		>
			<TitleSmall>{t('my_cart').toUpperCase()}</TitleSmall>
			<DefaultDivider sx={{ mt: 1, mb: 2 }} />
			<Grid container spacing={2}>
				<Grid item xl={8} lg={8} md={8} xs={12} sm={12} gap={2}>
					{cartProducts.map((el) => (
						<CartItem
							product={el}
							key={el.productId}
							onRemove={handleRemoveFromCart}
							onDecrement={() => decrementCart(el)}
							onIncrement={() => incrementProduct(el)}
						/>
					))}
					{cartProducts.length === 0 && (
						<BodyText sx={{ textAlign: 'center' }}>
							{t('no_item_cart')}
						</BodyText>
					)}
				</Grid>
				<Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
					<CartSideItem
						handleFinalizeClick={handleFinalizeClick}
						deliveryEstimate
						price={getTotalPrice()}
					/>
				</Grid>
			</Grid>
			<ModalFormCustomer
				open={open}
				onOpenChange={(value) => setOpen(value)}
				onClickNoAuth={() => navigate(AppLinks.PURCHASE)}
				onClickCreateAccount={() => navigate(`${AppLinks.REGISTER}?purchase=true`)}
			/>
		</Box>
	);
};

export default CartPage;
