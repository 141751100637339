import {
	DefaultDropdown, TitleMedium,
} from 'common/presentation/components';
import {
	Box, Grid,
} from '@mui/material';
import React, { useEffect } from 'react';
import TextField from 'common/presentation/components/text-field/TextField';
import { useTranslation } from 'react-i18next';
import CheckItem from './components/CheckItem';
import useAuth from 'features/authentication/presentation/provider/auth.provider';
import { InputFieldProps } from 'common/domain/entities/input-field-props';
import { IdNameModel } from 'common/data/dto/id-name-model';

type TAddressDetailsProps = {
	title: string
	countryId: number
	onCountryChange: (value: IdNameModel) => void
	districtId: number
	onDistrictChange: (value: IdNameModel) => void
	addressField: InputFieldProps<HTMLTextAreaElement>
	postalCodeField: InputFieldProps<HTMLTextAreaElement>
	locationField: InputFieldProps<HTMLTextAreaElement>
	useAsBillingAddressCheck?: boolean;
	onUseAsBillingAddressCheckChange?: (value: boolean) => void
	showCheck?: boolean
}

const AddressDetails: React.FC<TAddressDetailsProps> = ({
	title,
	countryId, onCountryChange,
	districtId, onDistrictChange,
	addressField,
	postalCodeField,
	locationField,
	useAsBillingAddressCheck,
	onUseAsBillingAddressCheckChange,
	showCheck,

}) => {
	const { t } = useTranslation('translations');
	const {
		getCountries, countries,
		getDistricts, districts,
	} = useAuth();

	useEffect(() => {
		getCountries();
		getDistricts(1);
	}, []);

	const handleInvoiceChange = () => {
		onUseAsBillingAddressCheckChange?.call(0, !useAsBillingAddressCheck);
	};

	const handleCountryChange = (value: number) => {
		const item = countries.find((el) => el.id === value);
		if (item) {
			onCountryChange(item);
		}
	};

	const handleDistrictChange = (value: number) => {
		const item = districts.find((el) => el.id === value);
		if (item) {
			onDistrictChange(item);
		}
	};

	return (
		<Box>
			<TitleMedium sx={{ textAlign: 'center', mt: 4 }}>{title}</TitleMedium>
			<Grid container spacing={2} sx={{ mt: 5 }}>
				<Grid item md={4} xs={12}>
					<DefaultDropdown
						label={t('customer_page_label_country')}
						value={countryId.toString()}
						options={countries}
						onChange={(e) => handleCountryChange(parseInt(e.target.value, 10))}
						renderItem={(item) => ({ optionLabel: item.name, optionValue: item.id })}
					/>
				</Grid>
				<Grid item md={4} xs={12}>
					<DefaultDropdown
						label={t('customer_page_label_city')}
						value={districtId.toString()}
						onChange={(e) => handleDistrictChange(parseInt(e.target.value, 10))}
						options={districts}
						renderItem={(item) => ({ optionLabel: item.name, optionValue: item.id })}
					/>
				</Grid>
				<Grid item md={4} xs={12}>
					<TextField
						id="address"
						{...addressField}
						// error={Boolean(state.nameError)}
						// helperText={state.nameError}
						// onChange={handleChange}
						label={t('Endereço')}
					/>
				</Grid>
			</Grid>
			<Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ mt: 2 }}>
				<Grid item md={4} xs={12}>
					<TextField
						id="postal_code"
						{...postalCodeField}
						// error={Boolean(state.nameError)}
						// helperText={state.nameError}
						// onChange={handleChange}
						label={t('Código postal')}
					/>
				</Grid>
				<Grid item md={4} xs={12}>
					<TextField
						id="location"
						{...locationField}
						// error={Boolean(state.nameError)}
						// helperText={state.nameError}
						// onChange={handleChange}
						label={t('Localidade')}
					/>
				</Grid>

			</Grid>
			{showCheck && (
				<CheckItem
					label={t('is_this_billing_address')}
					positiveButtonText={t('yes')}
					negativeButtonText={t('no')}
					positiveAction={handleInvoiceChange}
					negativeAction={handleInvoiceChange}
					positiveChecked={!!useAsBillingAddressCheck}
					negativeChecked={!useAsBillingAddressCheck}
				/>
			)}
		</Box>
	);
};

export default AddressDetails;
