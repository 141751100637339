/* eslint-disable indent */
import {
	Avatar, Box, Divider, Grid, Stack,
	Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Dimens from 'assets/dimens';
import {
	AtField, CompanyField, NifField,
	PersonField,
	PhoneField,
} from 'assets/icons';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as PhotoIcon } from 'assets/icons/person-badge-icon.svg';
import { DefaultButton, DefaultDropdown } from 'common/presentation/components';
import TextField from 'common/presentation/components/text-field/TextField';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../provider/auth.provider';
import { UserReadModel, UserUpdateModel } from '../../data/dto/user-model';
import LocalStorageWrapper, { LocalStorageKeys } from 'common/utils/storage-utils';
import useNotificationsContext from 'common/presentation/providers/notifications.provider';
import { OrderRow } from './component/OrderRow';

export const CustomerForm = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const { toast } = useNotificationsContext();
	const {
		getCountries, countries,
		getDistricts, districts,
		getUserInfo, userAuth: userInfo,
		updateUser,
		uploadFile,
		getOrders, orders,
		loading,
		uploadLoading,
	} = useAuth();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [state, setState] = useState<UserReadModel>({
		customerId: -1,
		fullName: '',
		email: '',
		taxId: '',
		phoneNumber: '',
		secondaryPhoneNumber: '',
		companyName: '',
		country: { id: 1, name: 'Portugal' },
		district: { id: 1, name: 'None' },
		streetAddress: '',
		zipCode: '',
		cityName: '',
		profileImage: '',
	} as UserReadModel);
	const inputRef = useRef<HTMLInputElement>(null);

	React.useEffect(() => {
		getUserInfo();
		getCountries();
		getDistricts(1); // initial district request
		getOrders();
	}, []);

	React.useEffect(() => {
		getDistricts(state.country.id);
	}, [state.country]);
	React.useEffect(() => {
		setState({
			customerId: userInfo?.customerId ?? -1,
			fullName: userInfo?.fullName ?? state.fullName,
			email: userInfo?.email ?? state.email,
			taxId: userInfo?.taxId ?? state.taxId,
			phoneNumber: userInfo?.phoneNumber ?? state.phoneNumber,
			secondaryPhoneNumber: userInfo?.secondaryPhoneNumber ?? state.secondaryPhoneNumber,
			companyName: userInfo?.companyName ?? state.companyName,
			country: userInfo?.country ?? state.country,
			district: userInfo?.district ?? state.district,
			streetAddress: userInfo?.streetAddress ?? state.streetAddress,
			zipCode: userInfo?.zipCode ?? state.zipCode,
			cityName: userInfo?.cityName ?? state.cityName,
			profileImage: userInfo?.profileImage ?? state.profileImage,
		});
	}, [userInfo]);

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		console.log(state.profileImage, JSON.stringify(state.profileImage).split(/(\\|\/)/).pop());

		updateUser({
			fullName: state.fullName || null,
			taxId: state.taxId || null,
			phoneNumber: state.phoneNumber || null,
			secondaryPhoneNumber: state.secondaryPhoneNumber || null,
			companyName: state.companyName || null,
			districtId: state.district?.id || null,
			streetAddress: state.streetAddress || null,
			zipCode: state.zipCode || null,
			cityName: state.cityName || null,
			profileImageFileName: state.profileImage ? JSON.stringify(state.profileImage).split(/(\\|\/)/).pop()?.split(/(\\|\/|")/)[0] : null,
		} as UserUpdateModel).then(({ success, message }) => {
			if (success) {
				toast.current?.show({
					severity: 'success',
					summary: 'Sucesso',
					detail: 'Dados alterados com sucesso.',
				});
			} else {
				toast.current?.show({
					severity: 'error',
					summary: 'Falha',
					detail: message,
				});
			}
		});

		return false;
	};

	const logout = () => {
		LocalStorageWrapper.remove(LocalStorageKeys.USER_STORE);
		LocalStorageWrapper.remove(LocalStorageKeys.AUTH_STORE_TOKEN);
		LocalStorageWrapper.remove(LocalStorageKeys.REFRESH_STORE_TOKEN);
		LocalStorageWrapper.remove(LocalStorageKeys.CART);
		window.location.reload();
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileObj = event.target.files && event.target.files[0];
		if (!fileObj) {
			return;
		}

		uploadFile(fileObj, () => true).then((result) => {
			if (result.success && result.files && result.files.length > 0) {
				const img = result.files[0].fileUrl;
				setState((prev) => ({ ...prev, profileImage: img }));
				// eslint-disable-next-line no-param-reassign
				event.target.value = '';
			} else {
				toast.current?.show({
					severity: 'error',
					summary: 'Falha',
					detail: result.message,
				});
			}
		});
	};

	const StyledTableRow = styled(TableRow)(() => ({
		padding: '34px',
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	}));

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', mt: { md: 4, xs: 8 } }}>
			<Box sx={{
				...Dimens.PAGE_HORIZONTAL_PADDING,
				mb: Dimens.SECTION_PADDING_BOTTOM,
				maxWidth: Dimens.PAGE_MAX_WIDTH,
			}}
			>
				<Box>
					<Typography
						sx={{
							color: theme.palette.primary.contrastText,
							fontSize: Dimens.FONT_SIZE_LARGE,
							fontWeight: Dimens.FONT_BOLD,
						}}
					>
						{t('customer_page_title')}

					</Typography>
					<Typography
						sx={{
							color: theme.palette.primary.contrastText,
							fontSize: Dimens.FONT_SIZE_BODY,
							fontWeight: Dimens.FONT_THIN,
							whiteSpace: 'pre-wrap',
							mb: { md: 8, xs: 6 },
						}}
					>
						{t('customer_page_description')}
					</Typography>
				</Box>
				<Stack gap={{ md: 24, xs: 4 }} direction={{ md: 'row', xs: 'column' }} display="flex" alignItems="center" justifyItems="start" justifyContent="start" mb={{ md: 8, xs: 0 }}>
					<Stack direction="row" display="flex" alignItems="center" justifyItems="center" justifyContent="center">
						<Avatar
							alt={state.fullName}
							src={state.profileImage}
							sx={{ width: 100, height: 100 }}
						/>
						<Box sx={{ ml: 2 }}>
							<Typography
								sx={{
									color: theme.palette.primary.contrastText,
									fontWeight: Dimens.FONT_BOLDER,
									fontSize: '24px',
								}}
							>
								{state.fullName}

							</Typography>
							<Typography
								sx={{
									color: theme.palette.primary.contrastText,
									fontWeight: Dimens.FONT_THIN,
									fontSize: Dimens.FONT_SIZE_BODY,
									mb: 1,
								}}
							>
								{state.district?.name}
								,
								{' '}
								{state.country?.name}

							</Typography>

							<div
								role="button"
								tabIndex={0}
								onKeyUp={logout}
								onClick={logout}
								style={{ cursor: 'pointer' }}
							>
								<Stack direction="row" gap={1} alignItems="center" justifyContent="start">
									<LogoutIcon height={32} width={32} />
									<Typography
										sx={{
											color: 'red',
											fontWeight: Dimens.FONT_THIN,
											fontSize: Dimens.FONT_SIZE_BODY,
										}}
									>
										{t('logout')}
									</Typography>
								</Stack>
							</div>
						</Box>
					</Stack>
					<Stack gap={2}>
						<input
							style={{ display: 'none' }}
							ref={inputRef}
							type="file"
							onChange={handleFileChange}
						/>
						<DefaultButton
						disabled={uploadLoading}
							startIcon={<PhotoIcon />}
							onClick={() => inputRef?.current?.click()}
						>
							{t('customer_page_photo_btn')}
						</DefaultButton>
					</Stack>
					<Divider sx={{ mt: 2, mb: 2 }} />
				</Stack>
				<Box display="flex" flexDirection="column" alignItems="center">
					<form onSubmit={onSubmit}>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
							<Grid item md={4} xs={12}>
								<TextField
									label={t('name_and_surname')}
									name="fullName"
									value={state.fullName}
									onChange={handleChange}
									startIcon={<PersonField />}
								/>
							</Grid>
							<Grid item md={4} xs={12}>
								<TextField
									label={t('label_email')}
									name="email"
									value={state.email}
									onChange={handleChange}
									startIcon={<AtField />}
								/>
							</Grid>
							<Grid item md={4} xs={12}>
								<TextField
									label={t('cell_phone')}
									name="phoneNumber"
									value={state.phoneNumber}
									onChange={handleChange}
									startIcon={<PhoneField />}
								/>
							</Grid>
							<Grid item md={4} xs={12}>
								<TextField
									label={t('phone')}
									name="secondaryPhoneNumber"
									value={state.secondaryPhoneNumber}
									onChange={handleChange}
									optional
									startIcon={<PhoneField />}
								/>
							</Grid>
							<Grid item md={4} xs={12}>
								<TextField
									label={t('customer_page_label_nif_number')}
									name="taxId"
									value={state.taxId}
									optional
									onChange={handleChange}
									startIcon={<NifField />}
								/>
							</Grid>
							<Grid item md={4} xs={12}>
								<TextField
									label={t('customer_page_label_company')}
									name="companyName"
									value={state.companyName}
									optional
									onChange={handleChange}
									startIcon={<CompanyField />}
								/>
							</Grid>
							<Grid item md={4} xs={12} mt={4}>
								<DefaultDropdown
									label={t('customer_page_label_country')}
									value={state.country?.id?.toString()}
									options={countries}
									onChange={(e) => setState((prev) => ({
										...prev, country: { id: parseInt(e.target.value, 10) },
									} as UserReadModel))}
									renderItem={(item) => ({ optionLabel: item.name, optionValue: item.id })}
								/>
							</Grid>
							<Grid item md={4} xs={12} mt={{ md: 4, xs: 0 }}>
								<DefaultDropdown
									label={t('customer_page_label_city')}
									value={state.district?.id?.toString()}
									options={districts}
									onChange={(e) => setState((prev) => ({
										...prev, district: { id: parseInt(e.target.value, 10) },
									} as UserReadModel))}
									renderItem={(item) => ({ optionLabel: item.name, optionValue: item.id })}
								/>
							</Grid>
							<Grid item md={4} xs={12} mt={{ md: 4, xs: 0 }}>
								<TextField
									name="streetAddress"
									value={state.streetAddress}
									onChange={handleChange}
									label={t('address')}
								/>
							</Grid>
							<Grid item md={4} xs={12}>
								<TextField
									name="zipCode"
									value={state.zipCode}
									onChange={handleChange}
									label={t('customer_page_label_postal_code')}
								/>
							</Grid>
							<Grid item md={4} xs={12}>
								<TextField
									name="cityName"
									value={state.cityName}
									onChange={handleChange}
									label={t('customer_page_label_local')}
								/>
							</Grid>

						</Grid>
						<Stack alignItems="center" mt={2}>
							<DefaultButton disabled={loading} type="submit" sx={{ mt: 3, width: 175 }}>
								{t('customer_page_save_btn')}
							</DefaultButton>
						</Stack>
					</form>

					<Typography
						sx={{
							color: theme.palette.primary.contrastText,
							fontWeight: Dimens.FONT_BOLDER,
							fontSize: '24px',
							alignSelf: 'start',
							mt: 8,
							mb: 4,
						}}
					>
						{t('my_orders').toUpperCase()}

					</Typography>
					<Box sx={{ overflow: 'auto' }}>
						<Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
							<Table sx={{ minWidth: 1000 }} size="small" aria-label="a dense table">
								<TableHead>
									<TableRow>
										<TableCell>{t('order_id')}</TableCell>
										<TableCell align="right">{t('order_date')}</TableCell>
										<TableCell align="center">{t('quantity')}</TableCell>
										<TableCell align="right">{t('price_with_tax')}</TableCell>
										<TableCell align="right">{t('order_status')}</TableCell>
										<TableCell align="right">{t('order_proof_info')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{orders.map((row) => (
										<OrderRow data={row} />
									))}
									{orders.length === 0 && (
										<StyledTableRow
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell align="center" colSpan={6}>{t('no_orders')}</TableCell>
										</StyledTableRow>
									)}
								</TableBody>
							</Table>
						</Box>
					</Box>
				</Box>

			</Box>
		</Box>
	);
};
