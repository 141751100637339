import React from 'react';
import ProductCatelogPage from './ProductCatelogPage';
import { CatelogProvider } from './provider/catelog.provider';

const ProductCatelog = () => (
	<main>
		<CatelogProvider>
			<ProductCatelogPage />
		</CatelogProvider>
	</main>
);

export default ProductCatelog;
