import { ProductCategory } from 'features/products/domain/entities/Product';
import { SubCategoryModel } from '../../data/dto/category-backoffice-model';
import { ApiResponse } from 'common/domain/entities/api-response';
import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import CategoriesApiImpl from '../../data/remote/categories-api-impl';

interface FetchState {
	loading: boolean;
	error: string;
}

type TContextProps = {
	categories: ProductCategory[]
	categoriesFetchState: FetchState
	subCategories: SubCategoryModel[]
	subCategoryFetchState: FetchState
	getProductCategories: () => Promise<boolean>
	subCategoriesC: SubCategoryModel[]
	subCategoryFetchStateC: FetchState
	getProductSubCategories: (id: number) => Promise<boolean>
	getProductSubCategoriesC: (id: number) => Promise<boolean>
	selectedCategory: number
	setSelectedCategory: (id: number) => void
	selectedSubCategory: number
	setSelectedSubCategory: (id: number) => void
}

const CategoriesContext = createContext({} as TContextProps);

export const CategoriesBackofficeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const categoriesApi = new CategoriesApiImpl();

	const [categories, setCategories] = useState([] as ProductCategory[]);
	const [categoriesFetchState, setCategoriesFetchState] = useState<FetchState>({
		loading: false,
		error: '',
	});
	const [selectedCategory, setSelectedCategory] = useState<number>(0);

	const [subCategories, setSubCategories] = useState<SubCategoryModel[]>([]);
	const [subCategoryFetchState, setSubCategoryFetchState] = useState<FetchState>({
		loading: false,
		error: '',
	});
	const [selectedSubCategory, setSelectedSubCategory] = useState<number>(0);

	const [subCategoriesC, setSubCategoriesC] = useState<SubCategoryModel[]>([]);
	const [subCategoryFetchStateC, setSubCategoryFetchStateC] = useState<FetchState>({
		loading: false,
		error: '',
	});

	const getProductSubCategoriesC = async (id: number) => {
		setSubCategoryFetchStateC({ ...subCategoryFetchState, loading: true });

		return categoriesApi.getProductSubCategories(id).then((res) => {
			setSubCategoriesC(res.data);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setSubCategoryFetchStateC({ ...subCategoryFetchState, error: responseError.message });
			return false;
		}).finally(() => {
			setSubCategoryFetchStateC({ ...subCategoryFetchState, loading: false });
		});
	};
	const getProductSubCategories = async (id: number) => {
		setSubCategoryFetchState({ ...subCategoryFetchState, loading: true });

		return categoriesApi.getProductSubCategories(id).then((res) => {
			setSubCategories(res.data);
			setSelectedSubCategory(res.data[0].productCategoryId);
			getProductSubCategoriesC(res.data[0].productCategoryId);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setSubCategoryFetchState({ ...subCategoryFetchState, error: responseError.message });
			return false;
		}).finally(() => {
			setSubCategoryFetchState({ ...subCategoryFetchState, loading: false });
		});
	};
	const getProductCategories = async () => {
		setCategoriesFetchState({ ...categoriesFetchState, loading: true });

		return categoriesApi.getProductCategories().then((res) => {
			setCategories(res.data);
			getProductSubCategories(res.data[0].productCategoryId);
			setSelectedCategory(res.data[0].productCategoryId);
			return true;
		}).catch((err) => {
			const responseError = err as ApiResponse<void>;
			setCategoriesFetchState((prev: FetchState) => ({ ...prev, error: responseError.message }));
			return false;
		}).finally(() => {
			setCategoriesFetchState((prev: FetchState) => ({ ...prev, loading: false }));
		});
	};

	const value = useMemo(() => ({
		categories,
		categoriesFetchState,
		getProductCategories,
		getProductSubCategories,
		getProductSubCategoriesC,
		subCategories,
		subCategoryFetchState,
		subCategoriesC,
		subCategoryFetchStateC,
		selectedCategory,
		setSelectedCategory,
		selectedSubCategory,
		setSelectedSubCategory,
	}), [
		categories,
		categoriesFetchState,
		subCategories,
		subCategoryFetchState,
		subCategoriesC,
		subCategoryFetchStateC,
		selectedCategory,
		selectedSubCategory,
	]);

	return <CategoriesContext.Provider value={value}>{children}</CategoriesContext.Provider>;
};

export default function useCategoriesBackoffice() {
	return useContext(CategoriesContext);
}
