import { ApiResponse } from '@/common/domain/entities/api-response';
import { SupportCreateModel } from '../dto/support-create-model';
import { SupportApi } from './support-api';
import storeHttp from 'common/data/remote/store-http';
import { SubjectReadModel } from '../dto/subject-read-model';
import { httpPost, httpGet } from 'common/utils/http.utils';

export class SupportApiImpl implements SupportApi {
	apiService = storeHttp;

	async create(support: SupportCreateModel): Promise<ApiResponse<string>> {
		return httpPost('/support', support, this.apiService);
	}

	async getSubjects(): Promise<ApiResponse<SubjectReadModel[]>> {
		return httpGet('/support/subjects/options', this.apiService);
	}
}
