import AppLinks from 'assets/applinks.routes';
import { PersonOutline } from '@mui/icons-material';
import {
	BottomNavigation, BottomNavigationAction, Paper,
} from '@mui/material';
import { Catalog, Phone, Products } from 'assets/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
	onProductClick: () => void
}

const Tabbar:React.FC<Props> = (props) => {
	const { onProductClick } = props;
	const navigate = useNavigate();
	const handleNavigate = (link: string) => {
		navigate(link);
	};
	return (
		<Paper
			sx={{
				display: { md: 'none', xs: 'block' },
				position: 'fixed',
				bottom: 0,
				left: 0,
				right: 0,
			}}
			elevation={3}
		>
			<BottomNavigation
				showLabels
			// value={value}
			// onChange={(event, newValue) => {
			// 	setValue(newValue);
			// }}
			>
				<BottomNavigationAction label="PRODUTOS" icon={<Products />} onClick={onProductClick} />
				<BottomNavigationAction label="CATALOGOS" icon={<Catalog />} onClick={() => handleNavigate(AppLinks.PRODUCT_CATELOG)} />
				<BottomNavigationAction label="CONTATOS" icon={<Phone />} onClick={() => handleNavigate(AppLinks.CONTACT_US)} />
				<BottomNavigationAction label="PERFIL" icon={<PersonOutline sx={{ width: '35px' }} />} onClick={() => handleNavigate(AppLinks.LOGIN)} />
			</BottomNavigation>
		</Paper>
	);
};

export default Tabbar;
