import React from 'react';
import ProductInfo from './ProductInfo';
import { ProductProvider } from '../provider/product.provider';

const ProductDetail = () => (
	<section>
		<ProductProvider>
			<ProductInfo />
		</ProductProvider>
	</section>
);

export default ProductDetail;
